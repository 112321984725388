.myTeamMain { background: #F1F4FB; }
.myTeamHeader { background: #fff; width: 100%; border-bottom: 1px solid #00000029; }
.myTeamHeader .myTeamHeaderSummary { position: relative; padding: 15px 30px; background: #fff; color: #555555; font-weight: 600; display: inline-block; border-bottom: 1px solid #555555; }
.myTeamHeader .myTeamHeaderSummary:after { content: ""; position: absolute; bottom: -6px; left: 50%; transform: translateX(-50%); width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 5px solid #555; z-index: 1; }
.myTeamTodayStatus {
    background: #fff;
    border-right: 1px solid #B6CCE5;
    /* min-width: 540px; */
    width: auto;
    padding: 20px;
    padding-right: 0;
}
.myTeamTodayStatus .myTeamTodaysStatus { margin-bottom: 20px; }
.myTeamTodayStatus .myTeamTodayStatusContent {
    width: 340px;
}
.myTeamLeftSide { 
    height: calc(100vh - 414px); 
    overflow-y: auto; background: #fff; position: relative;}
.myTeamLeftSide.myTeamLeftCol {height: calc(100vh - 128px);}
.myTeamLeftSide::-webkit-scrollbar, .myTeamLeftSide ::-webkit-scrollbar {display: none;}
.myTeamWFH { background: #fff;}
.myTeamWFH .myTeamWFH_Title { display: flex; align-items: center; justify-content: space-between; padding: 10px 30px; background: #FAFAFA; }
.myTeamWFH .myTeamWFH_Title .wfh_status_label { display: flex; align-items: center; column-gap: 10px; }
.myTeamWFH .myTeamWFH_Title .wfh_status_label .wfh_status { width: 12px; height: 12px; border-radius: 50%; background: #B41FB4; }
.myTeamWFH.myTeamWFO .myTeamWFH_Title .wfh_status_label .wfh_status { width: 12px; height: 12px; border-radius: 50%; background: #3B67CE; }
.myTeamWFH.myTeam_onLeave .myTeamWFH_Title .wfh_status_label .wfh_status { width: 12px; height: 12px; border-radius: 50%; background: #069A00; }
.myTeamWFH.myTeam_Sabbatical .myTeamWFH_Title .wfh_status_label .wfh_status { width: 12px; height: 12px; border-radius: 50%; background: #45B4E4; }
.myTeamWFH.myTeam_Absent .myTeamWFH_Title .wfh_status_label .wfh_status { width: 12px; height: 12px; border-radius: 50%; background: #D90000; }
.myTeamWFH .myTeamWFH_Title .wfh_status_label .wfh_status_text { font-size: 14px; }
.myTeamWFH_Title .wfh_status_count { font-size: 14px; }
.myTeamWFH .wfh_employee { display: flex; align-items: center; justify-content: center; flex-direction: column; row-gap: 10px; text-align: center; padding: 15px 0; }
.myTeamWFH .wfh_employee .myTeamEmpImg { width: 90px; height: 90px; object-fit: cover; border-radius: 50%; }
.myTeamWFH .wfh_employee .wfh_employee_name { font-size: 14px; color: #222222; }
.myTeamWFH .myTeamWFHContent { max-height: 284px; overflow-x: hidden; border-bottom: 1px solid #00000029; }


/* My Team Right Section */
.monthlyAttendance .myTeamResources { display: flex; height:100%;}
.monthlyAttendance .myTeamResources .departmentWiseResourses { min-width: 324px; padding: 20px; padding-right: 0;} 
.monthlyAttendance .myTeamResources .projectWiseResourses { min-width: 324px; background: #fff; padding: 20px; padding-right: 0;}
.myTeamResources .departmentWiseResoursesTitle .selectResources { width: 100px;} 
.myTeamResources .departmentWiseResoursesTitle { height: 2.2em; display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px; column-gap: 15px; padding-right: 15px; }
.Project-date-input input{height: 1em;}
.myTeamMain .monthlyAttendance .monthAttHeader { display: flex; align-items: center; justify-content: space-between; padding: 20px; row-gap: 15px; background: #fff; flex-wrap: wrap;}
.monthlyAttendance .monthAttHeader .monthAttHeaderMode { display: flex; align-items: center; column-gap: 30px; flex-wrap: wrap;}
.monthAttHeader .monthAttHeaderMode .monthAttHeaderModeContent { display: flex; align-items: center; column-gap: 8px; }
.monthlyAttendance .monthAttHeaderModeContent .monthAttHeaderStatusCircle { width: 15px; height: 15px; border-color:#B41FB4; border-radius: 50%; background: #B41FB4; }
.monthlyAttendance .monthAttHeaderModeContent .monthAttHeaderStatusTxt {font-size: 14px; color: #000;}
.monthlyAttendance .monthAttHeaderModeContent .monthAttHeaderStatusCircle.onLeave { border-color: #069A00; background: #069A00; }
.monthlyAttendance .monthAttHeaderModeContent .monthAttHeaderStatusCircle.weeklyOff { border-color: #ccc; background:#ccc; }
.monthlyAttendance .monthAttHeaderModeContent .monthAttHeaderStatusCircle.Absent { border-color: #D90000; background: #D90000; }
.monthlyAttendance .monthAttHeaderModeContent .monthAttHeaderStatusCircle.office { border-color: #3B67CE; background: #3B67CE; }
.myTeamGraphStatus {
    display: flex;
    margin-bottom: 15px;
    border-bottom: 1px solid #B6CCE5;
    overflow: auto;
}
.monthlyAttendanceData { position: relative; background: #fff; overflow-x: scroll; height: calc(100vh - 478px);} 
.monthlyAttendanceData.myTeamCalendar {height: calc(100vh - 192px);}
/* .monthlyAttendanceData.myTeamCalendar::-webkit-scrollbar {display: none;} */
.monthlyAttendanceData table { border: none !important; border-collapse: separate; border-spacing: 0; padding: 0 !important;} 
.monthlyAttendanceData table td .mnthAttWrapper { width: 260px; display: flex; align-items: center; column-gap: 15px; } 
.monthlyAttendanceData table th { background: #FAFAFA; line-height: 44px; font-size: 15px; font-weight: 600; color: #555555; }
.monthlyAttendanceData table thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; } 
.monthlyAttendanceData table th:first-child { position: -webkit-sticky; position: sticky; top: 0; left: 0; z-index: 3; } 
.monthlyAttendanceData table td:first-child { position: -webkit-sticky; position: sticky; left: 0; z-index: 2; }

.monthlyAttendanceData table .mnthAttEmpIcon img { width: 26px; height: 26px; border-radius: 50%; } 
.monthlyAttendanceData table .mnthAttEmpIcon { width: 26px; height: 26px; background: gray; display: flex; align-items: center; justify-content: center; border-radius: 50%; font-size: 12px; color: #fff; }
.monthlyAttendanceData table td{background: #fff; padding: 10px !important; text-align: center; position: relative;}
.monthlyAttendanceData table td, .monthlyAttendanceData table th { margin: 0; border: none !important; white-space: nowrap;} 
.monthlyAttendanceData table .headcol { position: absolute; width: 5em; left: 0; top: auto; border-top-width: 2px; margin-top: -1px; } .monthlyAttendanceData table .headcol:before { content: 'Row '; } 
.monthlyAttendanceData table .long { letter-spacing: 1em; }
.monthlyAttendanceData table td .weekOff{ background: #ccc; color: #fff; border-radius: 50%; padding: 2px 0px;  display: flex; align-items: center; justify-content: center; width: 28px; height: 28px; line-height: 28px;}
.monthlyAttendanceData table td .Office { background: rgba(59,103,206,0.8); color: #fff; border-radius: 50%; padding: 2px 0px;  display: flex; align-items: center; justify-content: center; width: 28px; height: 28px; line-height: 28px;}
.monthlyAttendanceData table td .noAttDate { border-radius: 50%; padding: 2px 0px;  display: flex; align-items: center; justify-content: center; width: 28px; height: 28px; line-height: 28px;}
.monthlyAttendanceData table td .wfh { background: #B41FB4; color: #fff; border-radius: 50%; padding: 2px 0px;  display: flex; align-items: center; justify-content: center; width: 28px; height: 28px; line-height: 28px;}
.monthlyAttendanceData table td .on_leave { background: #069A00; color: #fff; border-radius: 50%; padding: 2px 0px; display: flex; align-items: center; justify-content: center; width: 28px; height: 28px; line-height: 28px;}
.monthlyAttendanceData table td .Absent { background: rgba(217,0,0,0.8); color: #fff; border-radius: 50%; padding: 2px 0px; display: flex; align-items: center; justify-content: center; width: 28px; height: 28px; line-height: 28px;}

.monthlyAttendanceData table td .attHistoryListContent{display: none;}
.monthlyAttendanceData table td:hover .attHistoryListContent{display: block; position: absolute; bottom: 40px; left: 50%; transform: translateX(-50%); background: #fff; z-index: 9; color:#000;}
.attHistoryListContent:after { content: ""; position: absolute; bottom: -8px; left: 50%; transform: translateX(-50%); width: 0; height: 0; border-left: 8px solid transparent; border-right: 8px solid transparent; border-top: 8px solid #888888; z-index: 1; }

/* Monthly Attendence hover tool tip css*/
.attHistoryListContent .attHistoryStatus .attHistoryStatusText { display: flex; align-items: center; justify-content: center; position: relative; } 
.attHistoryListContent .attHistoryStatus .attHistoryStatusText .absentInfoIcon { font-size: 13px; margin-left: 5px; cursor: pointer; } 
.attHistoryListContent .attHistoryStatus .attHistoryStatusText .infoIconHoverState { position: relative; } 
.attHistoryListContent .attHistoryStatus .attHistoryStatusText .infoIconHoverState:hover .leaveTypeInfoBox { display: block; } 
.attHistoryListContent { display: flex; align-items: center; flex-direction: column; border: 1px solid #d6d6d6; border-radius: 5px; padding: 20px 0 0; margin-bottom: 15px; width: 560px; } 
.attHistoryListContent:last-child { margin-bottom: 0; } 
.attHistoryListContent .attHistoryDays { padding: 0 20px; text-align: center; } 
.attHistoryListContent .attHistoryDays .attHistoryDaysCount { font-size: 30px; line-height: 30px; font-weight: 300; } 
.attHistoryListContent .attHistoryDays .attHistoryDayText { font-size: 14px; line-height: 22px; font-weight: 500; } 
/* .hrCalanderRight .attendanceHistory ul.attHistoryList .attHistoryListContent .workFromHomeContent { line-height: 30px; }  */
.attHistoryListContent .workFromHomeContent .workFromHomeText { font-weight: 500; } 
.attHistoryListContent .workFromHomeContent .workFromHomeText { font-size: 16px; font-weight: 500; margin-bottom: 5px; } 
.attHistoryListContent .workFromHomeContent .calendarIconWithDate .checkInTxt { font-size: 14px; font-weight: 500; } 
.attHistoryListContent .workFromHomeContent .calendarIconWithDate .checkInTxt span { font-weight: 400; } 
.attHistoryListContent .calendarIconWithDate .workFromHomeBadge { display: inline-flex; font-size: 12px; border-radius: 2px; background: #eeeeee; padding: 2px 15px; } 
.attHistoryListContent .workFromHomeContent .workFromHomeBadge { background: #eeeeee; padding: 3px 15px; font-size: 12px; line-height: 12px; border-radius: 2px; display: inline-block; margin-bottom: 5px; } 
.attHistoryListContent .workFromHome { display: flex; align-items: center; column-gap: 50px; width: 100%; } 
.attHistoryListContent .attHistoryStatus { margin-left: auto; text-align: center; color: #0f673e; padding-right: 20px; padding-left: 20px; font-size: 12px; font-weight: 500; /* cursor: pointer; */ width: 110px; white-space: normal; text-align: center; } 
.attHistoryListContent .attHistoryStatus svg { font-size: 22px; } 
.attHistoryListContent .attHistoryListCols { display: flex; align-items: center; width: 100%; } 
.attHistoryListContent .attHistoryListCols .workFromHomeContent { text-align: left; } 
.attHistoryListContent .attHistoryAddText { width: 100%; font-size: 13px; font-weight: 600;} 
.attHistoryListContent .attHistoryLoggedHours { width: 100%; font-size: 13px; } 
.attHistoryListContent .attHistoryLoggedHours span { font-weight: 500; } 
.attHistoryListContent .listAddress { width: 100%; display: flex; align-items: center; background: #fafafa; border-radius: 0 0 5px 5px; padding: 5px 20px; margin-top: 20px; text-align: left; } 
/* LoggedOut Attendance List Css */ 
li.attHistoryListContent.loggedOut .workFromHomeText, .attHistoryListContent.loggedOut .attHistoryStatus, .attHistoryListContent.loggedOut .attHistoryLoggedHours { color: #8a0000; cursor: pointer; } 
.hrCalanderRight .attendanceHistory .attHistoryList .attHistoryListContent.loggedOut { border-color: #8a0000; } 
.attHistoryListContent.loggedOut .workFromHomeContent .workFromHomeBadge { background: #ffe7e7; color: #8a0000; } 
li.attHistoryListContent.pendingStatus .workFromHomeText, .attHistoryListContent.pendingStatus .attHistoryStatus, .attHistoryListContent.pendingStatus .attHistoryLoggedHours { color: #9e5b00; } 
.hrCalanderRight .attendanceHistory .attHistoryList .attHistoryListContent.pendingStatus { border-color: #9e5b00; } 
.attHistoryListContent.pendingStatus .workFromHomeContent .workFromHomeBadge { background: #ffeed6; color: #9e5b00; }
/* End of Monthly Attendence hover tool tip css*/

.attendanceArrow{
    cursor:pointer;
    padding:0px 20px 0px 5px;
    font-size: 20px;
}

.attendanceMonth{
    padding:0px 20px 0px 5px;
}
.projectWResources {
    display: flex;
    align-items: center;
    height: 175px;
}
ul.resourcesList {
    list-style: none;
    min-width: 230px;
    max-height: 200px;
    overflow: auto;
    margin-top: 20px;
}
ul.resourcesList li {
    font-size: 14px;
    padding: 5px 15px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    cursor: pointer;
}
ul.resourcesList .resourcesListCount {
    display: flex;
    align-items: center;
    column-gap: 5px;
    pointer-events: none;
}
.resourcesList .resourcesListItem {
    display: flex;
    align-items: center;
    column-gap: 10px;
    pointer-events: none;
}
ul.resourcesList li.active {
    background: #F8F8F8;
}
ul.resourcesList li .resourcesListCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
ul.resourcesList li .resourcesListCircle.circleOne {
    background: #B41FB4;
}
ul.resourcesList li .resourcesListCircle.circleTwo {
    background: #3B67CE;
}
ul.resourcesList li .resourcesListCircle.circleThree {
    background: #069A00;
}
ul.resourcesList li .resourcesListCircle.circleFour {
    background: #45B4E4;
}
.monthlyAttendanceData table tr:first-child td .attHistoryListContent,
.monthlyAttendanceData table tr:nth-of-type(2) td .attHistoryListContent {
    position: absolute;
    bottom: -115px;
}
.monthlyAttendanceData table tr:first-child td .attHistoryListContent:after,
.monthlyAttendanceData table tr:nth-of-type(2) td .attHistoryListContent:after {
    content: '';
    position: absolute;
    top: -9px;
    left: 48%;
    transform: rotate(180deg);
}
.monthlyAttendanceData table tr td:last-child .attHistoryListContent {
    position: absolute;
    bottom: -80px;
    right: 0;
    left: auto;
    transform: translateX(-10%);
}
.monthlyAttendanceData table tr td:last-child .attHistoryListContent::after {
    content: '';
    position: absolute;
    top: 10%;
    left: auto;
    right: -13px;
    transform: rotate(-90deg);
}
@media screen and (max-width: 1680px) {
    .myTeamWFH .wfh_employee .myTeamEmpImg { width: 65px; height: 65px; }
    .myTeamLeftSide {
        height: calc(100vh - 419px);
    }
    .monthlyAttendanceData {
        height: calc(100vh - 483px);
    }
}
@media screen and (max-width: 1260px){
    .monthlyAttendanceData {
        height: calc(100vh - 523px);
    }
}
@media screen and (max-width: 992px) {
    .myTeamLeftSide {
        height: calc(100vh - 422px);
    }
    .monthlyAttendanceData {
        height: calc(100vh - 503px);
    }
    .myTeamLeftCols{padding-right: 0;}
    .monthlyAttendance { margin-top: 15px;}
}
@media screen and (max-width: 768px) {
    .myTeamGraphStatus {flex-direction: column;}
    .myTeamTodayStatus .myTeamTodayStatusContent { height: 165px; width: 100%; }
    .myTeamResources .departmentWiseResoursesTitle { font-size: 14px; font-weight: 500; }
    .myTeamResources .departmentWiseResoursesTitle .selectResources { padding: 2px 15px; font-size: 14px;}
    .myTeamLeftSide { height: auto; }
    .monthlyAttendanceData { height: calc(100vh - 68px); }
    
    .monthlyAttendance .myTeamResources { flex-direction: column; column-gap: normal; }
    .monthlyAttendance .myTeamResources .departmentWiseResourses, .monthlyAttendance .myTeamResources .projectWiseResourses { width: 100%; }
    .myTeamTodayStatus { min-width: 100%; height: auto; border-right: 1px solid transparent;}
}
@media screen and (max-width: 600px) {
    .monthlyAttendance .monthAttHeader .monthAttHeaderMode {margin-top: 15px; line-height: 30px;}
    .projectWResources { flex-direction: column; height: auto;}
    ul.resourcesList {
        min-width: 100%;
        max-height: 100%;
        overflow: hidden;
    }
    .myTeamLeftCols{
        padding-right: 0;
    }
}














