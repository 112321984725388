.leaveAppHeader ul li {
    border-bottom: 1px solid transparent;
    position: relative;
}
.leaveAppHeader ul li.active {
    border-bottom: 1px solid #555555;
}
.leaveAppHeader ul li.active:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #555;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}
.leaveAppTableContainer {
    height: calc(100vh - 303px);
    overflow: auto;
    position: relative;
}
.projectTypeContainer {
    height: calc(100vh - 220px);
    overflow: auto;
}
.leaveAppTableContainer table thead {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}
.empLeaveComments:hover .leaveComments {
    display: block;
}
.allocationFilteredData {
    display: none;
}
.allocationFilteredData.active {
    display: block;
}
.tooltipWrapper:hover .tooltipHover {
    display: block;
    position: absolute;
    bottom: 30px;
    right: 0;
    z-index: 1;
}
.tooltipWrapper .tooltipHover:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #555;
    position: absolute;
    bottom: -8px;
    right: 2px;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    z-index: 3;
}
.tooltipWrapper .tooltipHover.comment:after {    border-top: 8px solid #0d6efd;}
.tooltipWrapper .tooltipHover.edit:after {    border-top: 8px solid #0d6efd;}
.tooltipWrapper .tooltipHover.approve:after {    border-top: 8px solid #198754;}
.tooltipWrapper .tooltipHover.reject:after {    border-top: 8px solid #dc3545;}

.leaveAppTableContainer table tr td .leaveTableStatus .Rejected {background: #dc3545;}
.leaveAppTableContainer table tr td .leaveTableStatus .Open {background: #0d6efd;}
.leaveAppTableContainer table tr td .leaveTableStatus .Dismissed {background: #ee8772e4;}
.leaveAppTableContainer table tr td .leaveTableStatus .Approved {background: #198754;}
.leaveAppTableContainer table tr td .leaveTableStatus .Pending {background: #C39000;}

.paginationLimitBox {
    position: relative;
    border: 1px solid #b1b1b1;
    cursor: pointer;
    white-space: nowrap;
    background: #fff;
    color: #000;
    text-decoration: none;
    padding: 3px 20px;
    border-radius: 5px;
    margin-left: 10px;
}
ul.paginationLimitBoxDropdown {
    position: absolute;
    bottom: 36px;
    right: 0;
    list-style: none;
    background: #fff;
    color: #000;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    z-index: 2;
}
ul.paginationLimitBoxDropdown li {
    min-width: 100px;
    text-align: left;
    padding: 5px 15px;
}
ul.paginationLimitBoxDropdown li:first-child {
    border-radius: 5px 5px 0 0;
}
ul.paginationLimitBoxDropdown li:last-child {
    border-radius: 0 0 5px 5px;
}
ul.paginationLimitBoxDropdown li:hover {
    background: #ededed;
    /* color: #fff; */
}
.leaveAppTableContainer table tr td .leaveTableStatus.Rejected {
    background: #fff6f6;
    border: 1px solid #f9eeee;
    color: #333;
}
.leaveAppTableContainer table tr td .leaveTableStatus.Dismissed {
    background: #fffbf5;
    border: 1px solid #fff0da;
    color: #333;
}
.leaveAppTableContainer table tr td .leaveTableStatus.Approved {
    background: #f2fbf7;
    border: 1px solid #dafdec;
    color: #333;
}
.leaveAppTableContainer table tr td .leaveTableStatus.Pending {
    background: #fffaef;
    border: 1px solid #fff0c7;
    color: #333;
}
.leaveAppTableContainer table tr td .leaveTableStatus.Open {
    background: #f3f8ff;
    border: 1px solid #ebf2ff;
    color: #333;
}
.leaveAppTableContainer table tr td .tooltipWrapper.statusApproved,
.leaveAppTableContainer table tr td .tooltipWrapper.statusRejected,
.leaveAppTableContainer table tr td .tooltipWrapper.statusDismissed {
    opacity: 0.4;
    pointer-events: none;
}
    /* height: calc(100vh - 130px);
    overflow: auto;
    position: relative;
} */
.empLeaveComments:hover .leaveComments {
    display: block;
}
.allocationFilteredData {
    display: none;
}
.allocationFilteredData.active {
    display: block;
}
.tooltipWrapper .tooltipHover {width: max-content;}
.tooltipWrapper:hover .tooltipHover {
    display: block;
    position: absolute;
    bottom: 30px;
    right: 0;
    z-index: 1;
}
.tooltipWrapper .tooltipHover:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #555;
    position: absolute;
    bottom: -8px;
    right: 2px;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    z-index: 3;
}
.tooltipWrapper .tooltipHover.comment:after {    border-top: 8px solid #0d6efd;}
.tooltipWrapper .tooltipHover.edit:after {    border-top: 8px solid #0d6efd;}
.tooltipWrapper .tooltipHover.approve:after {    border-top: 8px solid #198754;}
.tooltipWrapper .tooltipHover.reject:after {    border-top: 8px solid #dc3545;}

.leaveAppTableContainer table tr td .leaveTableStatus .Rejected {background: #dc3545;}
.leaveAppTableContainer table tr td .leaveTableStatus .Open {background: #0d6efd;}
.leaveAppTableContainer table tr td .leaveTableStatus .Dismissed {background: #f45c3e;}
.leaveAppTableContainer table tr td .leaveTableStatus .Approved {background: #198754;}

.paginationLimitBox {
    position: relative;
    border: 1px solid #b1b1b1;
    cursor: pointer;
    white-space: nowrap;
    background: #fff;
    color: #000;
    text-decoration: none;
    padding: 3px 20px;
    border-radius: 5px;
    margin-left: 10px;
}
ul.paginationLimitBoxDropdown {
    position: absolute;
    bottom: 36px;
    right: 0;
    list-style: none;
    background: #fff;
    color: #000;
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    z-index: 2;
}
ul.paginationLimitBoxDropdown li {
    min-width: 100px;
    text-align: left;
    padding: 5px 15px;
}
ul.paginationLimitBoxDropdown li:first-child {
    border-radius: 5px 5px 0 0;
}
ul.paginationLimitBoxDropdown li:last-child {
    border-radius: 0 0 5px 5px;
}
ul.paginationLimitBoxDropdown li:hover {
    background: #ededed;
    /* color: #fff; */
}
.leaveAppTableContainer table tr td .leaveTableStatus.Rejected {
    background: #fff6f6;
    border: 1px solid #f7c1c1;
    color: #333;
}
.leaveAppTableContainer table tr td .leaveTableStatus.Dismissed {
    background: #fffbf5;
    border: 1px solid #ffded8;
    color: #333;
}
.leaveAppTableContainer table tr td .leaveTableStatus.Approved {
    background: #f2fbf7;
    border: 1px solid #9adfbc;
    color: #333;
}
.leaveAppTableContainer table tr td .leaveTableStatus.Open {
    background: #f3f8ff;
    border: 1px solid #c7dcff;
    color: #333;
}
.leaveApplication .updateLeaveProfileImg {
    width: 90px;
    height: 90px;
    font-size: 40px;
}
.leaveApplication .updateLeaveProfileImg img {
    object-fit: cover;
}
.employee_form_dialog.leaveUpdateDialog .MuiDialog-paperWidthSm {
    min-width: 780px;
}
.reviewAllocationSidePanel {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    width: 700px;
    height: 100%;
    z-index: 999;
}
.reviewAllocationSidePanel .leaveApplication {
    height: calc(100vh - 60px);
    overflow: auto;
}
.reviewAllocationSidePanel .leaveApplication .reviewRequestTableContainer {
    max-height: 500px;
    overflow: auto;
}
.reviewReguChart .reviewReguChartBar {
    width: 100% !important;
    height: 300px !important;
}
@media screen and (max-width: 992px){
    .employee_form_dialog.leaveUpdateDialog .MuiDialog-paperWidthSm {
        min-width: 90%;
    }
    .leaveApplication .leaveAppFiscalYearData{width: 100%;}
}
@media screen and (max-width: 768px){
    .reviewAllocationSidePanel {
        width: 90%;
    }
}