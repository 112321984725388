.orgClientsContent {
  height: calc(100vh - 173px);
  overflow-y: auto;
  padding: 0 15px;
  /* padding-bottom: 25px; */
}

.EmpProjectInfo {
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  height: 100%;
  position: relative;
}

.EmpProjectInfo:hover {
  border: 1px solid #666666;
}

.EmpProjectInfo .projectLogo .projectLogoTitle {
  font-weight: 600;
  color: #000000;
}

.EmpProjectInfo .projectLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.EmpProjectInfo .projectLogo img {
  width: 110px;
  height: 35px;
  object-fit: contain;
}

.EmpProjectInfo .projectSubtitle {
  font-size: 14px;
  color: #333333;
  padding: 0px;
}

.EmpProjectInfo .projectRole .projectRoleTxt {
  font-size: 14px;
  color: #333333;
}

.EmpProjectInfo .projectRoleContentNumber {
  font-size: 14px;
  color: #333333;
}

.EmpProjectInfo .projectRoleContent {
  font-size: 14px;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  /* height: 50px; */
}

.EmpProjectInfo .projectRoleContent:hover {
  white-space: initial;
  width: auto;
  text-overflow: initial;
  height: auto;
}

.EmpProjectInfo .projectTeam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.EmpProjectInfo .projectTeam .projectEmployees {
  display: flex;
  align-items: flex-end;
}

.EmpProjectInfo .projectTeam .employeeImg .projectTeamTitle {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.EmpProjectInfo .projectTeam .employeeImg .projectEmpImages img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -5px;
  outline: 3px solid #fff;
}

.EmpProjectInfo .projectTeam .employeeImg .projectEmpImages {
  display: flex;
}

.EmpProjectInfo .projectTeam .employeeImg .projectEmpImages .teamList {
  min-width: 0;
  position: relative;
}
.showTeamMemberNameDiv {
  display: none;
}
.teamList:hover .showTeamMemberNameDiv {
  display: block;
  position: absolute;
  top: -30px;
  font-size: 10px;
  font-weight: 600;
  border: 1px solid #0069c3;
  border-radius: 5px;
  padding: 4px 5px;
  background: aliceblue;
  color: #0069c3;
  text-align: center;
  min-width: max-content;
}
.teamList:hover .showTeamMemberNameDiv::before {
  content: "";
  position: absolute;
  top: 100%;
  border-width: 6px;
  border-style: solid;
  border-color: rgb(0 105 195) transparent transparent transparent;
}

.EmpProjectInfo .projectTeam .employeeImg .projectEmpImages .newJoineeProfile {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -5px;
  outline: 3px solid #fff;
}

.projectListProfile.moreIcon .newJoineeProfile {
  width: 30px;
  height: 30px;
}

.EmpProjectInfo .projectTeam .employeeImg .projectEmpImages img:first-child {
  margin-left: 0;
}

.EmpProjectInfo .projectTeam .projectEmployees .moreEmployeesCount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin-left: -10px;
  outline: 3px solid #fff;
  background: #dddddd;
  border-radius: 50%;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
}

.EmpProjectInfo .projectTeam .projectManagerImg {
  text-align: center;
}

.EmpProjectInfo .projectTeam .projectManagerImg img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.projectManagerTitle {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

/* Project Info Team Box Css */
.projectInfoTeam {
  z-index: 1;
}
.projectInfoTeam .projectInfoTeamList {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 60%;
  overflow-y: auto;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.clientProjectCrossButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 11px 5px 14px;
  font-size: 16px;
  border-bottom: 1px solid #dddddd;
  color: #454545;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}
.clientProjectCrossButton svg{
  cursor: pointer;
}
.projectInfoTeam .projectInfoTeamList.teamApprover {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 60%;
  overflow-y: auto;
  height: 100%;
  top: 0;
  left: 40%;
  z-index: 1;
}
.projectInfoTeam .projectInfoTeamList::-webkit-scrollbar{
  display: none;
}
.projectInfoTeam .projectInfoTeamList.teamApprover::-webkit-scrollbar{
  display: none;
}
.projectInfoTeam .projectInfoTeamList li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
  padding: 5px 10px;
}

.projectInfoTeam .projectInfoTeamList li:hover {
  background: #e8e8e8;
  cursor: pointer;
}

.projectInfoTeam .projectInfoTeamList li .projectListProfile img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}

.projectInfoTeam .projectInfoTeamList li .projectListContent .projectEmpName {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.projectInfoTeam
  .projectInfoTeamList
  li
  .projectListContent
  .projectEmpDesignation {
  font-size: 12px;
  color: #333;
}

.EmpProjectInfo .projectInfoBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 15px;
  position: relative;
}

.EmpProjectInfo .projectInfoBtns .inProgressBtn {
  font-size: 12px;
  font-weight: 600;
  background: #fffbee;
  color: #ae5700;
  border: 1px solid #b78a20;
  border-radius: 35px;
  padding: 2px 8px;
}

.EmpProjectInfo .projectInfoBtns .completeBtn {
  font-size: 12px;
  font-weight: 600;
  background: #f6ffee;
  color: #006f00;
  border: 1px solid #006f00;
  border-radius: 35px;
  padding: 2px 8px;
}

.EmpProjectInfo .projectInfoBtns .addProjects {
  background: transparent;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 30px;
  font-size: 12px;
  padding: 2px 8px;
}

.EmpProjectInfo .projectInfoBtns .addProjects:hover {
  background: #7e009e;
  color: aliceblue;
}

.EmpProjectInfo .projectInfoBtns .primaryProjectBtn {
  font-size: 12px;
  font-weight: 600;
  background: #eeffff;
  color: #006363;
  border: 1px solid #009494;
  border-radius: 35px;
  padding: 2px 8px;;
}

.EmpProjectInfo .startEndDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.EmpProjectInfo .startEndDate .startDateContent .startDateTxt,
.EmpProjectInfo .startEndDate .endDate .endDateTxt {
  font-size: 12px;
  color: #333333;
  margin-top: 15px;
}

.EmpProjectInfo .startEndDate .startDateContent .startDate,
.EmpProjectInfo .startEndDate .endDate .endDate {
  font-size: 14px;
  font-weight: 600;
  color: #001d6d;
}

.EmpProjectInfo .startEndDate .endDate {
  text-align: right;
}

.addButton {
  background: transparent;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 30px;
  font-size: 16px;
  padding: 2px 22px;
  margin-right: 20px;
  margin-top: 20px;
  float: right;
}

.addButton:hover {
  background-color: #7e009e;
  color: aliceblue;
}

.backButton {
  border: none;
  background: transparent;
  font-size: 18px;
  padding: 6px 20px;
  color: #0077c6;
  font-weight: 600;
  margin-top: 10px;
}

.employee_form_dialog {
  display: flex;
  justify-content: center;
}

.employee_form_dialog
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  padding: 0;
}

.add_employee_main_heading {
  color: #7e009e;
  /* position: sticky;
    top: 0; */
  background: #fff;
  /* z-index: 1; */
}

.employee_form_dialog .MuiDialog-paperWidthSm {
  width: 620px;
  border: 1px solid #bebebe;
  border-radius: 10px;
  height: fit-content;
  padding: 0px 20px 10px 20px;
  max-height: 820px;
}

/* .card-content-employee-form {
    overflow-x: hidden;
    padding: 0 20px !important;
} */

.save_button {
  width: 122px;
  height: 36px;
  border: 1px solid #7e009e;
  border-radius: 30px;
  background: #7e009e;
  color: #ffff;
}

.save_button:hover {
  background: #660080;
}

.cancel_button {
  width: 122px;
  height: 36px;
  border: 1px solid #999999;
  border-radius: 30px;
  color: #333333;
  background: none;
}

.cancel_button:hover {
  color: black;
  border: 1px solid #333333;
}

.create_form_labels {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
  color: #000000;
}

.create_form_asterisk {
  font-size: 14px;
  color: red;
}

.card-content-employee-form .skillSetRating {
  color: #7e009e;
}

.employee_form_button_div {
  background: #fff;
  padding: 15px 20px !important;
  /* position: sticky;
    bottom: 0; */
}

.employee_form_dialog input,
.employee_form_dialog select {
  padding: 6px 15px !important;
}

.create_form_input_div {
  display: flex;
  flex-direction: column;
}

.employee_form_inner_heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

#current_address_checkbox_label {
  float: right;
  margin-right: 15px;
}

.employee_form_hr_div {
  display: flex;
  align-items: center;
}

.employee_form_hr_div_text {
  width: 100%;
  max-width: fit-content;
}

.employee_form_hr_div_line {
  width: 100%;
  margin-left: 5px;
}

.employee_form_hr {
  border: 1px solid gray;
  margin-bottom: 5px;
}

#employee_form_label_id {
  margin-top: 5px;
}

.employee_form_hr_div_checkbox {
  display: flex;
  width: 100%;
  max-width: fit-content;
}

.employee_form_address_div {
  display: flex;
  flex-direction: column;
}

.employee_form_address_inner_div {
  display: flex;
  flex-direction: row;
}

.employee_form_radio {
  margin-right: 10px;
}

.create_form_input_div
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 6px 15px !important;
}

.employee_form_delete_icon {
  color: #9e0000;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  flex: end;
  align-items: center;
}

.employee_form_add_row_button {
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  color: #001d6d;
  display: flex;
}

.create_form_input_div_time {
  margin-bottom: 20px;
}

.weekends {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MuiMenu-paper.MuiPaper-elevation.MuiPopover-paper {
  max-height: 300px;
}

.MuiMenu-paper.MuiPaper-elevation.MuiPopover-paper ul.MuiMenu-list {
  padding: 0;
}

.statusIcon {
  margin-right: 5px;
}

.radioButtonProjectType ul {
  display: flex;
}

.projectInnerDiv {
  display: flex;
  align-items: center;
}

.uploadButtonClientIcon {
  font-size: 12px;
  font-weight: 600;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 35px;
  text-align: center;
  padding: 2px 10px;
  margin-top: 5px;
  cursor: pointer;
  display: inline-block;
  background: transparent;
}

.dragAndDropClientIcon {
  border: 1px dashed #aaaaaa;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
}

.dragAndDropClientIcon .browseContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 10px;
  padding: 10px 0;
}

.dragAndDropClientIcon .browseContent .browseBtn {
  padding: 5px 30px;
  border-radius: 35px;
  color: #1678c8;
  background: #fff;
  border: 1px solid #1678c8;
  font-weight: 600;
}

.dragAndDropClientIcon .browseContent .browseText {
  font-size: 14px;
  color: #666666;
}

.clientIconDiv {
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientIconDiv .clientImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

#ellipsisIcon {
  color: #000;
}

#clientNameText {
  cursor: pointer;
}

#clientLink {
  text-decoration: none;
}

.AddRowButtonClient {
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  color: #001d6d;
}

.activeInactiveTabDiv {
  border: 1px solid #0077c6;
  color: #0077c6;
  background: #ddf0fc;
  border-radius: 10px;
  padding: 2px 20px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  position: absolute;
  top: 32px;
  right: -10px;
  font-size: 12px;
  cursor: pointer;
}

#inactiveIdCss {
  margin-left: 15px;
  color: #000;
}

.projectInfoTeam .projectInfoTeamList.lastElement li:last-child {
  position: sticky;
  bottom: 0;
  background: #fff;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
}
.addEmployeeHorizontalLine{
  height: 3px !important;
  width: 90%;
  margin: auto;
  margin-top: 15px;
}
.projectInfoTeam
  .projectInfoTeamList
  li
  .projectListContentFixed
  .projectAddEmpName {
  font-size: 14px;
  font-weight: 600;
  color: #176b95;
  cursor: pointer;
}

.AddButtonInitial {
  font-size: 14px;
  font-weight: 600;
  color: #176b95;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 10px;
}

.projectEmpImages .projectAddEmpName {
  font-size: 16px;
  font-weight: 600;
  color: #176b95;
  cursor: pointer;
}

.addEmplDiv {
  display: flex;
  margin-top: 15px;
}

.managerNameHover {
  position: relative;
  cursor: pointer;
}

.managerNameHover .managerHoverContent {
  position: absolute;
  top: -27px;
  right: 32px;
  min-width: 165px;
  border-radius: 6px;
  padding: 5px 15px;
  background: #eeffff;
  border: 1px solid #2ea8a8;
  font-size: 14px;
  display: none;
}

.managerNameHover:hover .managerHoverContent {
  display: block;
}

#disableButton {
  pointer-events: none;
  opacity: 0.5;
}
.changeIcon {
  cursor: pointer;
}
.approverPriorityInfoDiv {
  display: none;
}
.approverPriorityInfoLabel {
  border: 1px solid #7eb6ff;
  color: gray;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
  position: absolute;
  top: -21px;
  left: 96px;
  background: #fff;
}
.approverPriorityInfoIcon {
  cursor: pointer;
  margin-left: 10px;
}
.approverPriorityInfoIcon:hover .approverPriorityInfoDiv {
  display: block;
}
.noOfProjectsDiv {
  font-size: 10px;
  font-weight: 600;
  background: #e9f5ff;
  padding: 0px 10px;
  width: fit-content;
  border-radius: 11px;
  margin: 5px 0px 5px 0px;
}

.add_employee_button_clients_page{
  background: transparent;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 30px;
  font-size: 16px;
  padding: 2px 22px;
}

@media screen and (max-width: 768px) {
  .employee_form_dialog .MuiDialog-paperWidthSm {
    width: 100%;
    max-width: 80%;
    max-height: 80%;
  }
}

@media screen and (max-width: 600px) {
  .employee_form_dialog .MuiDialog-paperWidthSm {
    max-width: 80%;
  }
}

@media only screen and (max-width: 1680px) {
  .EmpProjectInfo .projectTeam .employeeImg .projectEmpImages img,
  .EmpProjectInfo .projectTeam .projectEmployees .moreEmployeesCount,
  .EmpProjectInfo .projectTeam .projectManagerImg img {
    width: 30px;
    height: 30px;
  }

  .EmpProjectInfo .projectInfoBtns .inProgressBtn,
  .EmpProjectInfo .projectInfoBtns .primaryProjectBtn {
    font-size: 12px;
    padding: 3px 10px;
  }

  .EmpProjectInfo .projectInfoBtns .addProjects {
    font-size: 12px;
    padding: 3px 10px;
  }

  .addButton {
    font-size: 12px;
    padding: 3px 10px;
  }
}
