/* ****** SideNav css ******  */
.pms {
  background: linear-gradient(-45deg, rgb(248, 220, 130), rgb(238, 192, 40));
  border-radius: 5%;
  /* box-shadow: 8px 8px 10px rgb(118 110 110); */
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto; 
  height: 8rem;
}

.pms:hover {
  cursor: pointer;
  /* box-shadow: 3px 3px 5px aliceblue; */
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  z-index: 100;
  transition: all 0.5s ease;
  background: #0077C6;
}
.sidebar.close {
  width: 70px;
}
/* .sidebar-toggle {
  position: absolute;
  top: 10px;
  right: -20px;
  color: var(--lightblue);
  width: 35px;
  height: 35px;
  background: #0a3e5f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 10px -1px var(--lightblue);
  cursor: pointer;
  border: 1px solid #009cff;
} */
.sidebar-toggle {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  width: 100%;
  height: 50px;
  background: #0077C6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 25px;
  z-index: 9;
  border-top: 1px solid #fff;
}
.sidebar.close .sidebar-toggle .togglebtn {
  transform: rotate(180deg);
}
.close.sidebar .sidebar-toggle .sidebar-text {
  display: none;
}
.close.sidebar .sidebar-toggle {
  justify-content: center;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  text-align: center;
  padding: 15px;
}
.sidebar.close .logo-details .logo {
  display: none;
}
.sidebar .logo-details .favicon {
  display: none;
}
.sidebar.close .logo-details .favicon {
  display: block;
}
.sidebar .logo-details .logo {
  display: block;
  padding-left: 15px;
}
.sidebar .logo-details img {
  width: 90%;
}
.sidebar .logo-details img.favicon {
  width: 24px;
  margin: auto;
}
.sidebar .logo-details .sidebar-icon {
  font-size: 30px;
  color: #11101d;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;

}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
  cursor: pointer;
}
.sidebar .nav-links li:hover {
  background: #4ba4dd;
}
/* .sidebar .nav-links > li.active:before,
.sidebar .nav-links > li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 4px;
  height: 100%;
  background: #4BA4DD;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  border-top-right-radius: 5px;
  border-top-right-radius: 5px;
}
.sidebar .nav-links li.active:before,
.sidebar .nav-links li:hover:before {
  opacity: 1;
} */
.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar .nav-links li .iocn-link.active {
  background: red;
}
.sidebar.close .nav-links li .iocn-link {
  display: block;
}
.sidebar .nav-links li .sidebar-icon {
  /* height: 24px; */
  min-width: 70px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  /* margin: 15px 0; */
  pointer-events: none;
}

.sidebar .nav-links li.active a .sidebar-icon,
.sidebar .nav-links li:hover a .sidebar-icon,
.sidebar .nav-links li.active a .link_name,
.sidebar .nav-links li:hover a .link_name {
  color: #fff;
}
/* .sidebar .nav-links li .sidebar-icon.arrow {
  min-width: 52px;
} */
.sidebar .nav-links li.showMenu .sidebar-icon.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links .sidebar-icon.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
}
.sidebar .nav-links li a.active {
  background: #4BA4DD;
}
li.navlist a.active span,
li.navlist a.active svg {
  color: #fff !important;
}
.sidebar .nav-links li a .link_name {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  transition: all 0.4s ease;
  white-space: nowrap;
  pointer-events: none;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  /* padding-left: 55px; */
  background: #0077C6;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu,
.sidebar .nav-links .showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 14px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.3s ease;
  padding: 17px 15px;
  padding-left: 30px;
}
.sidebar .nav-links .sub-menu li:hover a {
  background: #4BA4DD;
}
.sidebar .nav-links .sub-menu li:first-child a {
  border-radius: 0px 5px 0 0;
}
.sidebar .nav-links .sub-menu li:last-child a {
  border-radius: 0px 0 5px 0;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  padding: 0;
}
.sidebar .nav-links li .sub-menu li:first-child {
  border-radius: 0 10px 10px 0;
}
.sidebar .nav-links li .sub-menu li:last-child {
  border-radius: 0 0 10px 0;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 14px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}
.sidebar .profile-details .job {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.5;
  white-space: nowrap;
}
.sidebar .profile-details .sidebar-icon.bx {
  min-width: 50px;
}

.sidebar.close .profile-details .sidebar-icon,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}
.home-section {
  position: relative;
  /* background: #E4E9F7; */
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  /* overflow: auto; */
}
/* 
.user-profile {
  position: relative;
  width: 70px;
  height: 40px;
  display: flex;
  column-gap: 5px;
  font-size: 12px;
  align-items: center;
  color: #0a3e5f;
  cursor: pointer;
  background: #4BA4DD;
  border-radius: 35px;
}
.user-profile .useSettingDownArrow {
  font-size: 16px;
  color: #fff;
}
.logout {
  display: flex;
  float: left;
  width: 200px;
  min-width: 180px;
  background: #ffffff;
  box-shadow: 0 0 5px -2px #297bde;
  color: white;
  position: absolute;
  z-index: 999;
  top: 40px;
  right: 5px;
  border-radius: 5px;
}
.logout ul {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
} */

.sidebar.close ~ .home-section {
  left: 70px;
  width: calc(100% - 70px);
  height: 100%;
  padding-bottom: 50px;
  z-index: 0;
}
section.home-section.dashboard {padding-bottom: 0 !important; overflow-x: hidden;}
.home-section .home-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* background: #e4e9f7;
  padding: 5px 15px;
  box-shadow: 3px 0 5px -2px grey; */
}
.Navbar_navLink__sDYF1 {
  text-decoration: none;
  padding: 5px 15px;
  color: #0a3e5f;
}
.userProfileMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  background: #f1f4fb;
}
.home-content .navbar-user {
  display: flex;
  align-items: center;
  padding: 15px;
  background: var(--lightblue);
  justify-content: flex-end;
  width: 100%;
  /* display: none; */
}
.home-content a.nav-reload-btn {
  display: flex;
  margin-right: 15px;
}
/* .logout ul li {
  padding: 10px 15px;
  cursor: pointer;
}
.logout ul li:hover,
.logout ul li:hover span,
.logout ul li:hover svg {
  background: #4996e3;
  color: #fff;
}

.logout ul li a,
.logout ul li span,
.logout ul li button {
  text-decoration: none;
  color: #0a3e5f;
  font-size: 14px;
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.logout ul li a .setting-icon,
.logout ul li .logout-btn {
  font-size: 18px;
  margin-right: 10px;
}
.logout:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -10px;
  right: 12px;
} */
.home-content .nav-reload-btn,
.home-content .nav-user-icon {
  color: #0a3e5f;
  cursor: pointer;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}
.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

/* ****** End SideNav css ******  */
.icon {
  transform: scale(2.5);
  margin-top: 30px;
}

.text {
  margin-top: 27px;
  font-size: larger;
  text-align: center;
}

.account {
  background: linear-gradient(-45deg, rgb(155, 155, 250), rgb(97, 97, 244));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.account:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.activity {
  background: linear-gradient(-45deg, rgb(247, 168, 151), rgb(246, 98, 35));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}

.activity:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.buying {
  background: linear-gradient(-45deg, rgb(114, 225, 128), rgb(16, 105, 24));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.buying:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.calender {
  background: linear-gradient(-45deg, rgb(139, 162, 254), rgb(17, 43, 191));
  border-radius: 5%;
  /* box-shadow: 8px 8px 10px rgb(118 110 110); */
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}

.calender:hover {
  cursor: pointer;
  /* box-shadow: 3px 3px 5px aliceblue; */
}

.humanresources {
  background: linear-gradient(-45deg, rgb(111, 218, 244), rgb(7, 166, 229));
  border-radius: 5%;
  /* box-shadow: 8px 8px 10px rgb(118 110 110); */
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}

.humanresources:hover {
  cursor: pointer;
  /* box-shadow: 3px 3px 5px aliceblue; */
}

.installer {
  background: linear-gradient(-45deg, rgb(250, 148, 148), rgb(234, 26, 26));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.installer:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.manufacturing {
  background: linear-gradient(-45deg, rgb(103, 195, 211), rgb(24, 94, 235));
  border-radius: 5%;
  /* box-shadow: 8px 8px 10px rgb(118 110 110); */
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}
/* 
.manufacturing:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
} */

.messages {
  background: linear-gradient(-45deg, rgb(121, 206, 221), rgb(24, 94, 235));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.messages:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.notes {
  background: linear-gradient(-45deg, rgb(174, 146, 249), rgb(94, 3, 239));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.notes:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.pos {
  background: linear-gradient(-45deg, rgb(248, 220, 130), rgb(200, 164, 46));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}

.pos:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.projects {
  background: linear-gradient(-45deg, rgb(248, 156, 238), rgb(230, 25, 148));
  border-radius: 5%;
  /* box-shadow: 8px 8px 10px rgb(118 110 110); */
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}

/* .projects:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
} */

.selling {
  background: linear-gradient(-45deg, rgb(133, 215, 200), rgb(5, 209, 165));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.selling:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.stock {
  background: linear-gradient(-45deg, rgb(170, 199, 108), rgb(90, 106, 11));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.stock:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.support {
  background: linear-gradient(-45deg, rgb(124, 222, 202), rgb(35, 103, 121));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.support:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.todo {
  background: linear-gradient(-45deg, rgb(248, 146, 146), rgb(234, 26, 26));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.todo:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}

.website {
  background: linear-gradient(-45deg, rgb(245, 156, 233), rgb(168, 12, 105));
  border-radius: 5%;
  /* box-shadow: 8px 8px 10px rgb(118 110 110); */
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}

.website:hover {
  cursor: pointer;
  /* box-shadow: 3px 3px 5px aliceblue; */
}

.link-css {
  color: rgb(64, 133, 193);
  text-decoration: none;
}

.setup {
  background: linear-gradient(-45deg, rgb(137, 137, 230), rgb(97, 97, 244));
  border-radius: 5%;
  /* box-shadow: 8px 8px 10px rgb(118 110 110); */
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 8rem;
}

.setup:hover {
  cursor: pointer;
  /* box-shadow: 3px 3px 5px aliceblue; */
}

.core {
  background: linear-gradient(-45deg, rgb(149, 148, 148), rgb(0, 0, 0));
  border-radius: 5%;
  box-shadow: 8px 8px 10px rgb(118 110 110);
  color: aliceblue;
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 7rem;
}

.core:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px aliceblue;
}
.closeMenuMobile {
  display: none;
}

@media screen and (max-width: 1050px) {
  .icon {
    transform: scale(2);
    margin-top: 25px;
  }

  .text {
    font-size: medium;
    margin-top: 15px;
  }

  .account {
    width: 6rem;
    height: 6rem;
  }

  .activity {
    width: 6rem;
    height: 6rem;
  }

  .buying {
    width: 6rem;
    height: 6rem;
  }

  .calender {
    width: 6rem;
    height: 6rem;
  }

  .humanresources {
    width: 6rem;
    height: 6rem;
  }

  .installer {
    width: 6rem;
    height: 6rem;
  }

  .manufacturing {
    width: 6rem;
    height: 6rem;
  }

  .messages {
    width: 6rem;
    height: 6rem;
  }

  .notes {
    width: 6rem;
    height: 6rem;
  }

  .pos {
    width: 6rem;
    height: 6rem;
  }

  .projects {
    width: 6rem;
    height: 6rem;
  }

  .selling {
    width: 6rem;
    height: 6rem;
  }

  .stock {
    width: 6rem;
    height: 6rem;
  }

  .support {
    width: 6rem;
    height: 6rem;
  }

  .todo {
    width: 6rem;
    height: 6rem;
  }

  .website {
    width: 6rem;
    height: 6rem;
  }

  .setup {
    width: 6rem;
    height: 6rem;
  }

  .core {
    width: 6rem;
    height: 6rem;
  }
}
@media screen and (max-width: 850px) {
  /* .home-section {
    left: 0;
    width: calc(100% - 0px);
  } */
  .icon {
    transform: scale(1.5);
    margin-top: 15px;
  }

  .text {
    font-size: small;
    margin-top: 10px;
  }

  .account {
    width: 5rem;
    height: 5rem;
  }

  .activity {
    width: 5rem;
    height: 5rem;
  }

  .buying {
    width: 5rem;
    height: 5rem;
  }

  .calender {
    width: 5rem;
    height: 5rem;
  }

  .humanresources {
    width: 5rem;
    height: 5rem;
  }

  .installer {
    width: 5rem;
    height: 5rem;
  }

  .manufacturing {
    width: 5rem;
    height: 5rem;
  }

  .messages {
    width: 5rem;
    height: 5rem;
  }

  .notes {
    width: 5rem;
    height: 5rem;
  }

  .pos {
    width: 5rem;
    height: 5rem;
  }

  .projects {
    width: 5rem;
    height: 5rem;
  }

  .selling {
    width: 5rem;
    height: 5rem;
  }

  .stock {
    width: 5rem;
    height: 5rem;
  }

  .support {
    width: 5rem;
    height: 5rem;
  }

  .todo {
    width: 5rem;
    height: 5rem;
  }

  .website {
    width: 5rem;
    height: 5rem;
  }

  .setup {
    width: 5rem;
    height: 5rem;
  }

  .core {
    width: 5rem;
    height: 5rem;
  }
}
.mobMenu {
  display: none;
}

@media screen and (max-width: 768px) {
  .sidebar, .close.sidebar{display: none;}
  .sidebar.close ~ .home-section { left: 0px; width: calc(100% - 0px); height: 100vh;}
  .mobMenu { display: block;}
  .mobMenu ul.mobileMenu li a { color: #000; font-size: 14px; text-decoration: none; display: flex; padding: 10px 0; align-items: center; column-gap: 20px; padding-left: 20px;}
  .mobMenu ul.mobileMenu li ul.leaveApplicationSubmenu li a {
    padding-left: 20px;
}
  .mobMenu ul.mobileMenu li a.mobMenues.active { background: #0077C6; color: #fff;}
  .mobMenu ul.mobileMenu li a.active svg, .mobMenu ul.mobileMenu li a.active i { color: #fff;}
  .mobMenu ul.mobileMenu li a svg,  .mobMenu ul.mobileMenu li a i { color: #333; margin-right: 0; width: 20px; font-size: 16px; text-align: center;} 
  .mobMenu.close { width: 100%; padding-right: 15%; background: #fff; position: absolute; left: -100%;}
  .mobMenu { width: 100%; height: 100%; padding-right: 15%; background: #fff; position: fixed; top: 0; left: -100%; z-index: 999; overflow-y: hidden; transition: 0.3s ease-in-out;}
  .mobMenu.active{left: 0; transition: 0.3s ease-in-out;}
  .mobileMenuClose { position: absolute; top: 20px; right: -50px; color: #fff; font-size: 20px; }
  .mobMenu.close ~ .home-section { left: 0px; width: calc(100% - 0px); }
  .mobMenu:after { content: ""; background: #000000CC; width: 15%; height: 100%; position: absolute; z-index: 9; top: 0; right: 0; }
  .closeMenuMobile {display: block; position: absolute; top: 0; right: 0; color: #fff; z-index: 10; font-size: 20px; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; }
  .mobMenu .closeMenuMobile {text-decoration: none; color: #fff;}
  /* section.home-section.dashboard {overflow: hidden;} */
  .mobMenu .mobileMenu .leaveApplication { display: flex; align-items: center; justify-content: space-between;}
  .mobMenu .mobileMenu .leaveApplicationSubmenu { list-style: none; background: #eef8ff;}
  /* .mobMenu .mobileMenu .leaveApplicationSubmenu li a { padding-left: 15px; } */
}

