/* Common Css */
.MuiOutlinedInput-input {
  padding: 5px 15px !important;
}
.create_form_heading {
  font-size: 14px;
  color: #001d6d;
  font-weight: 600;
  margin-bottom: 10px;
}

.create_form_labels {
  font-size: 14px;
  margin-bottom: 5px;
  padding-top: 12px;
}
.create_form_labels.addEmpSetPass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}
.create_form_asterisk {
  font-size: 14px;
  color: red;
}
.accordion-body.addEmpDialog {
  padding: 0;
}
.create_form_main_div {
  overflow: auto;
  overflow-x: hidden;
}

.create_form_add_row_button {
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  color: #001d6d;
  float: right;
  margin-right: 15px;
}

#create_form_save_button {
  float: right;
  margin-right: 20px;
  width: 122px;
  background: #7e009e;
}

#create_form_save_button:hover {
  background: #fff;
  color: #7e009e;
  border: #7e009e 2px solid;
}

.delete_icon {
  color: rgb(249, 76, 76);
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
}

.create_form_input_div {
  display: flex;
  flex-direction: column;
}

.create_form_input_div.autoSelect
  .MuiInputBase-fullWidth.MuiInputBase-formControl {
  padding: 0;
}

.create_form_input_div.autoSelect .empInput {
  margin-top: 0 !important;
}

/* ********************************************************** */

.upload_resume {
  top: 253px;
  left: 550px;
  width: 180px;
  height: 210px;
  opacity: 1;
}

.label_resume {
  top: 253px;
  left: 550px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  color: #000000;
  opacity: 1;
}

.basic_flex {
  display: flex;
  flex-direction: row;
}

.basic_data {
  flex-direction: row;
  padding: 0px 0px 0px 20px;
}

/* Personal Information Css */

.personal_info_inner_heading {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

#current_address_checkbox_label {
  float: right;
  margin-right: 15px;
}

.role_label {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 10px 20px 0px 15px;
}

.newUserImageUpload {
  border: 1px dashed #aaaaaa;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
  min-width: 185px;
  max-width: 185x;
  flex-direction: column;
}

.newUserImageUpload.changePhoto {
  max-width: 50%;
}

.newUserImageUpload .uploadContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 10px 0;
}

.newUserImageUpload .uploadContent .uploadIcon {
  font-size: 30px;
  color: #cccccc;
}

.newUserImageUpload .uploadContent .uploadText {
  color: #333333;
}

.newUserImageUpload .dragAndDropLine {
  width: 1px;
  background: #cccccc;
  color: #333333;
  position: relative;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.newUserImageUpload .dragAndDropLine:after {
  content: "";
  width: 20px;
  height: 25px;
  position: absolute;
  background: #ffffff;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.newUserImageUpload .browseContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 10px;
  padding: 10px 0;
}

.newUserImageUpload .browseContent .browseBtn {
  padding: 5px 0px;
  border-radius: 35px;
  color: #1678c8;
  background: #fff;
  border: 1px solid #1678c8;
  font-weight: 600;
}

.newUserImageUpload .browseContent .browseText {
  font-size: 14px;
  color: #666666;
}

@media screen and (max-width: 600px) {
  .create_form_labels.addEmpSetPass {
    font-size: 13px;
  }
}
