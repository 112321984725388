/* All Employees Grid Css */

.empHeader {
  width: 100%;
  height: 50px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 1px #00000029;
}

.empTabs {
  background: #fff;
}

ul.empTabs li .empTabBtn {
  background: transparent;
  color: #000;
  padding: 15px 30px;
  border-radius: inherit;
}

ul.empTabs li .empTabBtn.active {
  background: transparent;
  color: #555555;
  font-weight: 600;
  position: relative;
  border-bottom: 1px solid #555555;
}

ul.empTabs li .empTabBtn.active:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #555;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

/* All Employees Filter css  */
.empFilterMenu .empTableGrid {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-right: 20px;
}
.empFilterMenu .empTableGrid .empGridIcon {
  padding: 5px 15px;
  border: 1px solid #00000029;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  position: relative;
}
.empFilterMenu .empTableGrid .empGridIcon:hover {
  border: 1px solid #ac09ac;
}
.empFilterMenu .empTableGrid .empGridIcon:hover svg {
  color: #ac09ac;
}
.empFilterMenu .empTableGrid .empGridIcon.active {
  color: #ac09ac;
  border: 1px solid #ac09ac;
}
.empFilterMenu .empTableGrid .empGridIcon .EmpGridToolTip {
  display: none;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  white-space: nowrap;
  border: 1px solid #0077c6;
  color: #0077c6;
  border-radius: 5px;
  padding: 3px 15px;
  transition: all 0.3s ease-in-out;
}
.empFilterMenu .empTableGrid .empGridIcon .EmpGridToolTip:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #0077c6;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.empFilterMenu .empTableGrid .empGridIcon:hover .EmpGridToolTip {display: block;}

.empFilterMenu {
  border-top: 1px solid #00000029;
  background: #fff;
  border-bottom: 1px solid #00000029;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.empFilterMenu .empFilterBy {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.empFilterMenu .add_employee_button {
  background: transparent;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 30px;
  font-size: 16px;
  padding: 7px 30px;
}
.empFilterMenu .empFilterBy .empFilterByList {
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  border: 1px solid #6666664d;
  border-radius: 10px;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}
.empFilterMenu .empFilterBy .empFilterLabel .by {
  font-weight: 600;
}
.empFilterMenu .empFilterBy .empFilterByList li {
  padding: 10px 15px;
  background: #fff;
  color: #333333;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  min-width: 250px;
  border-bottom: 1px solid #6666664d;
}
.empFilterMenu .empFilterBy .empFilterByList li.active {
  background: #ac09ac;
  color: #fff;
}
.empFilterMenu .empFilterBy .empFilterByList li:first-child {
  border-radius: 10px 10px 0 0;
}
.empFilterMenu .empFilterBy .empFilterByList li:last-child {
  border-radius: 0 0 10px 10px;
  border-bottom: none;
}
.empFilterMenu .empFilterBy .empFilterByList li svg {
  color: #cccccc;
}
.empFilterMenu .empFilterBy .empFilterLabel {
  white-space: nowrap;
  margin-right: 15px;
  display: flex;
  align-items: center;
  border-right: 1px solid #cccccc;
  position: relative;
}
.empFilterMenu .empFilterBy .empFilterLabel .empFilterLabelDropdown.active {
  background: #ac09ac;
  color: #fff;
}
.empFilterMenu .empFilterBy .empFilterLabel .empFilterLabelDropdown {
  background: #fff;
  padding: 13px;
  margin-left: 15px;
  display: flex;
  column-gap: 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.empFilterMenu .addEmpBtn {
  background: #fff;
  padding: 5px 0;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  right: 0;
  white-space: nowrap;
}
.empFilterBy .empFilterByList .empFilterText {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.empFilterMenu .empFilterBy .empFilterByList li.active .empFilterText svg {
  color: #fff;
}
.empFilterMenu .empFilterRightCol {
  padding-left: 0;
}
/* .empTabContent .allEmpContent {
  height: calc(100vh - 189px);
  overflow-y: auto;
  padding-bottom: 60px;
} */
.empTabContent .allEmpContent .allemployeesTable, .empTabContent .allEmpContent .allemployeesGrid {
  height: calc(100vh - 229px);
  overflow: auto;
}

.empTabContent {
  background: #f1f4fb;
}
.empCard {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1px 1px #00000029;
  transition: all 0.25s ease-in-out;
}
.empCard:hover {
  box-shadow: 3px 3px 1px #97a8d2;
  transform: translate3d(-2px, -2px, -2px);
}
.empCardTopbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0;
}
.empCardId {
  font-size: 14px;
  color: #222222;
}
.card_heading_icon {
  font-size: 20px;
  color: #333333;
  cursor: pointer;
}
.empCardImg {
  text-align: center;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.empCard .empCardImg .empCardAvatar {
  width: 90px;
  height: 90px;
  font-size: 45px;
}
.empCard .empCardInnerContent {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 15px 0 15px 15px;
}
.empCardImg img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.empCardImg .empImgActiveStatus {
  width: 14px;
  height: 14px;
  outline: 2px solid #fff;
  border-radius: 50%;
  background: green;
  position: absolute;
  top: 5px;
  right: 7px;
  z-index: 1;
}
.empCardImg.empTableImg .empImgActiveStatus, .empCardImg.empTableImg .empImgLeftStatus {
  width: 10px;
  height: 10px;
  top: 2px;
  left: 30px;
}
.empCardImg.onLeave .empImgActiveStatus {
  background: #d95700;
}
.empCardImg.onLeave img {
  border: 2px solid #d95700;
}
.empCardContent {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.empCardName {
  font-size: 18px;
  color: #222222;
  font-weight: 600;
  margin: 0;
}
.empCardDesignation {
  color: #222222;
  padding: 10px 0;
}
.card_workmode,
.card_workmode_office,
.card_on_leave {
  display: flex;
  font-size: 14px;
  font-weight: 600;
}
.card_workmode {
  color: #560098;
}
.card_workmode_office {
  color: #035700;
}
.card_on_leave {
  color: #d95700;
}
.card_workmode_icon {
  font-size: 16px;
}
.card_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 10px;
}
.empPagination {
  position: sticky;
  left: 0;
  bottom: 0;
  background: #f1f4fb;
  padding: 10px 0;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.empPagination .paginationCount ul li button {
  font-size: 18px;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
}
.empPagination .paginationCount ul li button svg {
  font-size: 28px;
}

.empCardImg .empImgLeftStatus {
  width: 14px;
  height: 14px;
  outline: 2px solid #fff;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 5px;
  right: 7px;
  z-index: 1;
}

/* Employee Form Css */

.add_employee_main_heading {
  font-size: 16px;
  color: #7e009e;
}

.add_employee_heading {
  margin-left: 15px;
}

.add_employee_cross_icon {
  font-size: 22px;
  color: #333333;
  float: right;
  cursor: pointer;
}

.add_employee_dialog {
  display: flex;
  justify-content: center;
}

.add_employee_dialog .MuiDialog-paperWidthSm {
  min-width: 858px;
}

.empFilterMenu .empfilterBtn,
.empFilterMenu .empfilterSearchBtn {
  display: flex;
  align-items: center;
}
.empfilterSearchBtn .searchBox {
  padding: 5px 0;
}

/* All Employee Table view css */
.empReportsTable .reportsTableContainer.allEmpTableView {
  background: #fff;
  border: 1px solid #00000029;
  border-radius: 10px;
  overflow-x: auto;
  font-size: 14px;
}
.reportsTableContainer.allEmpTableView table thead tr th {
  padding: 20px 15px !important;
  font-weight: 600;
}
.reportsTableContainer.allEmpTableView table tbody tr {
  border-top: 1px solid #00000029;
}
.empReportsTable .reportsTableContainer.allEmpTableView table, .empReportsTable .reportsTableContainer table tr th, .empReportsTable .reportsTableContainer table tr td {
  border: 0 !important;
  margin-bottom: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.reportsTableContainer.allEmpTableView .allEmpProfileImg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.reportsTableContainer.allEmpTableView .empTableImg {
  display: flex;
  text-align: left;
  align-items: center;
  width: 40px;
  height: 40px;
}
.reportsTableContainer.allEmpTableView table tr th:nth-of-type(1) {
  min-width: 120px;
}
.reportsTableContainer.allEmpTableView table tr th:nth-of-type(2) {
  min-width: 250px;
}
.reportsTableContainer.allEmpTableView .empTableImg .allEmpProfileName {
  white-space: nowrap;
}
.reportsTableContainer.allEmpTableView .empTableImg .allEmpProfileName .profileName {
  font-weight: 600;
}
.reportsTableContainer.allEmpTableView .empTableImg .allEmpProfileName .profileDesignation {
  font-size: 13px;
}

/* End of All Employee Table view css */

.employee_Att_TopHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #00000029;
}
ul.employee_Att_TopHeader_Menu {
  display: flex;
  align-items: center;
  list-style: none;
}
ul.employee_Att_TopHeader_Menu .employeeAttTab {
  background: transparent;
  color: #555555;
  border-bottom: 1px solid transparent;
  position: relative;
  font-weight: 600;
  padding: 8px 30px;
  cursor: pointer;
}
ul.employee_Att_TopHeader_Menu .employeeAttTab.active {
  border-bottom: 1px solid #555;
}
ul.employee_Att_TopHeader_Menu .employeeAttTab.active:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #555;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}
@media screen and (max-width: 1680px) {
  .empCard .empCardInnerContent {
    padding: 12px 0 15px 12px;
  }
  .empCardName,
  .empCardDesignation {
    font-size: 16px;
  }
}
@media screen and (max-width: 1679px) {
  .empCard .empCardInnerContent {
    padding: 15px 0 15px 15px;
  }
}

@media screen and (max-width: 1440px) {
  .empCardName,
  .empCardDesignation {
    font-size: 14px;
  }
  .empCard .empCardInnerContent {
    column-gap: 10px;
  }
}

@media screen and (max-width: 1366px) {
  .empCard .empCardInnerContent {
    column-gap: 15px;
  }

  .empCardName,
  .empCardDesignation {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .empPagination {
    position: sticky;
  }
}
@media screen and (max-width: 1095px) {
  .empFilterMenu .empTableGrid {
    margin-top: 5px;
  }
  .empFilterMenu .empTableGrid .empGridIcon .EmpGridToolTip {
    left: 0%;
  }
  .empFilterMenu .empTableGrid .empGridIcon .EmpGridToolTip:after {left: 75%;}
}
@media screen and (max-width: 1024px) {
  .empTabContent .allEmpContent .allemployeesTable, .empTabContent .allEmpContent .allemployeesGrid {
    height: calc(100vh - 293px);
    overflow: auto;
  }
  .add_employee_dialog .MuiDialog-paperWidthSm {
    min-width: 600px;
  }
  .empFilterMenu .empTableGrid {
    margin-top: 5px;
  }
}
@media screen and (max-width: 768px) {
  .empTabContent .allEmpContent .allemployeesTable, .empTabContent .allEmpContent .allemployeesGrid {
    height: calc(100vh - 338px);
    overflow: auto;
  }
  .add_employee_dialog .MuiDialog-paperWidthSm {
    min-width: 500px;
  }
}
@media screen and (max-width: 600px) {
  .empCardName,
  .empCardDesignation {
    font-size: 18px;
  }
  .add_employee_dialog .MuiDialog-paperWidthSm {
    min-width: 400px;
  }
  .empFilterMenu .empFilterBy.empFilterSort .empFilterLabel {
    margin-right: 0;
    border-right: 0;
  }
}
@media screen and (max-width: 480px) {
  .add_employee_dialog .MuiDialog-paperWidthSm {
    min-width: 320px;
  }
  .empCardName,
  .empCardDesignation {
    font-size: 16px;
  }
  .empfilterSearchBtn .searchBox .searchBoxInput {
    min-width: auto;
  }
  .empPagination .paginationCount ul li button {
    font-size: 14px;
    width: 28px;
    height: 28px;
  }
  .empfilterSearchBtn .searchBox .searchBoxInput,
  .empFilterMenu .add_employee_button {
    font-size: 14px;
  }
  .searchBox .searchBoxIcon {
    font-size: 16px;
  }
}
@media screen and (max-width: 457px){
  .empTabContent .allEmpContent .allemployeesTable, .empTabContent .allEmpContent .allemployeesGrid {
    height: calc(100vh - 365px);
    overflow: auto;
  }
}