  /* user profile */
  .userSetting { position: relative; width: 40px; height: 40px; color: #fff; cursor: pointer; border-radius: 35px; }
  .userSetting .logout .userDetails { background: #FAFAFA; padding: 24px 20px; display: flex; align-items: center; column-gap: 10px; color: #000; border-radius: 10px 10px 0 0; border-bottom: 1px solid #efefef;}
  .userSetting .logout .userDetails .userProfileText .userProfileName { color: #790098; font-weight: 500; font-size: 18px; }
  .userSetting .logout ul li:last-child, .logout ul li a:last-child { border-radius: 0 0 10px 10px;}
  .userSetting .logout ul li .userSettingIcons { font-size: 16px; color: #790098; width: 60px; padding-left: 20px;}
  .userSetting .userContent { text-align: right; line-height: 18px; }
  .userSetting .userContent .userName { font-weight: 500; font-size: 14px; color: #006edc;}
  .userSetting .userContent .userDesig { font-size: 14px; color: #666;}
  .userSetting .userIcon { width: 35px; height: 35px; border-radius: 50%; cursor: pointer;}
  .userSetting .userIcon svg { width: 35px; height: 35px; color: #0077c6;}
  .userSetting .userIcon img { width: 35px; height: 35px; border-radius: 50%;  object-fit: cover;}
  .userSetting .uSettingPassIcon { color: #790098; font-size: 8px; width: 60px; padding-left: 20px;}
  .userSetting .userMenu { color: #0077c6; font-size: 28px;}
  
  /* user logout css */
.logout { display: flex; float: left; width: 200px; min-width: 300px; cursor: default; background: #ffffff; border: 1px solid #C3C9D4; color: white; position: absolute; z-index: 1000; top: 55px; right: 0; border-radius: 10px; }
.logout ul { list-style: none; width: 100%; margin: 0; padding: 0; }
.logout ul li {cursor: pointer; }
/* .logout ul li:hover, .logout ul li:hover span, .logout ul li:hover svg { background: #fafafa; color: #333; } */
.logout ul li a, .logout ul li span, .logout ul li button { text-decoration: none; color: #333; font-size: 14px; display: flex; align-items: center; }
.logout ul li a .setting-icon, .logout ul li .logout-btn { font-size: 18px; margin-right: 10px; }
/* .logout:after { content: ""; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid #fff; position: absolute; top: -10px; right: 12px; } */
.userProfileMobile, .userProfileSettingMobile {display: none;}
.logout ul li a:hover {background: #fafafa;}
.logout ul li a {padding: 10px 0px;}

@media screen and (max-width: 768px) {
.userSetting {display: none;}
.userProfileMobile, .userProfileSettingMobile { display: block; position: sticky; top: 0;}
.userProfileMobile .userDetails { background: #FAFAFA; padding: 24px 20px; display: flex; align-items: center; column-gap: 10px; color: #000; border-radius: 10px 10px 0 0; }
.userProfileMobile .userProfileName { color: #7E009E; font-weight: 600; }
.userProfileMobile .userProfileDesig {font-size: 14px;}
.userProfileSettingMobile { bottom: 0; position: sticky; width: 100%; padding: 10px 0; background: #F9F9F9; padding-right: 15%;}
.userProfileSettingMobile ul { display: flex; align-items: center; justify-content: space-between; padding: 0 35px; }
.userProfileSettingMobile ul li a { padding: 12px 0; color: #333; }
.home-section {left: 0px; width: calc(100% - 0px);}
.sidebar .nav-links li:hover {background: transparent;}
.dsContent { overflow-y: hidden; }
}




