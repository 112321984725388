.main {
    width: 100%;
    padding: 15px !important;
    margin-right: auto;
    margin-left: auto;
  }
  .leaveheading {
    display: flex;
    flex-direction: row;
    /* margin: 25px 0px 25px 0px; */
  }
  .div1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin-bottom: 1px;
  }
  