.topHeader { width: 100%; background: #F1F4FB; display: flex; align-items: center; justify-content: space-between; padding: 5px 30px; position: sticky; top: 0; z-index: 9;}
.topHeader .topHeaderleft { display: flex; align-items: center; column-gap: 20px; }
.topHeader .topHeaderRight { display: flex; align-items: center; column-gap: 30px; }
.topHeaderleft .mobileMenuIcon {
    display: none;
}
@media screen and (max-width: 768px) {
    .topHeaderleft .mobileMenuIcon {
        display: block;
    }
}