.financialDetails { margin-top: 20px; }
.financialDetails .financialDetailsContent { font-size: 14px; display: flex; align-items: baseline; justify-content: flex-start; line-height: 35px; }
.financialDetails .financialDetailsContent .financialDetailsLabel { color: #333333; min-width: 180px;}
.financialDetails .financialDetailsContent .financialDetailsTxt.financialDetailsBoldTxt { font-weight: 400; line-height: 20px;}
.financialDetails .financialDetailsContent .financialDetailsTxt {color: #000000; font-weight: 600;}

/* Variable Pay Table */
.variablePayWrapper { background: #F1F4FB; border-top: 1px solid #00000029;}
.financeTable { background: #fff; margin-bottom: 10px;}
.financeTable .MuiDataGrid-columnHeaders {background: #f0f9ff;}
.financeVariablePay .variablePayTitle { display: flex; align-items: center; justify-content: space-between; padding: 15px 20px; }
.financeVariablePay .variablePayTitle .variableHeading { font-size: 18px; font-weight: 500; color: #001D6D; }
.financeVariablePay .variablePayTitle button.variablePeyBtn { color: #790098; padding: 5px 25px; border: 1px solid #7E009E; border-radius: 35px; background: #fff; font-size: 14px; }
.variablePayTable { overflow-x: auto; width: 100%; white-space: nowrap; }
.variablePayTable .tableHead {background: #F0F9FF;}
.variablePayTable .tableHead tr th { padding: 15px 0 !important; }
.variablePayTable table { box-shadow: 0px 1px 1px #00000029; margin-bottom: 0; }
.variablePayTable table, .variablePayTable table th, .variablePayTable table tr td {border: none !important;}
.variablePayTable thead tr th { font-size: 14px; font-weight: 600; }
.variablePayTable table tbody tr td { color: #000000; font-size: 14px; padding: 12px !important; }
.variablePayTable table tbody tr td:first-child, .variablePayTable table thead tr th:first-child {padding-left: 20px !important;}

.variablePayTable .partOfCtc { font-weight: 600; display: inline-flex; align-items: center; padding: 2px 20px; border-radius: 20px; font-size: 14px; column-gap: 15px; }
.variablePayTable .partOfCtc.ctcSuccess{background: #DBF6DB; color: #007C00;}
.variablePayTable .partOfCtc.ctcWarning{background: #FFEBD4; color: #934F00;}
.variablePayTable .variablePayAction { display: flex; align-items: center; column-gap: 20px; } 
.variablePayTable .variablePayAction .trashIcon { color: #DA3848; }


.financeAnnualSalary { background: #FAFAFA; padding: 20px 10px; height: 100%; }
/* .financeAnnualSalary .annualSalary {padding: 0 30px 20px;} */
.financeAnnualSalary .annualSalaryContent, .financeAnnualSalary .monthlySalaryContent { display: flex; align-items: center; justify-content: space-between; padding: 10px 20px; }
.financeAnnualSalary .annualSalaryContent .annualSalaryLabel, .financeAnnualSalary .monthlySalaryContent .monthlySalaryLabel { font-size: 14px; color: #333333; }
.financeAnnualSalary .annualSalaryContent .annualSalaryAmount, .financeAnnualSalary .monthlySalaryContent .monthlySalaryAmount { font-weight: 600; font-size: 14px; color: #000000; }
.financeAnnualSalary .monthlySalary { background: #fff; border: 1px solid #CCCCCC; margin-top: 10px;}
.financeAnnualSalary .monthlySalary .monthlySalaryContent.netPay { font-weight: 600; padding: 10px 20px; background: #DAF0FF; }
.financeAnnualSalary .monthlySalary .monthlyDeductionHeading { font-size: 16px; color: #333333; padding: 10px 20px; font-weight: 600; }
.empTitle.annualSalaryTitle { margin-top: 20px; padding-left: 20px; }

.tableBg{
    background-color: #F1F4FB;
}

.tableWrapper{
    box-shadow: 0px 1px 1px #00000029;
    background:#fff;
    margin-top:20px;
}

/* .salarySummary{
    background: #FAFAFA 0% 0% no-repeat padding-box;
box-shadow: inset 1px 0px 1px #00000029;
} */
@media screen and (max-width: 1480px) {
    .financialDetails .financialDetailsContent .financialDetailsLabel { min-width: 170px; }
    .financialDetails .financialDetailsContent { font-size: 13px;}
}

@media screen and (max-width: 768px) {
    .mobilePadding{padding-right: 0;}
    .financeAnnualSalary { padding: 0; display: flex; }
    .financeAnnualSalary .annualSalary { width: 50%; padding-top: 20px; }
    .financeAnnualSalary .monthlySalary { border: none; margin-top: 0; width: 50%; }
}
@media screen and (max-width: 600px) {
    .financeAnnualSalary { padding: 20px 0 0 15px; display: block; }
    .financeAnnualSalary .annualSalary { width: 100%; padding-top: 0; }
    .financeAnnualSalary .monthlySalary {border-top: 1px solid #CCCCCC; margin-top: 10px; width: 100%;}
}
