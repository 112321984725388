.main {
  width: 100%;
  max-width: 1340px;
  padding: 15px!important;
  margin-right: auto;
  margin-left: auto;
}

.head {
  display: flex;
  justify-content: space-between;
}

.leaveheading {
  display: flex;
  flex-direction: row;
  /* margin: 25px 0px 25px 0px; */
}

.newbutton {
  align-items: center;
  background: var(--primary);
  border-radius: 5px;
  box-shadow: rgb(255 255 255 / 26%) 0 1px 2px inset;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  padding: 10px 21px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.newbutton:hover {
  background: var(--primaryhover);
  transform: scale(1.01);
  cursor: pointer;
}

.roles {
  display: flex;
  color: rgb(1 121 179);
  align-items: center;    
  width: 100%;
  height: 100%;
}

.popup {
  background-color: #fb0000;
  color: aliceblue;
  position: absolute;
  left: 23.9%;
  font-size: small;
  top: 56%;
  padding: 0px 5px 0px 3px;
  border-radius: 45%;
  text-align: center;
  width: fit-content;
}

#permbutton {
  background-color: #b6ddf3;
  width: fit-content;
  padding: 5px;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  margin: 20px 0px 0px 35px;
}

.setupmain {
  width: 100%;
  /* display: flex;
  column-gap: 20px;
  justify-content: space-between;
  flex-wrap: nowrap; */
}
.setupmain a{
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  border: 1px solid var(--blue);
  border-radius: 5px;
  text-align: center;
  align-items: center;
  box-shadow: 5px 5px 10px -2px #dfdfdf;
  transition: .5s;
  margin-bottom: 15px;
  cursor: pointer;
}
.setupmain a h3{
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  padding: 5px;
  width: 100%;
}
.setupmain a:hover {
  box-shadow: 10px 10px 1px #d3d3d3;
  border: 1px solid var(--navyblue);
  transform: translateY(-10px);
  transition: .5s;
  background: #fafcfd;
}
.setupmain a:hover h3 {
  color: var(--navyblue);
  /* text-decoration: underline; */
}
.hrTabLeftAsside {
  width: 50px;
  background: #01a9f4;
  height: 200px;
  line-height: 200px;
  display: flex;
  align-items: center;
  border-radius: 5px 0 0 5px;
  transition: .5s;
  text-align: center;
  justify-content: center;
  padding: 15px;
  box-shadow: 3px 0px 1px 0px #eeeeee;
}
.SetUp_setupmain__omfSe a:hover .hrTabLeftAsside {
  background: var(--navyblue);
  transition: .5s;
}
.hrTabIcons {
  color: var(--white);
}

#head {
  text-decoration: none;
}

#head:hover {
  text-decoration: underline;
  color: rgb(27, 106, 197);
}

#setuphr {
  border: solid 1px #c5bdbd;
  width: initial;
  margin: 5px 0px 5px 40px;
}
/* ********************************* */
@media only screen and (max-width: 1024px) {
  
}
@media only screen and (max-width: 480px) {
  .setupmain a{
    width: 100%;
  }
}

