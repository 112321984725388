.boxText {
  background: #d8f0ff;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}

.boxTextEL {
  background: #fff8e3;
  border-radius: 5px;
  padding: 10px;
}

.noteText {
  text-align: left;
  font-size: 13.5px;
  color: #00366b;
}
.noteTextEL {
  text-align: left;
  font-size: 14.5px;
  color: #795a00;
}
.empSeprationBtn {
  display: flex;
  column-gap: 20px;
  justify-content: flex-end;
  margin-top: 20px;
}
button.noMindChanged {
  background: #7e009e;
  padding: 10px 30px;
  border-radius: 35px;
  border: 1px solid #7e009e;
  color: #fff;
}
button.resignSubmit {
  background: #fff;
  padding: 5px 20px;
  border-radius: 35px;
  border: 1px solid #333333;
  color: #333333;
}
.empSeprationReason {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 30px;
  flex-wrap: wrap;
}
.empSeprationReason .empSeprationStatus {
  width: 30%;
}
.empSeprationReason.empSepStatus .empSeprationStatus {
  width: 45%;
}
.empSeprationReasonHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.status-in-process {
  background: #fffbee;
  border: 1px solid #b78a20;
  border-radius: 20px;
  color: #ae5700;
  text-align: center;
  font-size: 14px;
  display: inline;
  padding: 0 20px;
}
.status-revoked {
  background: #cdfecd;
  border: 1px solid #abf3ab;
  border-radius: 30px;
  color: #006c00;
  text-align: center;
  font-size: 14px;
  display: inline;
  padding: 0 20px;
}
.revoke-btn {
  background: #7e009e;
  border-radius: 30px;
  padding: 5px 20px;
  border: 1px solid #7e009e;
  color: #fff;
}
.inprocess-view-btn {
  background: #fff;
  border-radius: 30px;
  margin-left: 20px;
  border: none;
}

.status-reject {
  background: #ffe8e8;
  border: 1px solid #ffd5d5;
  border-radius: 30px;
  color: #8a0000;
  text-align: center;
  font-size: 14px;
  display: inline;
  padding: 0 20px;
}

.separationInitate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  font-size: 22px;
  color: #9c9e9c;
  /* border: 1px solid gray; */
  font-weight: 600;
}

@media screen and (max-width: 1440px) {
  .empSeprationReason.empSepStatus .empSeprationStatus {
    width: 100%;
  }
  .empSeprationReasonHeader {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 768px) {
  .empSeprationBtn {
    margin-bottom: 20px;
  }
  .empSeprationReason.empSepStatus .empSeprationStatus {
    width: 45%;
  }
  .empSeprationReasonHeader {
    flex-direction: row;
  }
}
@media screen and (max-width: 600px) {
  .empSeprationBtn {
    flex-direction: column;
    row-gap: 15px;
  }
}
