.btn {
  border-radius: 50px;
  padding: 3px 25px;
}

.primaryBtn {
  background: #7e009e;
  color: #fff;
}
.primaryBtn:hover {
  background: #65007e;
  color: #fff;
}

.primaryBtnOutline {
  border: 1px solid #7e009e;
  background: #fff;
  color: #790098;
  text-decoration: none;
}
.primaryBtnOutline:focus {
  box-shadow: none;
}
.primaryBtnOutline:hover {
  border: 1px solid #790098;
  background: #790098;
  color: #fff;
}

.hrCardCont {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  width: calc(33% - 11px);
  position: relative;
}
.hrCardHead {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
}
.hrCardHead .hrCardTitle {
  font-size: 22px;
  height: 30px;
  padding: 2px 20px;
  border-left: 3px solid #0077c6;
  color: #000;
}
.hrCardHead .hrCardTitle svg, .hrCardHead .hrCardTitle i {
  color: #001d6d;
}
.hrCardHead .hrCardHeadRight {
  margin-right: 20px;
}

/* Default Icon */
.defaultIcon {
  font-size: 60px;
  font-weight: normal;
  color: #8f9597;
  display: flex;
  padding: 15px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 50px);
}
.emptyCardLabel {
  font-size: 16px;
  margin-top: 15px;
}

/* Dashboard Content */
.hrTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px 15px 30px;
  padding-right: 20px;
}
.hrTopBar .pageTitle {
  font-size: 20px;
  font-weight: 300;
}
.hrTopBar .dsPageTitle {
  font-size: 28px;
  font-weight: 500;
}
.hrTopBar .dsPageTitle span {
  font-weight: 300;
}
.hrTopBar .hrTopBarRight {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.hrTopBar .hrTopBarRight .hrSearchBar {
  min-width: 300px;
  border: 1px solid #94a4ca;
  border-radius: 30px;
  background: #fff;
}
.hrTopBar .notificationIcon {
  cursor: pointer;
}
.hrTopBar .hrTopBarRight .hrSearchBar fieldset {
  border: none;
}
.hrTopBar .hrTopBarRight .hrSearchBar button {
  padding: 8px 15px;
  font-size: 16px;
}
.hrTopBar .hrTopBarRight .hrSearchBar input {
  max-height: 38px;
  padding: 6px 15px;
}
.hrTopBar .hrTopBarRight .hrDLmode {
  font-size: 24px;
  color: #333;
}
.hrTopBar .hrTopBarRight .hrTopDD {
  font-size: 26px;
  color: #333;
}
/* .hrTopBar .hrTopBarRight .hrNotificationIcon{ } */
.hrTopBar .hrTopBarRight .hrNotificationIcon button {
  font-size: 24px;
  color: #333;
}
.hrTopBar .hrTopBarRight .hrNotificationIcon button.newNotification {
  font-size: 20px;
}

.dsContent {
  display: flex;
  column-gap: 20px;
  flex-direction: column;
  background: #f1f4fb;
}
.dsContentArea {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  position: relative;
  height: calc(100vh - 52px);
  overflow: auto;
}
.dsContent .dsContentLeft {
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  width: calc(100% - 300px);
  padding-bottom: 50px;
  padding-left: 20px;
}
.dsContent .dsContentLeft .selfCard {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  background: #bce5ff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  min-width: 300px;
  max-width: 300px;
}
/* .dsContentLeft .hrCardCont {flex: 1 1 380px;} */
.hrManageLeaves.hrCardCont {
  flex: 1 2 500px;
}
.dsContentLeft .hrCardCont:nth-last-child(2) {
  max-width: 50%;
}
.dsContent .dsContentLeft .selfCard .selfCardTop {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 2px;
  width: 100%;
  padding: 30px 10px 20px;
  background: #fff;
  border-radius: 10px;
}
.selfImage .userProfileImg > div {
  width: 100px;
  height: 100px;
}
/* .dsContent .dsContentLeft .selfCard .selfCardTop .selfImage{ width: 100px; height: 100px; border-radius: 50px; background: #0077C6; color:#fff; padding: 20px; box-sizing: border-box; font-size: 40px; line-height: 60px; font-weight: 500; text-align: center; margin-bottom: 10px;} */
.dsContent .dsContentLeft .selfCard .selfCardTop .selfImage img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}
.dsContent .dsContentLeft .selfCard .selfCardTop .selfName {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}
.dsContent .dsContentLeft .selfCard .selfCardTop .selfDesignation {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.dsContent .dsContentLeft .selfCard .selfCardTop .selfEmail {
  font-size: 14px;
}

.dsContent .dsContentLeft .selfCard .selfCardBottom {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  padding: 15px 20px;
}
/* .dsContent .dsContentLeft .selfCard .selfCardBottom .mgrImage{ width: 40px; height: 40px; border-radius: 50px; background: #016d7b; color:#fff; padding: 5px; box-sizing: border-box; font-size: 20px; line-height: 30px; font-weight: 500; text-align: center;} */
.dsContent .dsContentLeft .selfCard .selfCardTop .mgrImage img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
/* .dsContent .dsContentLeft .selfCard .selfCardBottom .mgrLabelName{} */
.dsContent .dsContentLeft .selfCard .selfCardBottom .mgrLabel {
  font-size: 12px;
}
.dsContent .dsContentLeft .selfCard .selfCardBottom .mgrName {
  font-size: 16px;
  font-weight: 500;
}

.dsContent .dsContentLeft .hrManageLeaves {
  width: calc(45% - 48px);
  min-width: 280px;
}

/* Recent Applied Leaves */
.dsContent .dsContentLeft .hrRecentAppliedLeaves {
  width: 30%;
  min-width: 240px;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody {
  max-height: 300px;
  height: calc(100% - 50px);
  overflow: auto;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveList {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 0;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveDayCount {
  line-height: 24px;
  text-align: center;
  padding: 0 20px;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveDayNumber {
  font-size: 2rem;
  font-weight: 300;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveDayText {
  font-size: 12px;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveType {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  gap: 5px;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveTypeText {
  font-size: 1rem;
  font-weight: 500;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveTypeDate {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #666666;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveStatus {
  max-width: 80px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-wrap: break-word;
  margin-left: auto;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveStatusIcon svg {
  font-size: 22px;
  font-weight: 300;
}
.hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveStatusText {
  font-size: 12px;
}
.statusOpen {
  color: #9f7700;
}
.statusApproved {
  color: #0f673e;
}
.statusRejected {
  color: #b60000;
}
.statusText {
  font-size: 12px;
}

/* Today's Attendance */
.dashboardCardsBody {
  min-height: 300px;
  max-height: 310px;
  height: calc(100% - 50px);
  overflow: auto;
}
.hrEmployeeAttendanceBody {
  padding: 30px 16px;
}
.hrEmployeeAttendanceBody .empMarkAttendance {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0 20px;
}
.hrEmployeeAttendanceBody .empMarkAttendance .empCalculateHour {
  display: flex;
  flex-direction: column;
  line-height: 28px;
}
.hrEmployeeAttendanceBody .empMarkAttendance .empCalculateHourVal {
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  color: #007304;
}
.hrEmployeeAttendanceBody .empMarkAttendance .empCalculateHourText {
  font-size: 15px;
}
.hrEmployeeAttendanceBody .empAddnotes {
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
  width: 100%;
  text-align: left;
  border-radius: 10px;
}
.hrEmployeeAttendanceBody .empAddnotesInput {
  width: 100%;
  font-size: 16px;
  text-align: left;
  padding: 5px 15px;
  border: 1px solid #c9c9c9;
  border-radius: 10px 0 0 10px;
  border-right: none;
}
.hrEmployeeAttendanceBody .empAddnotesInputBtn {
  background: #ffffff;
  border: 1px solid #790098;
  color: #790098;
  border-radius: 0px 10px 10px 0px;
  text-align: center;
  padding: 5px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hrEmployeeAttendanceBody .empAttendanceDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 30px 0;
}
.hrEmployeeAttendanceBody .empAttendanceLateBy {
  color: #886400;
  font-size: 14px;
}
.hrEmployeeAttendanceBody .checkInOutTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hrEmployeeAttendanceBody .checkInOutContent {
  display: flex;
  flex-direction: column;
}
.hrEmployeeAttendanceBody .checkInOutTitle {
  font-size: 14px;
}
.hrEmployeeAttendanceBody .checkInTime {
  color: #008104;
  font-size: 20px;
}
.hrEmployeeAttendanceBody .checkOutTime {
  color: #bd5e00;
  font-size: 20px;
  text-align: right;
}

/* New Joinees */
.dashboardCardsBody .newJoineesList {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.dashboardCardsBody .newJoineeProfile {
  width: 50px;
  height: 50px;
  margin: 0 20px;
}
.dashboardCardsBody .newJoineesName {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.dashboardCardsBody .newJoineesDesignation {
  color: #888888;
}
.dashboardCardsBody .newJoineesDepartment {
  display: flex;
  flex-direction: column;
  max-width: 100px;
  width: 100%;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-left: auto;
  padding-right: 20px;
}
/* All Posts */
.dashboardCardsBody .allPostList {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e7e7e7;
}
.dashboardCardsBody .allPostContent {
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 10px;
  width: 100%;
  cursor: pointer;
}
.dashboardCardsBody .allPostContent:hover .allPostDescription {
  text-decoration: underline;
}
.dashboardCardsBody .allPostIcon {
  padding: 0 15px;
  font-size: 24px;
}
.dashboardCardsBody .allPostDescription {
  margin: 0;
}
.dashboardCardsBody .allPostDate {
  margin: 0;
  padding-right: 15px;
  color: #666666;
  margin-left: auto;
}
.dashboardCardsBody .allPostDate p {
  font-size: 13px;
}
.dashboardCardsBody .allPostIcon span svg {
  font-size: 24px;
  padding: 0 15px;
}

/* Working Remotely */
.dashboardCardsBody .wrUsersList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.dashboardCardsBody .wrUsersProfile {
  width: 33.33% !important;
  flex-direction: column;
  row-gap: 5px;
  padding: 15px;
}
.dashboardCardsBody .workingRemotlyUser {
  width: 80px;
  height: 80px;
}
.dashboardCardsBody .wrUsersName {
  font-size: 14px;
  text-align: center;
}

/* On leave Today */
.dashboardCardsBody .wrUsersList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  height: 100%;
}
.dashboardCardsBody .wrUsersProfile {
  width: 33.33% !important;
  flex-direction: column;
  row-gap: 5px;
  padding: 15px;
}
.dashboardCardsBody .onleaveTodayProfile {
  width: 80px;
  height: 80px;
}
.dashboardCardsBody .wrUsersName {
  font-size: 14px;
  text-align: center;
}

/* bottom tabs */
.dashboardRequestTabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px;
}
.tabRequests .tabRequestContents {
  padding: 15px 20px;
}
.tabRequests .tabRequestContents svg, .tabRequests .tabRequestContents i {
  font-size: 22px;
}

/* Right Section */
.dsContent .dsContentRight {
  display: flex;
  flex-direction: column;
  width: 25%;
  max-width: 440px;
  min-width: 300px;
  background: #e4eaf7;
  padding: 0 20px 50px 20px;
}

/* Events Card */
.hrEventsCardTitle {
  font-size: 18px;
  margin: 30px auto 20px;
  text-align: center;
}
.eProfileCard {
  border-radius: 10px;
  max-width: 85%;
  margin: auto;
}
.eventsCard {
  background: #fff;
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0px 1px 1px #00000029;
}
.eventsCard .eventsEmpName,
.eventsCard .eventsDesignatinTxt {
  text-align: center;
}
.eventsCard .eventsEmpName p {
  line-height: 28px;
}
.eventsDate {
  background: #bce5ff;
  z-index: 1;
}
.eventsDate li p {
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eProfileCard .eventsDate,
.eProfileCard .eventsWishes {
  border-radius: 0 0 10px 10px;
  margin-top: -10px;
}
.eventsDate li,
.eventsWishes li {
  padding: 0 15px;
  padding-top: 10px;
}
.eventsWishes {
  background: #7e009e;
}
.eventsWishes li p {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.eventsWishes li p a {
  margin-left: 15px;
  color: #fff;
  text-align: center;
  font-size: 1rem;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #7e009e;
  font-size: 24px !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: -5px !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: -5px !important;
}
.noEventsCard {
  width: 100%;
  height: 100%;
  max-height: 380px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.danger {
  color: #a6001c;
}
.bg-purple {
  background: #7e009e;
}
.purple {
  color: #7e009e;
}

/* Poll */
.hrPollBody {
  min-height: 300px;
  max-height: 310px;
  height: calc(100% - 50px);
  overflow: auto;
}

/* Polls */
.hrPollsBody {
  max-height: 380px;
  height: calc(100% - 50px);
  overflow: auto;
}

/* Post Modals */
.postModalsContainer
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  min-width: 700px;
  border-radius: 10px;
}
/* .postModalsContainer .postModalsContent {padding: 0;} */
.postModalsContainer .postModalsContent .dialogTitle {
  color: #7e009e;
  font-size: 26px;
  font-weight: 400;
  gap: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 16px 24px;
  background: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 9;
}
.modal-dialog-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 9;
  padding: 16px 24px !important;
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.tabMenu {
  display: none;
}
.mobileMenu,
.topHeaderCenter {
  display: none;
}

/* Dashboard CSS Media Queries */
@media screen and (max-width: 1921px) {
  .dsContentLeft .hrCardCont {
    flex: 1 1 380px;
  }
}
@media screen and (max-width: 1805px) {
  .hrManageLeaves.hrCardCont {
    flex: 1 2 400px;
  }
}
@media screen and (max-width: 1440px) {
  .hrCardHead .hrCardTitle {
    font-size: 18px;
  }
  .defaultIcon {
    font-size: 40px;
    gap: 5px;
  }

  /* SelfCard */
  .mgrLabelName .mgrName span {
    font-size: 16px;
  }

  /* Manage Leaves */
  .dsContent .dsContentLeft .hrManageLeaves {
    width: calc(45% - 43px);
  }
  .hrManageLeaves.hrCardCont {
    flex: 1 2 350px;
  }

  /* Recent Applied Leaves */
  .hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveDayNumber {
    font-size: 1.5rem;
  }
  .hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveTypeText {
    font-size: 14px;
  }
  .hrRecentAppliedLeaves.hrCardCont .hrRecentAppliedBody .rLeaveStatusIcon svg {
    font-size: 18px;
  }

  /* Events */
  .eProfileCard {
    max-width: 100%;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0px !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0px !important;
  }

  /* Today's Attendance */
  .hrEmployeeAttendanceBody .empMarkAttendance .empCalculateHour {
    line-height: 24px;
  }
  .hrEmployeeAttendanceBody .empMarkAttendance .empCalculateHourVal {
    font-size: 22px;
  }
  .hrEmployeeAttendanceBody .empMarkAttendance .empCalculateHourText {
    font-size: 15px;
  }
  .hrEmployeeAttendanceBody .empAddnotesInput {
    font-size: 14px;
  }
  .hrEmployeeAttendanceBody .empAddnotesInputBtn,
  .hrEmployeeAttendanceBody .empAttendanceLateBy {
    font-size: 14px;
  }
  .hrEmployeeAttendanceBody .checkInTime,
  .hrEmployeeAttendanceBody .checkOutTime {
    font-size: 16px;
  }

  /* New Joinees */
  .hrNewJoineesBody .newJoineesList .newJoineesNameText {
    font-size: 14px;
  }
  .hrNewJoineesBody .newJoineesList .newJoineesDesignation {
    font-size: 12px;
  }
  .hrNewJoineesBody .newJoineeProfile {
    width: 40px;
    height: 40px;
    margin: 0 15px;
  }
  .hrNewJoineesBody .newJoineesDepartment {
    max-width: 80px;
  }
  .hrNewJoineesBody .newJoineesDepartment span label {
    font-size: 12px;
  }

  /* All Posts */
  .hrRecentAppliedBody .allPostDescription p {
    font-size: 14px;
  }

  /* Working Remotely */
  .hrWorkingRemotelyBody .workingRemotlyUser {
    width: 65px;
    height: 65px;
  }

  /* On leave Today */
  .hrOnleaveTodayBody .onleaveTodayProfile {
    width: 65px;
    height: 65px;
  }

  /* Post */
  .hrCardHead .selectPost {
    font-size: 14px;
  }
  .dsContentRight .postTextarea {
    font-size: 14px;
  }

  .postModalsContainer
    .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    min-width: 600px;
  }
}
@media screen and (max-width: 1366px) {
  .hrCardCont {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 1280px) {
  .dsContentRight .dsInnerContent {
    height: 100%;
    overflow: auto;
    padding-right: 20px;
  }
  .dsContent .dsContentRight {
    padding-bottom: 20px;
  }
  .hrTopBar .hrTopBarRight .hrSearchBar {
    min-width: auto;
  }
  .dsContent .dsContentLeft .selfCard {
    width: 260px;
  }
  .dsContent .dsContentLeft .hrManageLeaves {
    width: calc(100% - 280px);
  }
  .dsContent .dsContentLeft .hrRecentAppliedLeaves {
    width: calc(50% - 10px);
    max-width: 100%;
  }
}
/* @media screen and (max-width: 1109px){
    .dsContent .dsContentLeft .selfCard .selfCardTop { flex-direction: row; column-gap: 20px;}
} */
@media screen and (max-width: 1280px) {
  .dsContent .dsContentRight.activeTab {
    min-width: 400px;
  }
  .eProfileCard {
    max-width: 85%;
  }
  .hrTopBar .dsPageTitle {
    font-size: 20px;
  }
  .hrTopBar .hrTopBarRight .hrSearchBar input {
    font-size: 12px;
    padding: 5px 15px;
  }

  /* employee Card */
  .dsContent .dsContentLeft .selfCard .selfCardBottom {
    padding: 15px 15px;
  }
  .mgrLabelName .mgrName span {
    font-size: 14px;
  }

  /* Manage Leave Card */
  .hrCardHead .hrCardTitle {
    font-size: 16px;
  }
  .hrCardHead .hrCardHeadRight button {
    font-size: 12px;
  }

  /* event Card */
  .hrEventsCardTitle {
    font-size: 16px;
  }
  .eventsCard .eventsWishesTxt h6,
  .eventsCard .eventsEmpName p {
    font-size: 18px;
  }

  /* Post Modals */
  .postModalsContainer
    .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    min-width: 500px;
  }

  .tabMenu {
    display: block;
  }
  .tabMenu {
    background: #fff;
    border-top: 1px solid #00000029;
    border-bottom: 1px solid #00000029;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tabMenu .tabMenuTxt {
    font-size: 18px;
    color: #555555;
    font-weight: 600;
  }
  .tabMenu .tabMenuBtn {
    width: 60px;
    border-left: 1px solid #00000029;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  .tabMenuBtn.active {
    background: #ac09ac;
    color: #fff;
  }
  .dsContentArea {
    overflow-x: hidden;
    height: calc(100vh - 114px);
  }
  .dsContent .dsContentLeft.overlay {
    overflow: hidden;
  }
  .dsContent .dsContentLeft {
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
  }
  .dsContentLeft.overlay::after {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .dsContent .dsContentRight {
    position: absolute;
    top: 0;
    right: -300px;
    height: 100%;
    transition: 0.3s ease-in-out;
    padding: 0 0px 50px 20px;
  }
  .dsContent .dsContentRight.activeTab {
    z-index: 0;
    right: 0;
    transition: 0.3s ease-in-out;
  }
  .dsContentRight .swiper-wrapper {
    white-space: nowrap;
  }
}
@media screen and (max-width: 768px) {
  .dashboardProfileBox {
    width: 100%;
  }
  .dsContentLeft .hrCardCont:nth-last-child(2) {
    max-width: 100%;
  }
  .dsContentArea {
    height: calc(100vh - 109px);
  }
  .topHeaderCenter {
    display: block;
    width: 20px;
  }
  .mobileMenu {
    display: block;
    height: calc(100% - 137px);
    overflow: auto;
  }
  .mobileMenu svg {
    color: #000000;
    font-size: 20px;
  }
  .dsContent .dsContentLeft .selfCard .selfCardTop {
    flex-direction: row;
    column-gap: 20px;
  }
  .dsContent .dsContentLeft .selfCard {
    width: 100%;
    min-width: 100%;
  }
  .dsContent .dsContentLeft .selfCard .selfCardTop .selfName {
    font-weight: 400;
    text-align: left;
  }
  .dsContent .dsContentLeft .selfCard .selfCardTop .selfDesignation {
    text-align: left;
  }
  .dsContent .dsContentLeft .selfCard .selfCardTop .selfDesignation span {
    font-weight: 600;
  }
  .dsContent .dsContentLeft .selfCard .selfCardTop .selfEmail {
    text-align: left;
  }
  .dashboardCardsBody .newJoineeProfile {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .newJoineesName .newJoineesDesignation {
    font-size: 14px;
    font-weight: 400;
  }
  .newJoineesName label.newJoineesNameText {
    font-size: 14px;
    font-weight: 500;
  }
  .newJoineesDepartment span {
    font-size: 14px;
  }
  .postModalsContainer .postModalsContent .dialogTitle {
    font-size: 22px;
  }
}
@media screen and (max-width: 600px) {
  .postModalsContainer
    .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    min-width: 90%;
  }
  .dsContent .dsContentRight.activeTab {
    width: 100%;
    min-width: 100%;
  }
  .selfImage .userProfileImg > div {
    width: 65px;
    height: 65px;
  }
  .dsContentLeft .hrCardCont:nth-last-child(2) {
    max-width: 100%;
  }
  .newJoineesDepartment span {
    font-size: 12px;
  }
  .tabRequests .tabRequestContents {
    padding: 10px 15px;
    font-size: 14px;
  }
  .tabRequests .tabRequestContents svg {
    font-size: 14px;
  }
  .tabRequests .tabRequestContents svg {
    font-size: 16px;
  }
}
