@import "react-big-calendar/lib/css/react-big-calendar.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

label.label {
  font-size: 16px;
  /* margin-bottom: 10px; */
  text-transform: capitalize;
}

.cancelBtnOutline {
  border: 1px solid #999999;
  background: #fff;
  color: #333333;
}

.cancelBtnOutline:hover {
  background: #f1f1f1;
}

.outlinedSquareButton {
  background: #fff;
  border: 1px solid #cccccc;
  padding: 5px 15px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.primarySquareBtn {
  background: #0086df !important;
  border: 1px solid #0086df;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}

.primarySquareBtn:hover {
  background: #0371bb;
}

/* Calenar topbar */
.hrCalendarMain {
  display: flex;
  flex-direction: column;
  background: #f1f4fb;
}

.hrCalendarTopbar {
  padding: 15px 20px 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  background: #f1f4fb;
}

.hrCalendarHead .pageTitle {
  font-size: 24px;
}

.hrCalendarHead .calendarSearch {
  position: relative;
}

.hrCalendarHead .calendarSearch .searchIcon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.hrCalendarHead .calendarSearch .searchInput {
  width: 480px;
  padding: 10px 30px;
  border-radius: 50px;
  border: 1px solid #94a4ca;
  position: relative;
}

.hrCalendarHead .calendarSearch .searchInput:focus,
.hrCalendarHead .calendarSearch .searchInput:active {
  outline: none;
}

/* .hrCalanderRight .leaveBalance .leaveTabs .active:after { content: ""; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-bottom: 5px solid #790098; display: inline-flex; position: absolute; bottom: 0; left: 50%; } */
.hrCalendarHead .pageTitleSearch {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.hrCalendarHead .notificationIcon {
  position: relative;
  cursor: pointer;
}

.hrCalendarHead .notificationIcon .notificationBadge {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #009b00;
  color: #fff;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarHolidayList .primaryBtnOutline {
  border: 1px solid #7e009e;
  background: #fff;
  color: #790098;
  border-radius: 30px;
}

/* .calendarHeader .calendarHolidayList {
  column-gap: 20px;
} */
.calendarHeader .calendarHolidayList button.btn:focus {
  box-shadow: none;
}

.calendarHolidayList .primaryBtnOutline.active {
  opacity: 0.5;
  /*background: #7E009E; color: #fff;*/
}

.calendarHolidayList .primaryBtnOutline:focus {
  box-shadow: none;
}

/* Calendar Left content */
.hrCalendarContent {
  display: flex;
  gap: 10px;
  height: calc(100vh - 106px);
}

.hrCalanderLeft {
  width: calc(100% - 600px);
  position: relative;
  display: flex;
  flex-direction: column;
}

.hrCalanderRight {
  position: relative;
  max-width: 600px;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: auto;
}

/* React Calendar Css */
.react-calendar {
  position: relative;
}

.react-calendar__navigation {
  position: absolute;
  top: -48px;
  left: 15px;
}

.react-calendar {
  width: 100%;
  border: none;
  padding: 20px;
}

.react-calendar__navigation button.react-calendar__navigation__label {
  font-size: 18px;
}

.react-calendar .react-calendar__navigation button:enabled:hover,
.react-calendar .react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar button.react-calendar__navigation__arrow {
  font-size: 40px;
  font-family: sans-serif;
  height: 35px;
}

.react-calendar button.react-calendar__navigation__arrow {
  font-size: 40px;
  font-family: sans-serif;
  height: 35px;
}

.calendarHeader {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 10px 30px;
  background: #fff;
  border-top: 1px solid #00000029;
  border-bottom: 1px solid #00000029;
}

.calendarHolidayList p svg {
  font-size: 18px;
  margin-right: 5px;
}

.calendarHolidayList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
}

.holidayListMobile {
  pointer-events: none;
}

.react-calendar__month-view__weekdays {
  background: #b7e0ff;
  color: #333333;
  padding: 5px;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
}

.react-calendar button.react-calendar__tile {
  height: 100px;
  width: 150px;
  border: 1px solid #d7d7d7;
}

.calendarBottomStatus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
  background: #fff;
}

.calendarStatus span {
  width: 15px;
  height: 15px;
  border: 3px solid gray;
  border-radius: 50%;
}

.calendarStatus {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 15px 28px 15px 20px;
}

.calendarStatusApproved.calendarStatus span {
  background: #7e009e;
  border-color: #7e009e;
}

.calendarStatusPending.calendarStatus span {
  background: #c67200;
  border-color: #c67200;
}

.calendarStatusRejected.calendarStatus span {
  background: #b50000;
  border-color: #b50000;
}

.calendarStatusHoliday.calendarStatus span {
  background: #009b00;
  border-color: #009b00;
}

.calendarStatusToday.calendarStatus span {
  background: #bce5ff;
  border-color: #bce5ff;
}

.calendarStatus .calendarStatusToday svg {
  color: #b7e0ff;
}

/* Right Section Leave Balance */
.hrCalanderRight .leaveBalance {
  height: 100%;
  padding-left: 20px;
  white-space: nowrap;
  /* padding-bottom: 30px; */
}

.leaveBalance .attBalanceContent {
  height: calc(100% - 64px);
  overflow-y: auto;
}

.hrCalanderRight .leaveBalance .leaveBalanceTitle {
  padding: 20px 0;
}

.hrCalanderRight .leaveBalance .leaveBalanceTitleContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.hrCalanderRight .leaveBalance .earnedLeave {
  border: 1px solid #bedfff;
  border-radius: 35px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 10px);
  background: #d8f0ff;
}

.hrCalanderRight .leaveBalance .leaveCountStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 15px;
  margin-bottom: 30px;
}

.hrCalanderRight .leaveBalance .earnedLeaveContent {
  color: #0c58a3;
  font-size: 14px;
  font-weight: 600;
}

.hrCalanderRight .leaveBalance .earnedLeave .leaveCount {
  font-size: 20px;
  line-height: 20px;
  color: #0c58a3;
}

/* Leave Tabs */
.hrCalanderRight .leaveBalance .leaveTabs .leavebalanceTabs {
  justify-content: space-between;
  padding-right: 20px;
}

.hrCalanderRight .leaveBalance .leaveTabs {
  position: relative;
  border-radius: 0.25rem 0.25rem 0 0;
  padding-right: 20px;
  padding-bottom: 20px;
  height: calc(100% - 64px);
  overflow-y: auto;
}

.hrCalanderRight .leaveBalance .leaveTabs .leaveTabBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hrCalanderRight .leaveBalance .leaveTabs .leaveTabBtn .leaveBtn {
  background-color: transparent;
  padding-bottom: 12px;
  border: none;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  color: #333;
}

.hrCalanderRight .leaveBalance .leaveTabs .leaveTabBtn .leaveBtn.active {
  position: relative;
  color: #790098;
  border-bottom: 1px solid #7e009e;
  padding-bottom: 12px;
}

.hrCalanderRight .leaveBalance .leaveTabs .leaveTabBtn .leaveBtn.active:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #790098;
  display: inline-flex;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.hrCalanderRight .leaveBalance ul.leaveApplied {
  max-height: 241px;
  min-height: 150px;
  height: 100%;
  overflow: auto;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  overflow-x: hidden;
}

.leaveApplied .defaultIcon {
  min-height: 150px;
}

.hrCalanderRight .leaveBalance .leaveApplied .workFromHome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}

.hrCalanderRight .leaveBalance .leaveDays {
  padding-left: 20px;
}

.hrCalanderRight .leaveBalance ul.leaveApplied .leaveList {
  border-bottom: 1px solid #d6d6d6;
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.hrCalanderRight .leaveBalance ul.leaveApplied .leaveList .todaysMealFeedback {
  margin-left: auto;
  padding-right: 20px;
  color: #790098;
  font-weight: 500;
}

.hrCalanderRight
  .leaveBalance
  ul.leaveApplied
  .leaveList
  .todaysMealFeedback:focus {
  box-shadow: none;
}

.hrCalanderRight .leaveBalance ul.leaveApplied .leaveList:last-child {
  border-bottom: none;
}

.hrCalanderRight .leaveBalance .leaveDays .leaveDaysCount {
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
}

.hrCalanderRight .leaveBalance .leaveDays .leaveDayText {
  font-size: 12px;
  text-align: center;
}

.hrCalanderRight .leaveBalance .workFromHome .workFromHomeContent {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.hrCalanderRight
  .leaveBalance
  .workFromHome
  .workFromHomeContent
  .workFromHomeText {
  line-height: 90%;
  font-weight: 600;
  color: #000;
}

.hrCalanderRight
  .leaveBalance
  .workFromHome
  .workFromHomeContent
  .workFromHomeBadge {
  background: #eeeeee;
  padding: 3px 10px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 13px;
}

.hrCalanderRight .leaveBalance .calendarIconWithDate {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 13px;
  margin-top: 5px;
  color: #666;
}

.hrCalanderRight .leaveBalance .leaveStatusContent {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.hrCalanderRight .leaveBalance .leaveStatus {
  margin-left: auto;
  text-align: center;
  color: #0f673e;
  padding-right: 20px;
}

.leaveStatus.dismiss.cPointer {
  cursor: pointer;
}

.hrCalanderRight .leaveBalance .leaveStatus svg {
  font-size: 22px;
}

.hrCalanderRight .leaveBalance .leaveStatus .leaveStatusText {
  font-size: 12px;
  font-weight: 500;
}

.checkbox-align {
  align-items: center;
  display: flex;
  height: 21px;
}

/* leaves applied pending,dismiss status */
.hrCalanderRight .leaveBalance .leaveList.pendingStatus .workFromHomeText {
  color: #9e5b00;
}

.hrCalanderRight .leaveBalance .leaveList.pendingStatus .workFromHomeBadge {
  background: #fff1e0;
  color: #9e5b00;
}

.hrCalanderRight .leaveBalance .leaveList.pendingStatus .leaveStatus.pending {
  color: #9e5b00;
}

.hrCalanderRight .leaveBalance .leaveList.pendingStatus .leaveStatus.dismiss {
  color: #790098;
}

.hrCalanderRight
  .leaveBalance
  .leaveList.pendingStatus
  .leaveStatus.dismiss:hover {
  color: #8a0000;
  background: #f0f1f3;
}

/* leaves applied - Reject status */
.hrCalanderRight
  .leaveBalance
  .leaveList.rejectStatus
  .workFromHome
  .workFromHomeText {
  color: #8a0000;
}

.hrCalanderRight
  .leaveBalance
  .leaveList.rejectStatus
  .workFromHome
  .workFromHomeBadge {
  background: #ffe7e7;
  color: #7a0000;
}

.hrCalanderRight .leaveBalance .leaveList.rejectStatus .leaveStatus {
  color: #8a0000;
}

/* Calendar feedback */
.todaysMeal .mealFeedback {
  background: #f9f6ff;
  border: 1px solid #e299f5;
  display: flex;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* .todaysMeal .mealFeedback .feedbackList { width: 100%;} */
.todaysMeal .mealFeedback .feedbackList li .feedbackCheckbox {
  height: 20px;
  width: 20px;
  accent-color: #000;
  margin: 0 20px 0 20px;
}

.feedbackList {
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.todaysMeal .mealFeedback .feedbackList li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  white-space: nowrap;
}

.todaysMeal .mealFeedback .feedbackList li svg {
  font-size: 40px;
  margin-bottom: 20px;
}

.todaysMeal .mealFeedback .feedbackList li .feedbackText {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.todaysMeal .mealFeedback .feedbackList li .feedbackContent {
  font-size: 20px;
}

.todaysMeal .mealFeedback .feedbackMessage {
  width: 100%;
  background: #fff;
  border-left: 1px solid #e299f5;
  padding: 20px;
  border-radius: 5px;
  margin-left: auto;
  overflow: auto;
}

.todaysMeal .mealFeedback .feedbackMessage textarea {
  max-height: 91px;
  height: 100%;
  overflow: auto;
  margin-top: 20px;
}

.todaysMeal .mealFeedback .feedbackMessage .smiley {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666;
  font-size: 24px;
  cursor: pointer;
}

.todaysMeal .mealFeedback .feedbackMessage .smiley svg.active {
  color: #790098;
}

.todaysMeal .mealFeedback .feedbackBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
  padding-top: 10px;
}

/* Today's Meal box */
.hrCalanderRight .leaveBalance .todaysMeal .todaysMealTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 5px;
}

.todaysMeal {
  margin-top: 35px;
}

.todaysMeal .todaysMealContent {
  width: 100%;
  position: relative;
}

.hrCalanderRight .leaveBalance .todaysMeal .todaysMealTitle .todaysMealDate {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 16px;
}

.hrCalanderRight .leaveBalance .todaysMeal .todaysMealIcon {
  margin-left: auto;
  padding-right: 20px;
  font-size: 24px;
  display: flex;
  column-gap: 30px;
}

.hrCalanderRight .leaveBalance .todaysMeal .todaysMealIcon .thumbsUp {
  color: #009b00;
}

.hrCalanderRight .leaveBalance .todaysMeal .todaysMealIcon .thumbsDown {
  color: #999999;
}

.hrCalanderRight .leaveBalance .mealFeedbackBtn {
  color: #7e009e;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 20px;
  column-gap: 5px;
  font-weight: 500;
}

/* Lunch Request  */
.hrCalanderRight .lunchRequestMain {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}

.lunchInfoDesc {
  margin-left: auto;
  margin-top: -20px;
  position: relative;
  width: 20px;
  height: 10px;
  white-space: initial;
}

span.lunchInfoDesc:hover .lunchInfoDescHover {
  display: block;
}

.lunchInfoDescHover {
  display: none;
  font-size: 12px;
  padding: 2px 10px;
  background: #2b2b2b;
  color: #fff;
  width: 350px;
  max-height: 75px;
  overflow: auto;
  position: absolute;
  top: -24px;
  right: 100%;
  border-radius: 5px;
}

.lunchInfoDesc .lunchInfoDescIcon {
  color: #000;
}

.hrCalanderRight .lunchRequestMain .lunchRequest {
  box-shadow: 0px 1px 1px #00000033;
  border-radius: 5px;
}

.hrCalanderRight .lunchRequestMain .lunchRequest .lunchRequestTitle {
  background: #f1e8ff;
  color: #7e009e;
  margin-bottom: 20px;
  padding: 15px 20px;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoIconLunchHeading {
  cursor: pointer;
}

.lunchRequestInfoIconParentDiv {
  position: relative;
}
.lunchRequestInfoIconHoverText {
  background-color: #817f7f;
  color: aliceblue;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.lunchRequestInfoIconParentDiv .lunchRequestInfoIconHoverText {
  display: none;
}

.lunchRequestInfoIconParentDiv:hover .lunchRequestInfoIconHoverText {
  display: block;
  position: absolute;
  top: 1;
  right: 0;
}

.hrCalanderRight .lunchRequestMain .requestType {
  padding: 0 20px 0;
  /* margin-bottom: 20px; */
}

.requestType .form-select {
  margin-bottom: 20px;
}

.hrCalanderRight .lunchRequestMain .selectDAteFromTo {
  display: flex;
  column-gap: 20px;
  padding: 0 20px;
}

.selectDAteFromTo .selectDate {
  display: flex;
  flex-direction: column;
}

.hrCalanderRight .lunchRequestMain .lunchRequestBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 20px 30px;
  column-gap: 20px;
}

/* Lunch Requet History */
.lunchRequestError {
  color: #f00;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 15px;
}

.hrCalanderRight .lunchReqHistory {
  margin-top: 40px;
}

.hrCalanderRight .lunchReqHistory .lunchHistoryTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lunchHistoryTitle select.lunchHistoryDate {
  border: none;
}

.lunchHistoryTitle select.lunchHistoryDate:focus {
  outline: none;
}

.hrCalanderRight .lunchReqHistory .lunchHistoryTitle .lunchHistoryHeading {
  color: #7e009e;
  font-size: 18px;
  margin-bottom: 10px;
}

.hrCalanderRight .lunchReqHistory .lunchReqHistoryContent {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  max-height: 253px;
  overflow: auto;
}

.hrCalanderRight .lunchReqHistory .lunchReqHistoryContent .lunchRequestList li {
  display: flex;
  align-items: center;
  padding: 20px 0 15px;
  color: #333333;
  border-bottom: 1px solid #d6d6d6;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  li:last-child {
  border: none;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  .listDays {
  padding: 0 20px;
  text-align: center;
  align-self: baseline;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  .listDays
  .listDaysCount {
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  .listDays
  .listDaysText {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  li
  .listDate
  .listDateTitle {
  font-size: 16px;
  font-weight: 500;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  li
  .listAppliedDate
  span {
  font-weight: 500;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  li
  .listDate {
  line-height: 24px;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  li
  .listAppliedDate {
  font-size: 14px;
}

.hrCalanderRight
  .lunchReqHistory
  .lunchReqHistoryContent
  .lunchRequestList
  li
  .lunchHistoryIcons {
  margin-left: auto;
  padding-right: 20px;
  font-size: 20px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
}

.lunchRequestList .lunchCancleBtn {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
}

.lunchRequestList .lunchCancleBtn .lunchCancleTxt {
  font-size: 12px;
  color: #a6001c;
}

.lunchRequestList .lunchCancleBtn .lunchCancleTxt.disabled {
  font-size: 12px;
  color: #000;
}

#dltIconLunchReq {
  cursor: pointer;
}

.subcriptionType {
  margin-left: 30px;
  border: none;
  background: #fff1e0;
  padding: 2px 6px;
  font-size: 13px;
  color: #9e5b00;
  text-decoration: italic;
}

/* Apply leave Type Info Box */
/* .leaveTypeInfo .leaveTypeInfoDetails .leaveTypeInfoBox {
  max-width: 223px;
  position: absolute;
  top: 4px;
  left: 223px;
  border: 1px solid #ccc;
  padding: 7px 15px;
  background: #fff;
  border-radius: 10px;
  white-space: normal;
  font-size: 14px;

} */

.leaveTypeInfo .leaveTypeInfoDetails .leaveTypeInfoBox .infoBoxContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  color: #002d68;
  font-size: 14px;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent
  .infoBoxNotApproved {
  line-height: 20px;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent
  .infoBoxNotApproved
  span {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #000;
  font-style: italic;
}

.leaveTypeInfo .leaveTypeInfoDetails .leaveTypeInfoBox .infoBoxContent:hover {
  background: #f2f9ff;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent:first-child {
  border-radius: 10px 10px 0 0;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent:last-child {
  border-radius: 0 0 10px 10px;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent
  .infoBoxLeaveCount {
  font-weight: 500;
}

.hrCalanderRight .lunchRequestMain .leaveTypeInfoDetails .leaveTypeInfoIcon {
  cursor: pointer;
  color: #999999;
}

.hrCalanderRight .lunchRequestMain .halfDay {
  padding: 20px;
  /* margin-top: 20px; */
}

.hrCalanderRight .lunchRequestMain .attanReguBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px;
  /* margin-top: 10px; */
  gap: 20px;
}

.hrCalanderRight .lunchRequestMain .commentBox {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
}

.hrCalanderRight .lunchRequestMain .leaveTypeInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hrCalanderRight .lunchRequestMain .leaveTypeInfo span.currentLeave {
  font-weight: 500;
}

.hrCalanderRight
  .lunchRequestMain
  .leaveTypeInfoDetails
  .leaveTypeInfo
  .leaveTypeInfoIcon {
  margin-right: 5px;
}

.hrCalanderRight .lunchRequestMain .leaveTypeInfoDetails {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

/* Apply leave Type Info Box */
.leaveTypeInfo .leaveTypeInfoDetails .leaveTypeInfoBox {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 203px;
  border: 1px solid #006fcd;
  border-radius: 10px;
  background: #fff;
  z-index: 9;
  font-size: 14px;
}

.leaveTypeInfo .leaveTypeInfoDetails .leaveTypeInfoBox .infoBoxContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  color: #002d68;
  font-size: 14px;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent
  .infoBoxNotApproved {
  line-height: 20px;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent
  .infoBoxNotApproved
  span {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #000;
  font-style: italic;
}

.leaveTypeInfo .leaveTypeInfoDetails .leaveTypeInfoBox .infoBoxContent:hover {
  background: #f2f9ff;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent:first-child {
  border-radius: 10px 10px 0 0;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent:last-child {
  border-radius: 0 0 10px 10px;
}

.leaveTypeInfo
  .leaveTypeInfoDetails
  .leaveTypeInfoBox
  .infoBoxContent
  .infoBoxLeaveCount {
  font-weight: 500;
}

.hrCalanderRight .lunchRequestMain .leaveTypeInfoDetails .leaveTypeInfoIcon {
  cursor: pointer;
  color: #999999;
}

/* Attendance History Box */
.hrCalanderRight .attendanceHistory {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-right: 0;
}

.hrCalanderRight .attendanceHistory .attHistoryTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 20px;
  position: relative;
}

.attHistoryTitleHeading .leaveTypeInfoIcon {
  cursor: pointer;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus
  .attHistoryStatusText {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus
  .attHistoryStatusText
  .absentInfoIcon {
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus
  .attHistoryStatusText
  .infoIconHoverState
  .leaveTypeInfoBox {
  min-width: 185px;
  position: absolute;
  top: -48px;
  right: 2px;
  border: 1px solid #ccc;
  padding: 5px 10px 10px;
  background: #333;
  color: #fff;
  border-radius: 5px;
  white-space: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 100%, 91% 89%, 0 88%);
  display: none;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus
  .attHistoryStatusText
  .infoIconHoverState {
  position: relative;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus
  .attHistoryStatusText
  .infoIconHoverState:hover
  .leaveTypeInfoBox {
  display: block;
}

.hrCalanderRight .attendanceHistory .attHistoryTitle .attHistoryTitleIcon {
  font-size: 18px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

.hrCalanderRight .attendanceHistory ul.leaveApplied {
  margin-right: 0;
}

.hrCalanderRight .attendanceHistory .attHistoryList .attHistoryListContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 20px 0 0;
  margin-bottom: 15px;
  width: 100%;
}

.hrCalanderRight
  .attendanceHistory
  .attHistoryList
  .attHistoryListContent:last-child {
  margin-bottom: 0;
}

.hrCalanderRight .attendanceHistory .attHistoryList {
  height: calc(100% - 27px);
  overflow-y: auto;
  padding-right: 20px;
  padding-bottom: 20px;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryDays {
  padding: 0 20px;
  text-align: center;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryDays
  .attHistoryDaysCount {
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryDays
  .attHistoryDayText {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

/* .hrCalanderRight .attendanceHistory ul.attHistoryList .attHistoryListContent .workFromHomeContent {
  line-height: 30px;
} */

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .workFromHomeContent
  .workFromHomeText {
  font-weight: 500;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .workFromHomeContent
  .workFromHomeText {
  font-size: 16px;
  font-weight: 500;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .workFromHomeContent
  .calendarIconWithDate
  .checkInTxt {
  font-size: 14px;
  font-weight: 500;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .workFromHomeContent
  .calendarIconWithDate
  .checkInTxt
  span {
  font-weight: 400;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .calendarIconWithDate
  .workFromHomeBadge {
  display: inline-flex;
  font-size: 12px;
  border-radius: 2px;
  background: #eeeeee;
  padding: 2px 15px;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .workFromHomeContent
  .workFromHomeBadge {
  background: #eeeeee;
  padding: 3px 15px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 2px;
  display: inline-block;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .workFromHome {
  display: flex;
  align-items: center;
  column-gap: 50px;
  width: 100%;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus {
  margin-left: auto;
  text-align: center;
  color: #0f673e;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
  /* cursor: pointer; */
  width: 110px;
  white-space: normal;
  text-align: center;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus
  svg,
.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryStatus
  i {
  font-size: 22px;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryListCols {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  width: 100%;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryAddText {
  width: 100%;
  font-size: 13px;
  padding-left: 30px;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryLoggedHours {
  width: 100%;
  font-size: 13px;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .attHistoryLoggedHours
  span {
  font-weight: 500;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent
  .listAddress {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 0 0 5px 5px;
  padding: 5px 0;
  margin-top: 20px;
}

/* LoggedOut Attendance List Css */
.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  li.attHistoryListContent.loggedOut
  .workFromHomeText,
.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent.loggedOut
  .attHistoryStatus,
.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent.loggedOut
  .attHistoryLoggedHours {
  color: #8a0000;
  cursor: pointer;
}

.hrCalanderRight
  .attendanceHistory
  .attHistoryList
  .attHistoryListContent.loggedOut {
  border-color: #8a0000;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent.loggedOut
  .workFromHomeContent
  .workFromHomeBadge {
  background: #ffe7e7;
  color: #8a0000;
}

/* Pending Status List Css */
.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  li.attHistoryListContent.pendingStatus
  .workFromHomeText,
.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent.pendingStatus
  .attHistoryStatus,
.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent.pendingStatus
  .attHistoryLoggedHours {
  color: #9e5b00;
}

.hrCalanderRight
  .attendanceHistory
  .attHistoryList
  .attHistoryListContent.pendingStatus {
  border-color: #9e5b00;
}

.hrCalanderRight
  .attendanceHistory
  ul.attHistoryList
  .attHistoryListContent.pendingStatus
  .workFromHomeContent
  .workFromHomeBadge {
  background: #ffeed6;
  color: #9e5b00;
}

/* Holiday List */
.hrCalanderRight .holidayListMain {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-right: 0;
}

.hrCalanderRight .holidayListMain .holidayListTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-right: 20px;
}

.hrCalanderRight .holidayListMain .holidayListContent {
  height: calc(100% - 27px);
  overflow: auto;
  padding-right: 20px;
}

.hrCalanderRight .holidayListMain .holidayListTitle .holidayListHeading {
  font-size: 20px;
  color: #790098;
}

.hrCalanderRight .holidayListMain .holidayListTitle .holidayListTitleIcon {
  font-size: 18px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

.hrCalanderRight .lunchReqHistory .lunchReqHistoryContent {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  max-height: 253px;
  overflow: auto;
}

.hrCalanderRight .holidayListMain .holidayListContent .holidayList li {
  display: flex;
  align-items: center;
  color: #333333;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  margin-bottom: 24px;
}

/* .hrCalanderRight .holidayListMain .holidayListContent .holidayList li:last-child {border: none; } */
.hrCalanderRight .holidayListMain .holidayListContent .holidayList .listDays {
  width: 115px;
  text-align: center;
}

.hrCalanderRight
  .holidayListMain
  .holidayListContent
  .holidayList
  .listDays
  .listDaysCount {
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
}

.hrCalanderRight
  .holidayListMain
  .holidayListContent
  .holidayList
  .listDays
  .listDaysText {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.hrCalanderRight
  .holidayListMain
  .holidayListContent
  .holidayList
  li
  .listDate {
  line-height: 20px;
  font-size: 20px;
  font-weight: 500;
}

.hrCalanderRight
  .holidayListMain
  .holidayListContent
  .holidayList
  li
  .listAppliedDate {
  font-size: 14px;
}

.hrCalanderRight
  .holidayListMain
  .holidayListContent
  .holidayList
  li
  .lunchHistoryImg {
  margin-left: auto;
}

.hrCalanderRight
  .holidayListMain
  .holidayListContent
  .holidayList
  li
  .lunchHistoryImg
  img {
  width: 100%;
  height: 96px;
}

.hrCalanderRightToggleBtn {
  color: #333;
  font-size: 18px;
  cursor: pointer;
  padding: 15px 20px;
  display: none;
}

.calendarHeaderMobile {
  display: none;
}

.calendarEventsMain {
  display: flex;
  white-space: break-spaces;
}

.calendar-wrapper .rbc-calendar .rbc-event-content .calendarEvents {
  width: 100%;
}

.calendar-wrapper
  .rbc-calendar
  .rbc-row-segment
  .rbc-event-content
  .calendarEventsIcon {
  padding-right: 5px;
}

.holidayBtn {
  background: #f1e8ff;
  color: #790098;
}

.holidayBtn:hover {
  color: #f1e8ff;
  background: #790098;
}

.lunchHistoryImgNoImage {
  display: flex;
  flex-direction: column;
  font-size: 50px;
  color: #cccccc;
  width: 150px;
  height: 100px;
  align-items: center;
  justify-content: center;
  box-shadow: -1px 0px 2px #cdcdcd;
}
.lunchHistoryImgNoImageLabel {
  font-size: 12px;
  margin-top: 5px;
}
@media screen and (max-width: 1440px) {
  .hrCalanderLeft {
    width: calc(100% - 500px);
  }

  .hrCalanderRight {
    max-width: 500px;
  }

  .hrCalanderRight .leaveBalance .leaveList .workFromHomeText {
    font-size: 14px;
  }

  .hrCalanderRight .leaveBalance .leaveTabs .leaveTabBtn .leaveBtn,
  .hrCalanderRight .leaveBalance .todaysMeal .todaysMealTitle {
    font-size: 18px;
  }

  .calendarStatus {
    max-width: 165px;
    padding: 15px 0 15px 20px;
    font-size: 14px;
  }

  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .attHistoryDays {
    padding: 0 15px;
  }

  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .attHistoryStatus {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 1060px) {
  .calendarHolidayList {
    column-gap: 15px;
  }

  .calendarHolidayList .primaryBtnOutline {
    padding: 3px 15px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1366px) {
  .hrCalendarContent {
    height: calc(100vh - 112px);
  }

  .hrCalanderLeft {
    width: 100%;
  }

  .hrCalanderRight {
    position: absolute;
    right: 0;
    z-index: 5;
    width: 0;
    transition: 0.3s ease-in-out;
    height: calc(100% - 112px);
    overflow-y: auto;
  }

  .hrCalanderLeft.overlay:after {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
  }

  .hrCalanderRight.active {
    display: block;
    width: 600px;
    transition: 0.3s ease-in-out;
  }

  .calendarHolidayList {
    padding-bottom: 35px;
  }

  .calendarHeaderMobile {
    display: block;
  }

  .calendarHeaderMobile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #fff;
    border: 1px solid #00000029;
    border-left: none;
    border-right: none;
    height: 60px;
  }

  .calendarHeader {
    display: none;
  }

  .hrCalanderRightToggleBtn {
    display: block;
    z-index: 6;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
  }

  .hrCalanderRightToggleBtn.active {
    background: #7e009e;
    color: #fff;
  }

  .calendarHolidayListMob {
    width: 200px;
    border: 1px solid #999999;
    background: #fff;
    border-radius: 10px;
    padding-bottom: 0;
    row-gap: 0;
    position: absolute;
    top: 65px;
    right: 20px;
    margin-top: -360px;
    z-index: 7;
    transition: 0.3s ease-in-out;
    opacity: 0.5;
  }

  .calendarHolidayListMob.active {
    margin-top: 0;
    transition: 0.3s ease-in-out;
    opacity: 1;
  }

  .calendarHolidayListMob .btn.primaryBtnOutline {
    color: #333;
    width: 100%;
    border-radius: 0;
    background: #fff;
    text-align: left;
    font-size: 16px;
    padding: 15px 0 15px 20px;
    border: none;
    border-bottom: 1px solid #6666664d;
  }

  .calendarHolidayListMob .btn.primaryBtnOutline:focus {
    box-shadow: none;
  }

  .calendarHolidayListMob .btn.primaryBtnOutline:first-child {
    border-radius: 10px 10px 0 0;
  }

  .calendarHolidayListMob .btn.primaryBtnOutline:last-child {
    border-radius: 0 0 10px 10px;
  }

  .calendarHeaderMobile .holidayBtn {
    border-radius: 0;
    padding: 0 20px;
    height: 58px;
    line-height: 60px;
  }

  /* .calendarHeaderMobile .holidayBtn.active { background: #7e009e; color: #fff; } */
  .calendarHeaderMobile .holidayBtn:focus {
    box-shadow: none;
  }

  .navActionBtn {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
  }

  .navActionBtn.active {
    background: #7e009e;
    color: #fff;
  }

  .calendar-toolbar {
    top: -40px;
  }

  .calendar-wrapper .rbc-calendar .rbc-event-content .calendarEvents {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .calendar-wrapper .rbc-calendar .rbc-event-content {
    display: inline-block;
    width: fit-content;
  }

  .calendar-wrapper .rbc-calendar .rbc-row-segment .rbc-event-content span {
    display: none;
  }

  .calendar-wrapper
    .rbc-calendar
    .rbc-row-segment
    .rbc-event-content
    .calendarEventsIcon {
    padding: 5px;
  }
}

@media screen and (max-width: 768px) {
  .hrCalendarHead .pageTitle {
    display: none;
  }

  .hrCalanderRight.active {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .hrCalanderRight
    .holidayListMain
    .holidayListContent
    .holidayList
    li
    .lunchHistoryImg
    img {
    height: 65px;
    max-width: 100px;
    object-fit: cover;
  }
  .calendarHeaderMobile .holidayListMobile span {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .calendarHeaderMobile .holidayBtn .holidayBtnText {
    display: none;
  }

  .navActionBtn {
    padding: 0 10px;
  }

  .calendar-toolbar,
  .cancelBtnOutline,
  .primaryBtnOutline {
    font-size: 14px;
  }

  .hrCalanderRight .leaveBalance .leaveDays .leaveDaysCount,
  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .attHistoryDays
    .attHistoryDaysCount {
    font-size: 20px;
    line-height: 20px;
  }

  .hrCalanderRight .leaveBalance .leaveDays .leaveDayText,
  .hrCalanderRight .leaveBalance .leaveStatus .leaveStatusText,
  .hrCalanderRight .leaveBalance .calendarIconWithDate,
  .hrCalanderRight
    .leaveBalance
    .workFromHome
    .workFromHomeContent
    .workFromHomeBadge,
  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .workFromHomeContent
    .calendarIconWithDate
    .checkInTxt,
  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .attHistoryDays
    .attHistoryDayText,
  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .workFromHomeContent
    .workFromHomeBadge,
  .attHistoryStatus .attHistoryStatusText,
  .hrCalanderRight
    .holidayListMain
    .holidayListContent
    .holidayList
    .listDays
    .listDaysText {
    font-size: 10px;
  }
  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .workFromHomeContent
    .workFromHomeText {
    font-size: 14px;
    font-weight: 500;
  }

  .hrCalanderRight .leaveBalance .leaveStatus svg,
  .hrCalanderRight .leaveBalance .earnedLeave .leaveCount,
  .hrCalanderRight .leaveBalance .leaveTabs .leaveTabBtn .leaveBtn,
  .hrCalanderRight .leaveBalance .todaysMeal .todaysMealTitle,
  .leaveAllocReqMain .leaveAllocReqHead .leaveAllocationReqTitle,
  .hrCalanderRight .leaveBalance .leaveBalanceTitle,
  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .attHistoryStatus
    svg,
  .attHistoryTitle .attHistoryTitleHeading,
  .hrCalanderRight .attendanceHistory .attHistoryTitle .attHistoryTitleIcon,
  .hrCalanderRight .holidayListMain .holidayListTitle .holidayListHeading,
  .hrCalanderRight .holidayListMain .holidayListTitle .holidayListTitleIcon,
  .lunchRequestTitle .lunchRequestHeading {
    font-size: 16px;
  }

  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .workFromHomeContent {
    line-height: 20px;
  }

  .hrCalanderRight .leaveBalance .leaveDays,
  .hrCalanderRight .leaveBalance .workFromHome {
    padding-left: 10px;
  }

  .hrCalanderRight .leaveBalance .leaveStatus {
    padding-right: 10px;
  }

  .hrCalanderRight .leaveBalance .todaysMeal .todaysMealTitle .todaysMealDate,
  .hrCalanderRight
    .attendanceHistory
    ul.attHistoryList
    .attHistoryListContent
    .attHistoryLoggedHours,
  label.label,
  .hrCalanderRight .lunchRequestMain .halfDay,
  .hrCalanderRight .lunchRequestMain .leaveTypeInfoDetails {
    font-size: 12px;
  }

  .hrCalanderRight .leaveBalance .earnedLeave {
    width: calc(100% - 0px);
  }

  .leaveBalanceTitleContent .btn {
    padding: 3px 15px;
    font-size: 14px;
  }

  .hrCalanderRight
    .holidayListMain
    .holidayListContent
    .holidayList
    .listDays
    .listDaysCount {
    font-size: 20px;
    line-height: 20px;
  }

  .hrCalanderRight
    .holidayListMain
    .holidayListContent
    .holidayList
    li
    .listDate {
    line-height: 14px;
    font-size: 14px;
  }

  .hrCalanderRight .holidayListMain .holidayListContent .holidayList .listDays {
    width: 80px;
  }

  /* .hrCalanderRight .lunchRequestMain .lunchRequestBtn {
    padding: 0px 20px 30px;
  } */
}
