/* .leaveAllocReqMain {padding-right: 20px;} */
.leaveAllocReqMain .leaveAllocReqHead { display: flex; align-items: center; justify-content: space-between; margin-top: 20px; cursor: pointer;}
.leaveAllocReqMain .leaveAllocReqHead .leaveAllocationReqTitle { font-size: 20px; font-weight: 500; color: #790098; }
.leaveAllocReqMain .leaveAllocReqHead .leaveAllocationReqTitle span {color: #000;}
.leaveAllocReqMain .leaveAllocReqHead .leaveAllocationReqDropdown {padding: 0 20px;}
.leaveAllocReqMain .leaveAllocReqContent .leaveApplied { margin-top: 10px; }


@media screen and (max-width: 1440px) {
    .leaveAllocReqMain .leaveAllocReqHead .leaveAllocationReqTitle { font-size: 18px;}
  }

  @media screen and (max-width: 1440px) {
  .leaveAllocReqMain .leaveAllocReqHead .leaveAllocationReqTitle {font-size: 16px;}
  }