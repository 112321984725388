.tsTimelineSwiter {
  padding-bottom: 50px;
}

.empTitle {
  font-size: 18px;
  color: #001d6d;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.empTitle.addIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.empTitle.addIcon .titleIcon {
  color: #7e009e;
}

.empTitle svg {
  cursor: pointer;
}

ul.listInfo {
  margin-top: 10px;
  list-style: none;
}

.listInfo li {
  display: flex;
  padding: 10px 0;
  font-size: 14px;
}

.listInfo li .listLabel {
  width: 150px;
  color: #333333;
}

.listInfo li .listContent {
  color: #000;
  width: calc(100% - 150px);
  font-weight: 500;
}
.listInfo li .listContent.resignation {
  color: red;
  font-weight: bold;
}

.listInfo li .leaveApprover {
  color: #000;
  width: calc(100% - 150px);
  font-weight: 500;
  line-height: 30px;
}

ul.newListInfo {
  margin-top: 10px;
  list-style: none;
}

.newListInfo li {
  display: flex;
  padding: 4px 0;
  font-size: 14px;
}

.newListInfo li .listLabel {
  width: 150px;
  color: #333333;
}

.newListInfo li .listContent {
  color: #000;
  width: calc(100% - 150px);
  font-weight: 500;
}

.workShiftToggleBtn span.Mui-disabled {
  background: transparent !important;
}

/* Drag and drop file upload css */
.fileUpload label {
  width: 100%;
}
.fileUpload.pInfoFileUpload {
  min-width: 500px;
  max-width: 600px;
}
.drapAndDrop {
  border: 1px dashed #aaaaaa;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
  cursor: pointer;
}

.drapAndDrop.changePhoto {
  max-width: 50%;
}

.fileUpload label:focus-within {
  outline: none;
}

.drapAndDrop .uploadContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 10px 0;
}

.drapAndDrop .uploadContent .uploadIcon {
  font-size: 30px;
  color: #cccccc;
}

.drapAndDrop .uploadContent .uploadText {
  color: #333333;
}

.drapAndDrop .dragAndDropLine {
  width: 1px;
  background: #cccccc;
  color: #333333;
  position: relative;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.drapAndDrop .dragAndDropLine:after {
  content: "";
  width: 20px;
  height: 25px;
  position: absolute;
  background: #ffffff;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.drapAndDrop .browseContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 10px;
  padding: 10px 0;
}

.drapAndDrop .browseContent .browseBtn {
  padding: 5px 30px;
  border-radius: 35px;
  color: #1678c8;
  background: #fff;
  border: 1px solid #1678c8;
  font-weight: 600;
}

.drapAndDrop .browseContent .browseBtn {
  padding: 5px 30px;
  border-radius: 35px;
  color: #1678c8;
  background: #fff;
  border: 1px solid #1678c8;
  font-weight: 600;
}

.drapAndDrop .browseContent .browseText {
  font-size: 14px;
  color: #666666;
}

.fileUpload .fileStatus {
  font-size: 14px;
  color: #333;
}

@media screen and (max-width: 1680px) {
  .empTitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .fileUpload.pInfoFileUpload {
    min-width: 400px;
    max-width: 500px;
    display: inline-flex;
  }
  .fileUpload label {
    width: 100%;
  }

  .drapAndDrop {
    min-width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .fileUpload.pInfoFileUpload {
    min-width: 100%;
    max-width: 100%;
    display: inline-flex;
  }
  .drapAndDrop {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .drapAndDrop .browseContent {
    display: inline-block;
  }

  .drapAndDrop .dragAndDropLine {
    width: 100%;
    height: 1px;
    padding: 0;
    margin: 15px 0;
  }

  .drapAndDrop .dragAndDropLine:after {
    content: "";
    width: 30px;
    height: 20px;
  }

  .drapAndDrop .browseContent .browseBtn {
    margin-bottom: 5px;
  }
}
