/* Right Drawer css */
.createProjectDrawer {
  width: 675px;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  transition: all 250ms ease-in-out;
  transform: translateX(675px);
}
.createProjectDrawer.addProject {
  width: 500px;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  transition: all 250ms ease-in-out;
  transform: translateX(500px);
}
.createProjectDrawerUpdate {
  width: 675px;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  transition: all 250ms ease-in-out;
}
.createProjectDrawer.active,
.createProjectDrawerUpdate.active,
.createProjectDrawer.addProject.active {
  transform: translateX(0px);
}
/* .createProjectDrawerUpdate.active {
  width: 30%;
}
.createProjectDrawer.addProject.active {
  width: 500px;
} */
.createProjectOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9;
  opacity: 0;
  transition: opacity 250ms, visibility 0s;
  visibility: hidden;
}
.createProjectOverlay.active {
  opacity: 0.5;
  visibility: visible;
}
.createProjectDrawer .createProjectDrawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #00000029;
  background: #0077c6;
  color: #fff;
}
.createProjectDrawer .createProjectDrawerHeader .headerClose {
  cursor: pointer;
}
.createProjectDrawer .formContainer {
  /* height: calc(100vh - 204px); */
  padding: 0 20px;
}
.createProjectDrawer .formContainer .approverPriority1InfoDiv {
  display: none;
  position: absolute;
  top: -10px;
  left: 0;
  transform: translateX(-35px);
}
.createProjectDrawer
  .formContainer
  .approverPriorityInfoIcon:hover
  .approverPriority1InfoDiv {
  display: block;
}

.createProjectDrawer .formContainer .approverPriority1InfoLabel {
  border: 1px solid #7eb6ff;
  color: gray;
  justify-content: center;
  display: block;
  align-items: center;
  padding: 5px 15px;
  position: relative;
  border-radius: 5px;
  top: -25px;
  left: 34px;
  background: #fff;
}
.createProjectDrawer .formContainer .addButtonInitial {
  font-size: 14px;
  font-weight: 600;
  color: #176b95;
  cursor: pointer;
  border: none;
  background: transparent;
}
.createProjectDrawerUpdate .createProjectDrawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #00000029;
  background: #0077c6;
  color: #fff;
}
.createProjectDrawerUpdate .createProjectDrawerHeader .headerClose {
  cursor: pointer;
}
.createProjectDrawerUpdate .formContainer {
  /* height: calc(100vh - 204px); */
  overflow-y: auto;
  padding: 0 20px;
  overflow-x: hidden;
}
.projectListDetailWrapper .projectListDetailsTab ul li.active {
  border-bottom: 1px solid #0077c6;
  color: #0077c6;
}
/* End Of Right Drawer css */
.clientListContainer .clientListTable {
  height: calc(100vh - 278px);
  overflow: auto;
}
.timeSheetPagination {
  position: sticky;
  left: 0;
  bottom: 0;
  background: transparent;
  padding: 10px 0;
  z-index: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
  width: 100%;
}
.clientInformationContainer {
  height: calc(100vh - 87px);
  overflow: auto;
  padding: 20px 15px 0;
  background: #f1f4fb;
}
.projectList .projectListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.projectList .projectListHeader .pListHeaderContent .pListHeaderContentHeader {
  font-size: 22px;
  font-weight: 600;
}
.projectList .projectListHeader .pListHeaderContent .pListHeaderContentDesc {
  font-size: 14px;
}
.addClientForm {
  height: calc(100vh - 175px);
  overflow: auto;
}
.createProjectDrawer .addProjectForm {
  height: calc(100vh - 167px);
  overflow: auto;
}
.projectListTable {
  height: calc(100vh - 350px);
  overflow: auto;
}
.projectListDetailWrapper {
  background: #f1f4fb;
  height: calc(100vh - 87px);
  overflow: auto;
}

.updateFormImg .updateImgDeleteIcon {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ff2b2b;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}
.updateFormImg img.uploadImgIcon {
  width: 85px;
  height: 90px;
  border: 1px solid #00000029;
  padding: 11px;
  border-radius: 5px;
  cursor: pointer;
}
.updateFormImg .updateImgName {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
}
.updateImgName {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  color: #666666;
  text-overflow: ellipsis;
  width: 100px;
  margin-top: 4px;
}
.addProjectForm .customRadioBtnMain ul {
  display: flex;
  align-items: center;
}
.projectContainer .projectTeams .teamsContainer {
  height: calc(100vh - 371px);
  overflow: auto;
}
.projectListDetailWrapper .projectListTaskContainer .teamMemberList {
  height: calc(100vh - 366px);
  overflow: auto;
}
.iconTooltip {
  position: relative;
  padding: 0 5px;
  display: inline-block;
}
.iconTooltip .iconTooltipHover {
  display: none;
  position: absolute;
  top: -30px;
  right: 0;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  padding: 1px 15px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.iconTooltip .iconTooltipHover.comments {
  border: 1px solid #3498db;
  background: #fafdff;
  color: #156da8;
}
.iconTooltip .iconTooltipHover.approve {
  border: 1px solid #07bc0c;
  background: #fbfffb;
  color: #036d07;
}
.iconTooltip .iconTooltipHover.reject {
  border: 1px solid #e74c3c;
  background: #fffcfc;
  color: #b61c0b;
}
.iconTooltip .iconTooltipHover:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  bottom: -8px;
  right: 0px;
  z-index: 3;
}
.iconTooltip .iconTooltipHover.approve:after {
  border-top: 8px solid #008000;
}
.iconTooltip:hover .iconTooltipHover {
  display: block;
}
.projectListTaskContainer .projectListRightSection .TeamTimesheet {
  height: calc(100vh - 530px);
  overflow: auto;
}
.projectListTaskContainer .projectListRightSection .TeamTimesheet table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
