.notificationDate {
  font-size: 12px;
  color: #000;
  font-style: italic;
  text-align: end;
  margin-top: 10px;
  padding: 0px 4px;
}

.notificationDate svg {
  font-size: 12px;
  color: #000;
  font-style: italic;
  margin-right: 5px;
}

.disableNotificationIcon {
  font-size: 7rem;
  color: #d8d8d8;
  text-align: center;
}

.notificationHeading {
  border-bottom: 1px solid #f0f0f0;
  width: 300px;
  background: #fff;
  padding: 15px 10px;
}

.loading {
  width: 100%;
  font-size: 14px;
  text-align: center;
}

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #d7d7d7;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  /* -webkit-box-shadow: inset 0 0 5px #535353; */
}

.defaultIcon {
  font-size: 60px;
  font-weight: normal;
  color: #8f9597;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyCardLabel {
  font-size: large;
  margin-top:25px;
}