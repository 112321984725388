/* Educational Qualification */
.eduQualification {
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
}
.eduQualification .eduQualificationContent {
  width: 100%;
}
.eduQualification .eduQualificationIcon {
  font-size: 30px;
  color: #333333;
}
.eduQualification .eduQualificationContent .eduHeading {
  font-weight: 600;
  color: #000000;
}
.eduQualification .eduQualificationContent .eduSubHeading {
  font-size: 14px;
}
.eduQualification .eduQualificationContent .eduYearLocation {
  font-size: 14px;
  color: #999999;
}

/* Skills Set */
.eduSkillsSet {
  margin-top: 20px;
}
.eduSkillsSet .eduSkillsSetTitle {
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}
.headingAndIcon {
  display: flex;
  justify-content: space-between;
}
.eduSkillsSet .eduSkillsRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.eduSkillsProgress .eduSkillsProgressBar .MuiLinearProgress-determinate {
  height: 5px;
}
.eduSkillsSet .eduSkillsRating .eduSkillsRatingTxt {
  font-size: 12px;
  color: #999999;
}
.eduSkillsSet .eduSkillsRating .eduSkillsRatingDate {
  font-size: 12px;
  color: #333333;
}
.eduSkillsRating .eduSkillsRatingStarts {
  font-size: 12px;
  color: #a400cd;
  display: flex;
  column-gap: 2px;
}
.eduSkillsSet .eduSkillsRatingContent {
  font-size: 14px;
  color: #666666;
}

/* Certifications */
.eduCertifications {
  margin-top: 20px;
}

/* Commitment */
.empCommitment {
  margin-top: 20px;
}
.empCommitment .empCommitmentContent {
  display: flex;
  align-items: center;
  column-gap: 50px;
}
.eduCertifications .eduCertificationsTitle {
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}
.eduCertifications .eduCertificationsContent {
  display: flex;
  align-items: center;
  column-gap: 50px;
}
.empCommitment
  .empCommitmentContent
  .empCommitmentText
  .empCommitmentsText {
  font-size: 12px;
  color: #999999;
  margin-right: 5px;
}


.empCommitment
  .empCommitmentContent
  .empCommitmentText
  .empCommitmentDate {
  font-size: 12px;
  color: #333333;
}
.eduCertifications
  .eduCertificationsContent
  .eduCertifiationText
  .eduCertificationsTxt {
  font-size: 12px;
  color: #999999;
  margin-right: 5px;
}
.eduCertifications
  .eduCertificationsContent
  .eduCertifiationText
  .eduCertificationsDate {
  font-size: 12px;
  color: #333333;
}
.eduCertifiationImg img {
  width: 70px;
}

/* Previous Work Experience */
.previousWorkExperience {
  padding-bottom: 50px;
}
.tsTimelineSlides {
  display: flex !important;
  align-items: center;
  column-gap: 20px;
  width: auto !important;
}
.workExpInitialSlide {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.tsTimelineSlides .tsTimelineBox {
  border: 1px solid #ccc;
  padding-left: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.tsTimelineSlides .tsTimelineBox .firstLetter {
  font-size: 50px;
  text-shadow: 0px 0px 2px #000;
  -webkit-text-fill-color: #fff;
}
/* .tsTimelineSlides .tsTimelineBox svg { font-size: 30px; color: #7E009E; } */
.tsTimelineSlides .tsTimelineBox .tsTimelineBoxContent .tsTimelineBoxHeading {
  font-weight: 600;
  color: #000;
}
.tsTimelineSlides .tsTimelineBox .tsTimelineBoxContent .tsTimelineBoxDate {
  font-size: 14px;
  color: #333333;
}
.tsTimelineSlides .tsTimelineCircle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #333;
  color: #000;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tsTimelineSlides .tsTimelineBox .firstLetter {
  font-size: 50px;
  text-shadow: 0px 0px 2px #000;
  -webkit-text-fill-color: #fff;
  text-transform: uppercase;
}

.iconsTimeline {
  cursor: pointer;
}
#plusIconCssId {
  color: #a400cd;
}
.iconsTimelinedelete {
  color: #da3848;
  cursor: pointer;
}
.uploadCompanyName {
  background-color: #f0f9ff;
  font-size: 14px;
  color: #001460;
  font-weight: 600;
  padding: 5px 0px;
  width: 100%;
  margin-bottom: 20px;
}
.emptyDiv {
  width: 250px;
  height: 200px;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  display: flex;
  color: #c1c1c1;
  flex-direction: column;
}
.emptyDiv .text {
  font-size: 25px;
  font-weight: 600;
}
.crossIconDocUpload{
  float: right;
  font-size: 25px;
  font-weight: 600;
  color: #666666;
  cursor: pointer;
}
.fileIconCss{
  color: #7a91a4;
  margin-right: 5px;
}
.downloadIconCertification{
  margin-right: 20px;
  cursor: pointer;
}
@media screen and (max-width: 1680px) {
  .eduQualification .eduQualificationContent .eduHeading,
  .eduSkillsSet .eduSkillsSetTitle,
  .eduCertifications .eduCertificationsTitle {
    font-size: 14px;
  }
  .eduQualification .eduQualificationIcon {
    font-size: 22px;
  }
  .eduCertifiationImg img {
    width: 50px;
  }
}
