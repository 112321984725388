.handbookWrapper {
  display: flex;
}
.handbookWrapper .handbookLeftAsside {
  width: 400px;
  border-right: 1px solid #00000029;
}
.handbookWrapper .handbookRightAsside {
  width: calc(100% - 400px);
}
.handbookWrapper .handbookLeftAsside ul.handbookPageList {
  height: calc(100vh - 109px);
  overflow: auto;
}
.handbookWrapper .handbookLeftAsside .handbookEditList {
  height: calc(100vh - 109px);
  overflow: auto;
}
.handbookWrapper .handbookRightAsside .handbookSwiperContainer .swiper-slide {
  height: calc(100vh - 175px);
  overflow: auto;
}

.handbookWrapper
  .handbookRightAsside
  .handbookSwiperContainer
  .handbookSlides
  img {
  text-align: center;
  margin: auto;
  width: 80%;
  height: auto;
  object-fit: cover;
}

/* .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
} */

.handbookRightAsside .swiper .swiper-wrapper .swiper-slide img.handbookImg {
  display: block;
  /* width: 100%;
    height: 100%; */
  object-fit: cover;
  width: auto;
  height: 700px;
  margin: auto;
}
.joditEditor
  .jodit-react-container
  .jodit-container
  .jodit-workplace
  .jodit-wysiwyg {
  height: calc(100vh - 409px) !important;
  overflow: auto;
}
.joditEditor
  .jodit-react-container
  .jodit-container
  .jodit-workplace
  .jodit-wysiwyg
  ol,
.joditEditor
  .jodit-react-container
  .jodit-container
  .jodit-workplace
  .jodit-wysiwyg
  ul,
.joditEditor
  .jodit-react-container
  .jodit-container
  .jodit-workplace
  .jodit-wysiwyg
  menu {
  list-style: auto;
  margin: 0 0 24px 24px;
}
.handbookSwiperContainer .swiper .swiper-button-prev {
  top: auto;
  left: auto !important;
  right: 50px;
  bottom: 0;
  justify-content: flex-end;
}
.handbookSwiperContainer .swiper .swiper-button-prev,
.handbookSwiperContainer .swiper .swiper-button-next {
  top: auto;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  padding: 5px 20px;
}
.handbookSwiperContainer .swiper .swiper-button-next {
  left: auto !important;
  right: 0 !important;
}
.handbookSwiperContainer .swiper .swiper-button-prev {
  left: auto !important;
  right: 60px;
}
.handbookSwiperContainer .swiper .swiper-button-prev:hover,
.handbookSwiperContainer .swiper .swiper-button-next:hover {
  background: #f7f7f7;
}
.handbookSwiperContainer .swiper-button-next:after,
.handbookSwiperContainer .swiper-button-prev:after {
  color: #393939;
}
.handbookWrapper .handbookRightAsside .handbookSwiperContainer .handbookSlideContainer {
  width: 650px;
  margin: auto;
  box-shadow: 0 0 5px 3px gray;
}
.handbookWrapper .handbookRightAsside .handbookSwiperContainer .handbookImageSlides {
  height: calc(100vh - 177px);
  overflow: auto;
  background: #ffffff;
}

.handbookRightAsside .handbookSwiperContainer {
  background-color: #ffffff;
  background-image: url(https://www.transparenttextures.com/patterns/elegant-grid.png);
  padding: 30px 0;
}
.handbookWrapper .handbookRightAsside .handbookSwiperContainer .handbookSlideContainer .firstAndLastImg {
  height: calc(100vh - 110px);
  background: #ffffff;
  overflow: auto;
}
.handbookSlideBottom{
  bottom:-1px;
  position: sticky;
  width:100%;
}
