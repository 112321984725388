* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  /* user-select: none; */
  /* overflow: hidden; */
  /* margin-left: calc(100vw-100); */
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

:root {
  --primary: #007bff;
  --primaryhover: #0069d9;
  --blue: #03a9f4;
  --lightblue: #bce5ff;
  --navyblue: #0a3e5f;
  --success: #5cb85c;
  --gray: #fbfbfb;
  --grayhover: #f5f5f5;
  --white: #ffffff;
  --skyblue: #f1f4fb;
  --skybluedark: #e4eaf7;
  --purple: #7e009e;
  --danger: #a6001c;
  --golden: #8d6a00;
}
.cursor-pointer {
  cursor: pointer;
}
.danger {
  color: #a6001c;
}

.bg-purple {
  background: #7e009e;
}

.purple {
  color: #7e009e;
}

.lightblue {
  background: var(--lightblue) !important;
}

.skyblue {
  background: var(--skyblue) !important;
}

.skybluedark {
  background: var(--skybluedark) !important;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

p {
  margin-bottom: 0;
}

#createProductFormImage {
  width: 100%;
  overflow: auto;
}

#createProductFormImage > img {
  width: 5vmax;
  margin: 0 0.5vmax;
}

.bgColor {
  background: var(--primary) !important;
}

.delIconClr {
  color: #757575;
}

.editIconClr {
  color: #1976d2;
}

.emp-profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid rgb(178 192 211) !important;
  min-height: 200px;
}

.emp-profile img {
  width: 125px;
  border-radius: 5px;
  height: 125px;
  border: 5px solid #fff;
  box-shadow: 2px 2px 5px -1px grey;
  background: url(https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png);
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.emp-table {
  /* padding: 5px 15px; */
  overflow-x: auto;
}

.emp-table table {
  background: #fff;
}

.emp-table .edit-table-btn {
  margin-bottom: 15px;
  text-align: center;
}

.emp-table .edit-table-btn button {
  border-color: rgb(178 192 211) !important;
  margin: 0 5px;
}

.edu-add-btn {
  text-align: center;
  margin-top: 30px;
}

.upload-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding: 15px 0 0; */
  width: 100%;
}

.upload-file .upload-reset-btn button {
  padding: 5px 15px;
}

.upload-file input.file {
  width: 100%;
  border: dotted 1px #007bff;
  padding-right: 10px;
  text-overflow: clip;
  border-radius: 5px;
}

.upload-file div#createProductFormFile {
  width: 100%;
  max-width: 400px;
}

.upload-cv.upload-file .upload-reset-btn,
.upload-cv.upload-file #createProductFormFile {
  margin-bottom: 10px;
}

.upload-reset-btn .upload-btn {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.upload-reset-btn .upload-btn:hover {
  background-color: #0069d9;
  color: #fff;
  border: 1px solid #0069d9;
}

.upload-reset-btn .reset-btn {
  border: 1px solid #b2c0d3;
  background: #e6f1ff;
}

.upload-file .file::file-selector-button {
  border-radius: 5px;
  color: #2b2b2b;
  background-color: rgb(230, 241, 255);
  border: 1px solid rgb(178 192 211);
  cursor: pointer;
  transition: all 0.25s ease-in;
  cursor: pointer;
  padding: 10px 15px;
  text-transform: uppercase;
}

.crossButton {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 24px;
  background: transparent;
  color: var(--navyblue);
  border: none;
  z-index: 999;
}

.upload-cv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 15px;
}

.main {
  width: 100%;
  padding: 15px !important;
  margin-right: auto;
  margin-left: auto;
}

.head {
  display: flex;
  justify-content: space-between;
}

#head11 {
  text-decoration: none;
}

.roleModalheading {
  background: #f1f4fb;
  color: #333;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
  padding: 15px;
  text-align: center;
  position: relative;
}
.roleModel .roleModalHeader .roleModalheading h3 {
  font-size: 22px;
}
.roleModel .roleModalSubheading {
  padding: 10px 0;
  background: #dcf2ff;
  font-size: 18px;
  text-align: center;
  color: #000;
}
.roleModel .leaveModalFooter {
  border-top: 1px solid #00000029;
}
.roleModel .rolemodelTable table tr th:first-child,
.roleModel .rolemodelTable table tr td:first-child {
  text-align: left;
  padding-left: 0 !important;
}
.roleModel .rolemodelTable table thead tr {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  text-align: left;
}
.roleModel .rolemodelTable table tr.roleModuleList {
  border-top: 1px solid #00000029;
}
.roleModel .rolemodelTable table tr.roleModuleList td {
  color: #0043a7;
  font-weight: 600;
}
.roleModel .rolemodelTable table tr td {
  padding: 5px 0;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.roleModel .rolemodelTable table tr#tablehead td {
  font-weight: 600;
  color: #000;
}
.model-radio-btn > div label {
  margin-left: 0;
}

.model-radio-btn > div label span.MuiRadio-colorPrimary {
  padding: 0;
  padding-right: 5px;
}

.workmode-model {
  padding: 0 !important;
}

.workmode-input input {
  background: #fff;
  border: 1px solid rgb(178 192 211);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  margin: 5px 0 15px;
}

.workmode-radio-btn label span {
  font-size: 12px;
  padding: 0;
  margin-right: 5px;
}

.workmode-date {
  width: 48%;
  margin-top: 20px;
  margin-bottom: 15px;
}

.workmode-btn .submit-btn {
  background: #4996e3;
  color: #fff;
}

.workmode-btn {
  padding: 15px 15px 30px !important;
}

.workmode-btn .submit-btn:hover {
  background: #59a2eb;
}

.workmode-radio-btn label {
  margin-left: 0;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: none !important;
}

.firstdivWorkMode .checkbox-title {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  margin: 5px 0;
}

h1 {
  color: var(--blue);
  font-weight: 400;
}

hr {
  border: 1px solid #e3e3e3;
  margin: 0;
}

.input {
  color: gray;
}

.breadcrumb {
  padding: 10px 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 30px;
}

.breadcrumb nav ol li {
  color: #4996e3;
}

.breadcrumb nav ol li p {
  color: #757474;
  font-weight: 300;
}

.term-apraisal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  font-size: 24px;
}

.head h1 {
  color: var(--blue);
  font-weight: 400;
}

h1 {
  color: var(--blue);
  font-weight: 400;
  /* font-size:26px; */
}

.filter-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.newbutton {
  align-items: center;
  background: var(--primary) !important;
  border-radius: 5px;
  box-shadow: rgb(255 255 255 / 26%) 0 1px 2px inset;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  padding: 10px 20px !important;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.roleModel .leaveModalFooter .newbutton.roleUpdateBtn {
  padding: 5px 15px !important;
}

.anchorButton {
  align-items: center;
  background-color: var(--primary);
  border-radius: 5px;
  box-shadow: rgb(255 255 255 / 26%) 0 1px 2px inset;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 15px 10px;
  padding: 10px 21px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
  text-decoration: none;
}

.anchorButton:hover {
  color: #fff;
}

.anchorButton:disabled {
  background-color: #cccccc;
  cursor: auto;
}

/*ul.MuiList-padding.MuiMenu-list {
  display: flex;
  flex-direction: column;
  border: 1px solid #b2c0d3;
  max-height: 350px;
  height: 100%;
  width: 100% !important;
  overflow: auto;
} */
.MuiDataGrid-columnHeaderDraggableContainer {
  flex-direction: initial !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell--textRight,
.MuiDataGrid-cell--textRight {
  justify-content: flex-start !important;
}

input[readonly],
textarea[readonly],
.readOnlySelect {
  background-color: rgb(247 247 247);
  cursor: not-allowed;
}

.empInput button .MuiSvgIcon-fontSizeSmall {
  color: white;
  background: #838282;
  border-radius: 50%;
  padding: 2px;
}

.readOnlySelect > div {
  background-color: rgb(235, 235, 235);
  pointer-events: none;
  border-color: rgb(118, 118, 118) rgb(133, 133, 133);
  border-width: 2px;
  border-style: inset;
  border-radius: 0%;
}

textarea[readonly] {
  background-color: #f2f2f2;
  border-color: rgb(178 192 211);
  border-radius: 5px;
  padding: 10px 15px;
}

.readOnlySelect input {
  color: slategray !important;
}

.background {
  height: calc(100vh - 52px);
  overflow: auto;
  background: linear-gradient(
    -45deg,
    aliceblue,
    #adb3f4,
    #ecaaaa,
    rgb(137, 192, 240),
    rgb(168, 243, 125)
  );
  position: absolute;
  background-size: 400% 400%;
  animation: change 7s ease-in-out infinite;
  width: 100%;
}

@keyframes change {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

* {
  padding: 0;
  margin: 0;
}

.heading {
  text-align: left;
  text-transform: capitalize;
  color: rgb(73 150 227);
  margin-top: 30px;
  padding-bottom: 10px;
}

/* .divmain {
  padding: 20px 50px 20px 50px;
} */

.label {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 5px;
  /* margin-left: -60px;
  padding-bottom: 10px; */
}

.labelUnsubscribe {
  text-transform: uppercase;
  margin-left: -60px;
}

.enabled-checkbox {
  padding: 5px 0;
}

.labelUser {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 12px;
}

.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: none;
}

.div1 {
  display: flex;
  /* padding: 5px 15px; */
  /* flex-direction: row; */
}

.div2 {
  display: flex;
  flex-direction: column;
  /* padding: 0 15px; */
}

.div2 .jodit-react-container {
  background: #fff;
  border-radius: 5px;
  border: 1px solid rgb(178 192 211);
  margin: 0 15px;
}

.firstdiv p,
.div2 p {
  font-size: 12px;
  /* margin: 0 15px; */
}

form#formUserSubmit {
  background-color: #f6faff;
  border-radius: 10px;
  box-shadow: 0 0 5px -1px grey;
  margin-bottom: 50px;
}

.basic {
  padding: 15px;
  /* background-color: #e6f1ff;
  margin-bottom: 50px; */
}

/* .roles-btn {
  margin: 0 15px;
} */
.user-permission-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #757575;
  cursor: pointer;
}

/* .MuiDialog-paper {
  border-radius: 10% !important;
  padding: 60px !important;
} */
.Toastify__toast {
  width: fit-content;
}

.firstdiv {
  width: 100%;
  padding: 0 15px;
}

.ReactTags__tags.react-tags-wrapper .ReactTags__tagInput input {
  width: 100%;
  border: 1px solid #b2c0d3;
  border-radius: 5px;
  padding: 10px 15px;
}

span.tag-wrapper.ReactTags__tag {
  background: var(--navyblue);
  padding: 2px 19px 2px 10px;
  border-radius: 25px;
  position: relative;
  font-size: 12px;
  color: var(--white);
  margin-right: 5px;
}

.Employee_flexForm__w51LB #rating {
  width: fit-content;
}

.ratingCount {
  width: 35px !important;
  height: 20px;
  background: #fff8e9;
  color: #000;
  font-weight: 600;
  position: absolute !important;
  top: -25px;
  left: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2c0d3;
  border-radius: 5px;
}

.MuiBox-root.css-1jjwf1f {
  font-size: 12px;
}

span.tag-wrapper.ReactTags__tag button {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: var(--white);
  margin: 0;
}

.firstdiv textarea.remove-height {
  height: initial !important;
  max-height: none;
  padding: 5px 15px;
}

.firstdiv textarea {
  width: 100%;
  border: 1px solid #b2c0d3;
  height: 100% !important;
  max-height: 115px;
  border-radius: 5px;
  margin-top: 5px;
  overflow: auto !important;
  margin-bottom: 15px;
  padding: 10px 15px;
}

textarea {
  border-color: rgb(178 192 211);
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
}

p.note {
  margin-bottom: 15px;
}

p.emergency-cont-detail {
  font-size: 14px;
  margin-bottom: 15px;
}

.basic-submit-btn {
  text-align: center;
  margin: 15px;
}

.basic-submit-btn button {
  color: #fff;
  padding: 5px 15px;
}

.firstdiv .PrivateSwitchBase-root-1 {
  padding: 0;
  font-size: 12px;
}

.firstdiv .PrivateSwitchBase-root-1 .MuiSvgIcon-root {
  font-size: 16px;
}

.firstdiv .MuiCheckbox-colorSecondary {
  margin-left: 5px;
}

.workmode-model {
  width: 100%;
  min-width: 500px;
}

.firstdivdialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 15px;
}

.deductionInput {
  padding: 5px 15px;
  border: 1px solid #b2c0d3;
  border-radius: 5px;
  margin-bottom: 15px;
}

.MuiFormControl-root .MuiTextField-root .Dialog {
  width: 300px !important;
}

.Dialog {
  margin-bottom: 20px;
  justify-content: center;
}

.Dialog1 {
  margin-bottom: 20px;
  justify-content: center;
  width: 300px !important;
}

#image {
  width: 21.5rem;
}

.react-datepicker__input-container > input {
  padding: 6px;
  color: slategray;
  width: 20.5rem;
}

.boxmain {
  color: slategray;
  margin: 0 0 15px !important;
  background: var(--white);
  width: 100%;
}

.firstdiv .boxmain input {
  background: #ffffff;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline,
.css-yk16xz-control {
  border-color: rgb(178 192 211) !important;
}

.user-error {
  font-size: 12px;
  color: #f00;
  margin-top: -10px;
  margin-bottom: 15px;
}

.custom-error {
  font-size: 12px;
  color: #f00;
  margin-bottom: 15px;
}

.MuiOutlinedInput-input {
  padding: 10px 15px !important;
}

#boxmain {
  width: 21.5rem;
  padding: 0px;
}

.selectStyle {
  width: 21.5rem;
}

.google-but-logout {
  width: -webkit-fill-available !important;
}

.makeStyles-selectTableCell-3 {
  color: red;
  /* width: 0px !important; */
  /* font-size: 12px; */
}

.SalaryStructure_firstdiv1__ENpEm {
  width: 50% !important;
}

.box1 {
  display: block;
  width: 100%;
  height: 200px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.firstdivSalary {
  padding: 0px 10px;
  width: 100%;
}

.box2 {
  height: 120px;
  width: 21.5rem;
}

.button {
  width: 20px;
  margin-right: 10px;
  padding: 3px;
}

.button1 {
  padding: 3px;
}

.firstdiv .labeldiv {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.firstdiv .labeldiv .label {
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.firstdiv .labeldiv .label input {
  margin-right: 5px;
}

.photo {
  font-size: 100px;
  border: black solid 1px;
  transform: scale(4);
  width: 100px;
}

.check {
  display: flex;
  flex-direction: row;
}

/* p {
  font-size: 10px;
} */

#weeklyoff {
  display: flex;
  justify-content: space-between;
}

#monthly_subscription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#unsubscribed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#unsubscribed {
  margin-left: 10px;
  margin-bottom: 9px;
}

.salarytype {
  /* text-transform: uppercase; */
  color: slategray;
  width: 80%;
  height: 25px;
  max-height: 25px;
  margin-left: 50px;
}

.forminp1 {
  height: 10px;
  padding: 7px;
  width: auto;
  margin-right: auto;
  font-size: large;
}

.divform1 {
  display: flex;
  flex-direction: column;
  border: solid 1px gray;
  display: flex;
  padding: 30px 100px 30px 50px;
}

.boxmain11 {
  padding: 3px;
  text-transform: uppercase;
  color: slategray;
  width: 80%;
  max-height: 24px;
}

.grid1 {
  display: flex;
  margin-top: 15px;
}

.addnewbut {
  padding: 5px 15px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15px 0;
  border: none;
  background-color: #007bff;
  align-self: end;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.addnewbut:hover {
  background-color: #0069d9;
}

.edit-emp-details {
  border: 1px solid rgb(178 192 211);
  /* margin: 15px; */
  border-radius: 5px;
  padding-bottom: 20px;
}

.edit-emp-details .content {
  display: flex;
  align-items: center;
  background-color: rgb(213 224 235);
  justify-content: space-between;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(178 192 211);
  color: #2b2b2b;
}

.edit-emp-details .content svg {
  cursor: pointer;
  color: #2b2b2b;
}

.edit-emp-details button {
  border-color: #b2c0d3 !important;
  margin-top: 3px;
}

.edu-level-box {
  padding: 0 15px 15px;
}

.formbut1 {
  text-align: center;
  padding: 5px 10px 5px 10px;
  font-size: medium;
  max-height: 30px;
  margin-top: 20px;
  max-width: 60px;
  align-self: flex-end;
}

.content {
  display: flex;
  background-color: rgb(253, 253, 223);
  justify-content: space-between;
}

#monthly_subscription {
  margin-left: 10px;
  margin-bottom: 9px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logout1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* float: left; */
  width: 220px;
  padding: 10px 0px 10px 0px;
  background: linear-gradient(60deg, #1f73a0, #53cbda);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(0 172 193 / 40%);
  color: white;
  font-size: medium;
  position: absolute;
  z-index: 1;
  top: 2.5rem;
}

.logout {
  display: block;
}

.mainfilterDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-transform: capitalize;
  margin: 15px 0 0;
}

.filterDiv {
  background-color: rgb(250 254 255);
  padding: 10px 35px 10px 10px;
  color: #2b2b2b;
  font-weight: 400;
  margin-right: 15px;
  margin-bottom: 15px;
  border: solid 1px #cfcfcf;
  position: relative;
  font-size: 14px;
  /* min-width: 215px; */
  border-radius: 5px;
}

/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 30px 15px;
  max-width: none !important;
} */
/* table,
th,
table,
th,
td {
  border: 1px solid rgb(178 192 211) !important;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 5px 15px !important;
} */

table,
thead,
th {
  padding: 5px 15px !important;
}
.roleModel .rolemodelTable table thead tr th {
  padding: 10px 0 !important;
}

table,
tbody,
th,
span.PrivateSwitchBase-root-1 {
  padding: 0;
}

span.filterDiv .MuiSvgIcon-root {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: var(--navyblue);
  cursor: pointer;
}

.test {
  margin-left: -80px;
  margin-bottom: -10px;
}

.test:hover,
.test1:hover {
  color: yellow;
}

.Employee_linkcss__1pw9C {
  color: #256d85;
}

.MuiDataGrid-columnHeader {
  /* background-color: #256d85; */
  /* padding: 1vmax !important; */
  border-right: 1px solid #a7cede;
}

.MuiDataGrid-columnHeaders {
  background: var(--blue);
}

.productListContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#productListHeading {
  font: 400 2rem "Roboto";
  /* padding: 0.5px; */
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.637);
  transition: all 0.5s;
  margin: 2rem;
  text-align: center;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  /* padding: 7px 10px !important; */
  background-color: white !important;
}

.emp-table table tbody tr:first-child th {
  color: #2b2b2b;
  font-weight: 600;
}

/* .MuiTableCell-root {
  padding: 0px 10px !important;
} */

.MuiTableCell-head {
  font-weight: bolder !important;
  background: #def0ff !important;
  font-size: medium !important;
}

.productListTable {
  background-color: white;
  border: none !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 5px 10px 0 rgba(0, 0, 0, 0.19);
}

/* .productListTable div {
  color: rgba(0, 0, 0, 0.678);
  border: none !important;
} */
.productListTable .MuiDataGrid-row:nth-of-type(odd) {
  background: var(--gray);
}

.productListTable a,
.productListTable button {
  color: var(--primary);
  transition: all 0.5s;
  font-size: 14px;
  text-transform: capitalize;
}

.productListTable a:hover {
  color: var(--primaryhover);
}

.productListTable button:hover {
  color: var(--primaryhover);
  background: transparent;
}

.MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer,
.MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
  visibility: visible !important;
  width: auto !important;
}

.MuiDataGrid-columnHeader
  .MuiDataGrid-iconButtonContainer
  button
  .MuiDataGrid-sortIcon,
.MuiDataGrid-columnHeader .MuiDataGrid-menuIcon button svg {
  color: var(--white);
}

.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
  .MuiDataGrid-sortIcon {
  opacity: 1 !important;
}

.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.MuiDataGrid-columnHeader div {
  color: rgb(255, 255, 255);
  justify-content: left;
}

/* .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  justify-content: center !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell--textRight {
  justify-content: center !important;
} */

.Employee_linkcss__1pw9C {
  color: #256d85;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 20px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */

.bm-item .menu-div {
  display: flex !important;
  /* display: inline-block; */
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item1 .MuiSvgIcon-root {
  fill: white;
  width: 2em;
  height: 1.5em;
}

/* Individual item */
.bm-item {
  /* display: inline-block; */

  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}

.main1 {
  display: flex;
  justify-content: space-between;
  border: solid 1px #cfcfcf;
  align-items: center;
  width: 100%;
  padding: 30px 15px;
  background-color: rgb(250 254 255);
  margin: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.main1 .select {
  width: 100%;
  margin-right: 10px;
  z-index: 9;
}

/* .select{
    width: 200px;
    height: auto;
    padding: 10px 0px 10px 0px;
    margin-right: 10px;
} */
.inp {
  padding: 10px 15px;
  border-radius: 5px;
  width: 30%;
  border: 1px solid #cccccc;
}

.inp:focus {
  box-shadow: 2px 2px 10px;
}

.sel {
  height: 35px;
  width: 15%;
  border-radius: 4px;
  text-align: center;
  border: solid 1px gray;
  font-size: medium;
}

.select:focus {
  box-shadow: 2px 2px 10px;
}

.newbutton1 {
  background-color: #3594e3;
  border-radius: 5px;
  box-shadow: rgb(255 255 255 / 26%) 0 1px 2px inset;
  color: #fff;
  cursor: pointer;
  padding: 10px 30px;
  font-weight: bold;
  outline: none;
  border: 1px solid #207ac4;
}

#newDisable {
  pointer-events: none;
  opacity: 0.4;
}

#weekly-off-error {
  margin-top: 10px;
}

.fmaindiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.footer {
  margin-top: 100px;
  margin-bottom: 50px;
}

.css-jm3i9k {
  display: -webkit-inline-box !important;
}

.f1 {
  display: flex;
  color: gray;
  margin-top: 50px;
}

.tickbut {
  padding: 2px 10px 2px 10px;
  font-size: larger;
  font-weight: bolder;
  margin-left: 20px;
  height: fit-content;
}

.fdiv1 {
  display: flex;
  flex-direction: row;
}

.finp {
  border: none;
  margin-top: 15px;
}

.fnewbut {
  margin-top: 15px;
  padding: 2px 5px 0px 5px;
}

.radioWrapper {
  margin-top: 10px;
}

.radio {
  text-transform: uppercase;
  font-size: 12px;
  display: inline-flex;
}

.radio span {
  padding: 0px 10px;
}

/* Policy Css -------------------- */
/* ******************************** */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: rgb(86, 86, 86) !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: rgb(86, 86, 86) !important;
}

/* .MuiPaper-elevation1 {
  box-shadow: none !important;
  max-height: 350px !important;
  border: 1px solid #b2c0d3;
} */
.MuiPaper-elevation1 .MuiList-padding.MuiMenu-list {
  display: flex;
  flex-direction: column;
}

.MuiPaper-elevation1 .MuiList-padding.MuiMenu-list li {
  text-align: left;
  display: flex;
  justify-content: left;
  line-height: 35px;
  padding: 5px 15px;
}

.emp-lwp-table .MuiPaper-elevation1 {
  border: none;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  /* width: 185px !important; */
  min-width: none !important;
  /* text-align: center !important; */
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  width: fit-content !important;
}

/* ********************************* */
/* WorkMode Modal Css */
/* ********************************* */
.workmodeModel {
  padding: 15px;
}

.firstdivWorkMode {
  align-self: center;
  text-align: center;
}

.MuiFormControl-root {
  width: -webkit-fill-available !important;
}

/* .css-2b097c-container {
  width: 100%;
  margin-right: 10px;
} */
.main1 .filter-input,
.main1 .inp {
  width: 100%;
  margin-right: 10px;
  background: #fff;
}

.main1 .filter-input input {
  padding: 8px 15px !important;
}

.tablediv {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  padding: 0px 5px 0px 5px;
  margin-block-end: auto;
}

.newbutton:hover {
  background: var(--primaryhover) !important;
  transform: scale(1.01);
  cursor: pointer;
}

.requiredAsterisk {
  color: #f00;
  font-weight: bold;
  font-size: 0.8rem;
}

.earningModelWraper {
  padding: 0;
  padding-top: 0;
  width: 500px;
}

.earningModelBtn {
  padding: 15px !important;
}
.es-modal.empSeprationModal .earningModelBtn.empSeptationActionBtn {
  position: sticky;
  bottom: 0;
  background: #fff;
}

.empInput input {
  padding: 0 !important;
}

.empInput {
  padding: 10px 15px;
  color: slategray;
  width: 100%;
  border: 1px solid #b2c0d3;
  border-radius: 5px;
  margin: 5px 0 15px;
}

.empInput {
  margin: 5px 0 15px !important;
}

.empInput input {
  padding: 3px 0 !important;
}

.perviousUploadFile {
  display: flex;
  padding: 10px 30px;
  width: 100%;
  font-size: 16px;
  align-items: center;
}

.perviousUploadFile a {
  padding: 10px 20px;
  background: #c9c9c966;
  border-radius: 5px;
  color: rgb(99, 99, 99);
  text-decoration: none;
  margin-left: 20px;
  font-weight: 600;
}

.perviousUploadFile a:hover {
  background: #4996e3;
  color: #fff;
  font-weight: 600;
}

.AutoFillLabel {
  padding: 5px 0 15px;
  font-size: 14px;
}

label.label.showlabelbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

label.label.showlabelbtn .showhistorybtn {
  margin: 0;
  font-size: 12px;
  padding: 0;
  color: var(--primary);
  /* border: 1px solid #b2c0d3; */
  /* background: #f6fafe; */
}

label.label.showlabelbtn .showhistorybtn:hover {
  background: transparent;
  text-decoration: underline;
}

.model-header {
  width: 100%;
  padding: 0 !important;
  min-width: 500px;
}

.model-table-content table {
  width: 100%;
}

.model-table-content {
  width: 100%;
  padding: 15px;
}

.MuiDialog-paper.MuiDialog-paperScrollPaper {
  padding: 0;
}

.leaveform {
  background-color: #f6faff;
  border-radius: 10px;
  box-shadow: 0 0 5px -1px grey;
  margin-bottom: 50px;
  padding: 50px 30px;
}

.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  padding: 0;
}

.datepickerD > div::before {
  border-bottom: none !important;
}

.datepickerD > div {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.helpbox {
  position: relative;
  background: #f6fafe;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #b2c0d3;
  color: var(--navyblue);
  line-height: 30px;
  font-size: 14px;
  margin-top: 15px;
}

.helpbox:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #b2c0d3;
  position: absolute;
  top: -10px;
  left: 275px;
}

.hrTabLeftAsside {
  width: 50px;
  background: #01a9f4;
  height: 200px;
  line-height: 200px;
  display: flex;
  align-items: center;
  border-radius: 5px 0 0 5px;
  transition: 0.5s;
  text-align: center;
  justify-content: center;
  padding: 15px;
  /* box-shadow: 3px 0px 1px 0px #dbdbdb; */
}

.SetUp_setupmain__omfSe a:hover .hrTabLeftAsside {
  background: var(--navyblue);
  transition: 0.5s;
}

.hrTabIcons {
  color: var(--white);
  font-size: 20px;
}

.setupmain a:hover .hrTabIcons {
  background: var(--navyblue);
}

.MuiInput-underline:before,
.MuiInput-underline:focus {
  content: "";
  border-bottom: none !important;
}

.roleModel .rolemodelTable {
  padding: 15px;
  padding-top: 0;
  height: calc(100vh - 234px);
  overflow: auto;
}

.modelTitle {
  background: var(--blue);
  position: relative;
  color: #fff;
}

.selectBox {
  border: none;
}

.selectBox:focus {
  border: none;
  outline: none;
}

.shiftTypesSelectBox input {
  padding: 0 !important;
  background: transparent !important;
}

.variable-selectBox {
  padding: 20px 15px !important;
  color: #2b2b2b !important;
  border-bottom: 1px solid #dbdbdb !important;
  font-size: 14px !important;
}

.variable-selectBox:last-child {
  border-bottom: none !important;
}

.buttonAdd {
  padding: 2px 15px;
  width: fit-content;
  margin: 5px 0;
  border: none;
  background-color: #007bff !important;
  align-self: end;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.flexGrow {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.buttonAdd:hover {
  background-color: #0069d9;
}

.buttonAdd:disabled {
  background-color: #cccccc;
  cursor: auto;
}

/* .boxmain fieldset {
  top: 0 !important;
} */
select:disabled,
textarea:disabled,
input:disabled,
.css-1fhf3k1-control,
.Mui-disabled {
  background: #f7f7f7 !important;
  color: #999;
}
select:disabled:hover,
textarea:disabled:hover,
input:disabled:hover,
.css-1fhf3k1-control:hover,
.Mui-disabled:hover {
  cursor: not-allowed;
}
textarea:disabled::placeholder {
  color: #00000061 !important;
}

.labeldiv {
  display: flex;
  align-items: center;
}

.labeldiv label {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.labeldiv input {
  margin-right: 5px;
}

.user-roles-model {
  position: relative;
  max-width: 600px;
  width: 600px;
  padding: 50px 15px !important;
}

.newSalerySlipWraper {
  width: 100%;
  border: 1px solid #b2c0d3;
  display: flex;
  padding: 30px 15px;
  background: #f6fafe;
  border-radius: 5px;
  margin-bottom: 50px;
  justify-content: space-between;
}

.holidayDesc input {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.holiday-table thead {
  position: sticky;
  top: 0;
  /* z-index: 999; */
}

.holiday-table tbody tr:last-child {
  position: sticky;
  bottom: 0;
  z-index: 99;
  background: #fff;
}

/* ********************************* */
/* ********************************* */
/* Term Appraisal Css */
/* ********************************* */
.css-d0uhtl {
  margin-left: 0 !important;
}

.loader {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    -45deg,
    aliceblue,
    #adb3f4,
    #ecaaaa,
    rgb(137, 192, 240),
    rgb(168, 243, 125)
  );
  animation: change 7s ease-in-out infinite;
}

/* Oops! page css */
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  background-image: url(https://www.transparenttextures.com/patterns/egg-shell.png);
}

.error-wrapper .error-content h1 {
  font-weight: 900;
  font-size: 250px;
  background: linear-gradient(to left, #0a3e5f, #01a9f4);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.error-wrapper .error-content {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 50px 0;
}

.error-wrapper .error-content h2 {
  font-size: 40px;
  font-weight: 600;
  color: #616060;
}

.error-wrapper .error-inner-content p {
  font-size: 18px;
  color: #484848;
}

.error-wrapper .error-inner-content .newbutton.home-button {
  display: inline-block;
  text-decoration: none;
  color: #fff;
}

.compare-tax-plusicon {
  width: 12px;
  height: 12px;
}

.compare-tex-details-tab {
  border: 1px solid #b2c0d3;
  color: #666;
  font-weight: 400;
  cursor: pointer;
}

.compare-tex-details-tab.active {
  /* background: #f8f8f8; */
  border: 1px solid #0d6efd;
  color: #0d6efd;
  outline: none;
  box-shadow: none;
}

/* Tooltip */
.tooltips {
  position: relative;
  cursor: pointer;
}

.tooltips .poi-tooltips {
  position: absolute;
  bottom: 30px;
  left: 0;
  max-width: 200px;
  width: 200px;
  background: #3a3a3a;
  padding: 5px 15px;
  min-height: 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  overflow: hidden;
  opacity: 0;
}

.tooltips:hover .poi-tooltips {
  overflow: visible;
  opacity: 1;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #3a3a3a;
  position: absolute;
  bottom: -10px;
}

/* Dashboard home page css */
/* Employee Profile Card css */
.profileCard {
  background: var(--lightblue) !important;
  height: 100%;
}

.profileCard .pEmpName {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  padding: 0 5px;
}
.profileCard .profileEmpName {
  text-align: center;
  padding: 0 10px;
}

.profileCard .pEmpDesignation {
  text-align: center;
  padding: 0 5px;
}
.profileCard .pEmpDesignation span {
  font-size: 18px;
  font-weight: 500;
}

.profileCard .pEmpId {
  font-weight: 300;
}

.profileCard .pReportCard {
  background: var(--lightblue);
  border-radius: 0 0 10px 10px;
  margin-top: -10px;
  padding: 0 5px;
  padding-top: 10px;
}
.profileCard .pReportCard li:first-child {
  padding: 0;
}

/* Employee Profile Card css */
.manageLeavesCard {
  border-radius: 10px !important;
}

/* Manage Leaves Card css */

/* .eCardMain {
  border-radius: 10px !important;
  box-shadow: none !important;
  background: transparent !important;
}

.eProfileCard {
  background: transparent;
  border-radius: 10px !important;
  max-width: 100%;
  margin: auto;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 24px !important;
  color: #7E009E;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 5px !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 5px !important;
}

.eventsCard {
  background: #fff;
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0px 1px 1px #00000029;
  padding: 30px 0 20px !important;
}

.eventsDate,
.eventsWishes {
  border-radius: 0 0 10px 10px;
  margin-top: -10px !important;
  /* padding: 0 !important; */
/* } */

.eventsDate {
  background: var(--lightblue);
  z-index: 1;
}

.eventsWishes {
  background: var(--purple);
}

.eventsDate li,
.eventsWishes li {
  padding: 0 15px;
  padding-top: 10px;
}

.eventsDate li p {
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventsDate li p svg {
  margin-right: 10px;
}

.eventsWishes li p a {
  margin-left: 15px;
  color: #fff;
  text-align: center;
  font-size: 1rem;
}

.eventsWishes li p {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.cardHeading {
  position: relative;
  font-weight: 400;
  margin-top: 10px;
  color: #000;
  padding: 15px 0;
  /* margin-bottom: 0; */
}

.cardHeading:before {
  content: "";
  width: 2px;
  height: 35px;
  background: #0077c6;
  position: absolute;
  top: 10px;
  left: 0px;
}

.cardContent {
  height: calc(100% - 50px);
  overflow: auto;
  padding: 16px;
}

.dashboardCards {
  background: #fff;
  box-shadow: 0px 1px 0px #0000001a;
  min-height: 380px;
  max-height: 415px;
  height: 100%;
  overflow: hidden !important;
  border-radius: 10px !important;
}

/* MUI Datepicker alignment css */
.MuiDayPicker-monthContainer .MuiPickersDay-dayWithMargin {
  margin: 2px;
}

/* MUI Datepicker alignment css */
.polDialouge .MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  min-width: 700px;
  width: 100%;
}

.MuiPaper-elevation.MuiPaper-rounded .MuiList-padding.MuiDataGrid-menuList {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
}

.MuiPaper-elevation.MuiPaper-rounded .MuiList-padding.MuiDataGrid-menuList li {
  justify-content: flex-start;
}

/* .css-l3ln04-MuiAutocomplete-root .MuiAutocomplete-tag {
  margin: 3px;
  max-width: calc(100% - 6px);
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 30px;
} */

/* .css-l3ln04-MuiAutocomplete-root .MuiAutocomplete-tag {
  margin: 3px;
  max-width: calc(100% - 6px);
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 30px;
} */
ul.MuiList-padding.MuiMenu-list li {
  justify-content: flex-start;
  padding: 5px 15px;
}

ul.MuiList-padding.MuiMenu-list {
  display: flex;
  flex-direction: column;
}

.badgeDiv {
  display: flex;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.badgeDiv.active {
  border: 1px solid green;
}
.MuiClockPicker-root .MuiClock-root .MuiClock-amButton,
.MuiClockPicker-root .MuiClock-root .MuiClock-pmButton {
  position: absolute;
}

/* Verify OTP css */
.loginOtpContent {
  padding: 30px 0 0;
  width: 100%;
  text-align: center;
}
.loginOtpContent .loginHeading {
  font-size: 24px;
  font-weight: 600;
}
.loginOtpContent .loginSubheding {
  padding: 20px 0;
}
.loginOtpContent .loginOtpEnterPass {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  justify-content: center;
}
.loginOtpContent .loginOtpEnterPass input {
  width: 50px;
  height: 50px;
  border: 1px solid #00000029;
  background: #fff;
  border-radius: 5px;
  padding: 0 !important;
  text-align: center;
}
.loginOtpContent .otpSuccessTxt {
  color: #009b00;
  padding: 20px 0;
}
.loginOtpContent .otpErrorTxt {
  color: #da3848;
  padding: 20px 0;
}
.employee_form_button_div .optLoginBtn {
  background: #0077c6;
  border: none;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  border-radius: 35px;
  color: #fff;
  margin: auto;
  margin-bottom: 20px;
}
.employee_form_button_div .optLoginBtnDisabled {
  background: #cbcaca;
  border: none;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  border-radius: 35px;
  color: #fff;
  margin: auto;
  margin-bottom: 20px;
}
.add_employee_main_heading .otpModalClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;
  font-size: 24px;
}
.otpResendTxt .disabledRegernate {
  color: #cbcaca;
}
.otpResendTxt .enabedRegernate {
  color: #1678c8;
  text-decoration: underline;
  cursor: pointer;
}

/* TimePicker edit date Css */
.MuiDialog-container.MuiDialog-scrollPaper
  .MuiDialog-paperScrollPaper
  .MuiTypography-overline,
.MuiDialog-container.MuiDialog-scrollPaper
  button.MuiPickersToolbar-penIconButton {
  display: none;
}
/* End of TimePicker edit date Css */
.es-modal.empSeprationModal {
  max-width: 70%;
  margin: auto;
}
.es-modal.empSeprationModal .empSeprationList.empSeprationHead {
  background: #f0f9ff;
  font-weight: 600;
}
.es-modal.empSeprationModal .empSeprationList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.es-modal.empSeprationModal .empSeprationList .empApproval {
  display: flex;
  align-items: center;
  column-gap: 50px;
}
.es-modal.empSeprationModal .empSeprationList .empSeprationApproval {
  display: flex;
  align-items: center;
  column-gap: 30px;
  font-size: 14px;
}
.es-modal.empSeprationModal
  .empSeprationList
  .empSeprationApproval
  .empAppStatus {
  min-width: 150px;
  text-align: right;
}
.es-modal.empSeprationModal
  .empSeprationList
  .empSeprationApproval
  .empAppStatus:last-child {
  min-width: 200px;
  text-align: center;
}
.es-modal.empSeprationModal .empSeprationActionBtn {
  min-width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  column-gap: 50px;
  font-size: 13px;
}
.es-modal.empSeprationModal .empSeprationActionBtn .fromToDate {
  width: 175px;
}
.es-modal.empSeprationModal .empSeprationActionBtn .fromToDate input {
  font-size: 13px;
}
.es-modal.empSeprationModal .empSeprationActionBtn p {
  font-size: 13px;
}
.es-modal.empSeprationModal .empSeprationHeader {
  background: #f0f9ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.es-modal.empSeprationModal .empSeprationHeader .empSeprationHOD {
  display: flex;
  align-items: center;
  column-gap: 25px;
}
.es-modal.empSeprationModal
  .empSeprationHeader
  .empSeprationHOD
  .empSeprationAction {
  width: 150px;
}
.form-switch .form-check-input {cursor: pointer;}
.form-switch .form-check-input:checked {
  background-size: 15px;
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  border-color: #6B7280;
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
/* .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  width: 750px;
} */

.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 6px;
}
/* ********************************* */
@media only screen and (max-width: 1920px) {
  .main {
    max-width: 1740px;
  }
}

@media only screen and (max-width: 1440px) {
  .main {
    max-width: 1340px;
  }
}

@media only screen and (max-width: 1366px) {
  /* Resignation modal css */
  .es-modal.empSeprationModal .empSeprationList {
    align-items: baseline;
    flex-direction: column;
    padding: 15px 0;
  }
  .es-content {
    text-align: left;
    padding: 10px 15px;
    width: 100%;
    background: #f5f5f5;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .es-modal.empSeprationModal .empSeprationList .empSeprationApproval {
    padding-left: 15px;
  }
  .es-modal.empSeprationModal
    .empSeprationList
    .empSeprationApproval
    .empAppStatus {
    min-width: 100px;
    text-align: left;
  }
  .es-content.empSepHeadText {
    display: none;
  }
  /* End of Resignation modal css */
}
@media only screen and (max-width: 992px) {
  .es-modal.empSeprationModal {
    max-width: 100%;
  }
  .es-modal.empSeprationModal
    .empSeprationList
    .empSeprationApproval
    .empAppStatus {
    min-width: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .es-modal.empSeprationModal .empSeprationList.empSeprationHead .empApproval {
    width: 100%;
  }
  .es-modal.empSeprationModal .empSeprationList .empApproval {
    column-gap: 0px;
  }
  .es-modal.empSeprationModal
    .empSeprationList
    .empSeprationApproval
    .empAppStatus {
    min-width: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .heading {
    font-size: 23px;
  }

  .boxmain {
    font-size: 14px;
  }

  .button1 {
    font-size: 14px;
  }

  .box1 {
    height: 190px;
  }

  #image {
    width: 16.5rem;
  }

  .react-datepicker__input-container > input {
    width: 15.5rem;
  }

  .selectStyle {
    width: 16.5rem;
  }

  .box2 {
    width: 16.5rem;
  }

  .fmaindiv {
    flex-direction: column;
    padding-left: 50px;
  }

  h2 {
    font-size: medium;
  }
}

@media only screen and (min-width: 992px) {
  .heading {
    font-size: 20px;
  }

  .boxmain {
    font-size: 13.3px;
  }

  .button1 {
    font-size: 13.3px;
  }

  .box1 {
    height: 180px;
  }
}

@media only screen and (max-width: 812px) {
  .emp-profile {
    justify-content: center;
  }

  .bm-burger-button {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .boxmain {
    font-size: 15px;
  }

  .button1 {
    font-size: 15px;
  }

  .labeldiv {
    width: 100%;
  }

  .box1 {
    height: 170px;
  }

  /* .background {
    height: calc(100% - 45px);
  } */
}

@media only screen and (max-width: 600px) {
  .roleModel .roleModalHeader .roleModalheading h3 {
    font-size: 18px;
    font-weight: 600;
  }
  .roleModel .roleModalSubheading {
    padding: 5px 0;
    font-size: 18px;
  }
  .workmode-model {
    min-width: 100%;
  }
  .empInput input {
    font-size: 14px;
  }
  .makeStyles-earningModelWraper-11 {
    min-width: 100%;
  }

  .makeStyles-newSalerySlipWraper-7 {
    flex-direction: column;
  }

  .makeStyles-newSalerySlipAsside-8 {
    width: 100% !important;
  }

  .tabListDiv ul li {
    width: 100%;
  }

  .div1.edu-level-box {
    padding: 0 15px 15px;
    margin-top: 0;
  }

  .edu-add-btn {
    margin-top: 0;
  }

  .main1 {
    flex-direction: column;
  }

  .div1 {
    display: block;
  }

  .select {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .filterDiv {
    min-width: 45%;
  }

  .heading {
    font-size: 18px;
  }

  .css-2b097c-container,
  .main1 .filter-input {
    width: 100%;
    margin-right: 0x;
    margin-bottom: 15px;
  }

  #newDisable {
    margin-bottom: 0;
  }

  .inp {
    width: 100%;
    margin-bottom: 15px;
  }

  .react-3d-carousel {
    display: flex;
    flex-direction: column;
  }

  .box1 {
    height: 160px;
  }

  .labeldiv {
    width: 110%;
  }

  .sel {
    font-size: smaller;
  }

  #image {
    width: 14.5rem;
  }

  .react-datepicker__input-container > input {
    width: 13.5rem;
  }

  .selectStyle {
    width: 14.5rem;
  }

  .box2 {
    width: 14.5rem;
  }

  #image {
    width: 13.5rem;
  }

  .react-datepicker__input-container > input {
    width: 12.5rem;
  }

  .selectStyle {
    width: 13.5rem;
  }

  .box2 {
    width: 13.5rem;
  }

  .button1 {
    font-size: 11.6px;
  }

  .boxmain {
    font-size: 11.6px;
  }

  .labeldiv {
    width: 90%;
    margin-left: 8px;
  }

  .sel {
    height: 20px;
  }

  .sel {
    height: 15px;
    font-size: xx-small;
  }

  #image {
    width: 11.5rem;
  }

  .react-datepicker__input-container > input {
    width: 10.5rem;
  }

  .selectStyle {
    width: 11.5rem;
  }

  .box2 {
    width: 11.5rem;
  }

  .boxmain {
    font-size: 10px;
  }

  .button1 {
    font-size: 9px;
  }

  .labeldiv {
    width: 50%;
    margin-left: 30px;
  }
}

/* mui calendar */
.css-u0soqy-MuiPickerStaticWrapper-root {
  box-shadow: 1px 2px 4px 2px #888888 !important;
}

.css-j88s13-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  padding: 0 !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #1565c0 !important;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #1565c0 !important;
  color: #fff !important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border-color: transparent !important;
  color: #1565c0;
}

.css-144dnr3-MuiBadge-badge {
  color: green;
}

.css-1n2mv2k {
  background-color: #f4f4f4;
  margin-bottom: 1rem;
}

.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title {
  display: none !important;
}

.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  display: none !important;
}

/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 7px;
  direction: ltr;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #838383;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #535353;
}

.newbuttonDisabled {
  align-items: center;
  background: #89939f;
  border-radius: 5px;
  box-shadow: rgb(255 255 255 / 26%) 0 1px 2px inset;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  padding: 10px 20px !important;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border: none;
}

@media only screen and (max-width: 480px) {
  .loginOtpContent .loginOtpEnterPass input {
    width: 35px;
    height: 35px;
  }
}
/* employee separation modal */

.esheading {
  padding: 1em;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #7e009e;
  opacity: 1;
}

.es-rm-box {
  background: #f0f9ff 0% 0% no-repeat padding-box;
}

.es-rm-text {
  text-align: right;
  display: grid;
  padding: 0.5em;
  font-size: 14px;
  color: #333333;
  padding-right: 100px;
}

.es-content {
  text-align: left;
  font-size: 14px;
  color: #000000;
  padding: 1em;
}

/* .es-check {
  padding-right: 2em;
} */

/* .custom-date {
  width: 22% !important;
  padding-right: 1em !important;
} */

/* .custom-date-data{
  width: 25% !important;
  padding-right: 1em !important;
} */

.rm-comment {
  padding: 0 1em 1em !important;
}

.tf-pad {
  padding: 0 1em 0 !important;
}

.rm-comment-modal {
  padding: 1em 1.5em 0 !important;
}

.tf-pad-modal {
  padding: 0.5em 1.5em 0 !important;
}

.confirm-btn {
  background: #7e009e 0% 0% no-repeat padding-box !important;
  border: 1px solid #7e009e !important;
  border-radius: 30px !important;
  color: white !important;
  width: 122px !important;
}

.cancel-btn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #999999 !important;
  border-radius: 30px !important;
  text-align: center !important;
  font: normal normal 600 14px/19px Open Sans !important;
  color: #333333 !important;
  width: 122px !important;
}

.el-lbl {
  text-align: left;
  font: italic normal normal 12px/17px Open Sans;
}

.el-custom-width {
  width: 50%;
}

.hr-data-margin {
  margin-right: 6.3em;
}

.hod-data-margin {
  margin-right: 7.6em;
}

/* resignation history box */

.res-history-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7e009e;
  border-radius: 10px;
  padding: 1em;
}

.res-head {
  text-align: left;
  font-size: 14px;
  color: #003983;
}


.eye-btn {
  text-align: center;
  font: normal normal 300 14px/17px Font Awesome 6 Pro;
  color: #7e009e;
  width: 20%;
}

.view-details {
  border: none;
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
  width: 100%;
  color: #000000;
}

.res-reason {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  color: #333333;
}

/* Employee resignation history modal */

.res-modal-heading {
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  color: #7e009e;
}

.res-his-modal {
  padding: 1em !important;
  width: 816px !important;
}

.res-his-cancel-btn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  text-align: center !important;
  font-size: 14px;
  color: #333333 !important;
}

.res-his-content-heading {
  text-align: left;
  font-size: 14px;
  color: #003983;
}

.res-his-content-data {
  text-align: left;
  font-size: 14px;
  color: #333333;
}

.separator {
  border-top: 1px solid #00000029;
}

.rm-box {
  background: #f6f6f6;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 0px 0px 10px 10px;
  display: inline-flex;
  padding: 5px 15px;
  font-size: 13px;
}

.revoke-heading {
  text-align: left;
  font-size: 13px;
  color: #333333;
  border: 1px solid darkgrey;
  display: inline-flex;
  padding: 5px 15px;
  border-radius: 10px;
}

/* employee separation- approval response */

.es-rejected-state {
  padding: 0.5em;
  background: #ffe8e8 0% 0% no-repeat padding-box;
  border: 3px solid #ffffff;
  border-radius: 50%;
  margin-left: -15px;
  cursor: context-menu;
}

.es-accepted-state {
  background: #cdfecd 0% 0% no-repeat padding-box;
  border: 3px solid #ffffff;
  border-radius: 50%;
  padding: 0.5em;
  margin-left: -15px;
  cursor: context-menu;
}

.es-pending-state {
  background: #fffbee 0% 0% no-repeat padding-box;
  border: 3px solid #ffffff;
  border-radius: 50%;
  padding: 0.5em;
  margin-left: -15px;
  cursor: context-menu;
}

.es-na-state {
  border-radius: 50%;
  padding: 0.5em;
  margin-left: -15px;
  cursor: context-menu;
  background: #dddddd 0% 0% no-repeat padding-box;
  border: 3px solid #ffffff;
}

.action-icon {
  color: #790098;
  size: 15px;
  cursor: pointer;
}

.custom-margin-right {
  padding-right: 20px;
}

.custom-margin-n-right {
  margin-right: -3em !important;
}

.margin-internal {
  margin-right: 3em !important;
}

.custom-overflow {
  overflow-x: hidden !important;
}

.res-accordian {
  padding: 1em;
  padding-left: 3em;
}

.custom-drop {
  width: 175px;
  border-radius: 5px;
  border-color: lightgray;
  font-size: 13px;
}

/* .custom-right{
  margin-right: -25em !important;
} */

.to-text {
  padding: 5px;
  left: 3em;
  position: relative;
}

.es-modal.empSeprationModal
  .empSeprationList
  .empSeprationApproval
  .release-date {
  font-size: 13px;
  color: #006c00;
}

input:focus {
  box-shadow: none !important;
}
.es-modal {
  margin-left: 10em;
  margin-right: 10em;
}

.response {
  margin-right: 3em;
}

.internal-resp {
  margin-right: 2em;
}

.ktReqd-margin {
  margin-right: -8em;
}

.comment {
  padding: 1em;
}

.res-box-after {
  width: 50% !important;
  margin-left: 1em !important;
}

.resignation-confirm-modal-heading{
    padding: 1em;
    display: flex;
    font-size: 20px;
    justify-content: center;
    color: #7e009e;
}
.resignation-confirm-modal-heading {
  padding: 1em;
  display: flex;
  font: normal normal normal 25px/27px Lato;
  justify-content: center;
  color: #7e009e;
}

.revoke-confirm-modal-heading {
  padding: 1em;
  display: flex;
  font-size: 20px;
  color: #7e009e;
}

.revoke-modal-sub-heading {
  padding: 0 25px;
}

.resignation-textarea {
  border-radius: 6px;
  border-color: lightgrey;
}

.res-header-eff-date{
  padding: 0 1em;
  font-size: 14px;
}
