.slider {
  position: relative;
  height: calc(100vh - 54px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    -45deg,
    aliceblue,
    #adb3f4,
    #ecaaaa,
    rgb(137, 192, 240),
    rgb(168, 243, 125)
  );
  position: absolute;
  background-size: 400% 400%;
  animation: change 7s ease-in-out infinite;
  width: 100%;
  padding: 50px;
}

@keyframes change {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.image1 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.right_arrow {
  position: absolute;
  top: 50%;
  right: 100px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  opacity: 0.6;
  cursor: pointer;
  user-select: none;
}

.left_arrow {
  position: absolute;
  top: 50%;
  left: 100px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  opacity: 0.6;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  height: 100%;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  text-align: center;
  width: 75%;
}

@media screen and (max-width: 950px) {
  .slider {
    height: 100vh;
    position: initial;
  }
  .slide.active {
    width: 75%;
  }
  .left_arrow {
    left: 20px;
  }
  .right_arrow {
    right: 20px;
  }
}
@media screen and (max-width: 650px) {

  .left_arrow {
    top: 92%;
    left: 100px;
  }
  .image1 {
    margin-top: 11px;
    width: 95%;
  }
}
@media screen and (max-width: 500px) {
  .slide {
    height: 90vh;
  }
  .right_arrow {
    top: 80%;
    right: 80px;
  }
  .left_arrow {
    top: 80%;
    left: 80px;
  }
  .image1 {
    margin-top: 90px;
    width: 100%;
    height: 65%;
  }
}
@media screen and (max-width: 390px) {
  .right_arrow {
    top: 70%;
  }
  .left_arrow {
    top: 70%;
  }
  .image1 {
    margin-top: 120px;
    height: 48%;
  }
} 

.scrollFix{
  width:100%;
  overflow: scroll;
}

.slideScroll {
  opacity: 0;
  transition-duration: 1s ease;
  height:80vh;
  overflow: auto;
  background:#fff;
  position: relative;
}

.slideScroll.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  text-align: center;
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
