.calendar-toolbar {
  position: absolute;
  top: -42px;
  padding-left: 0px;
  color: #555555;
  font-family: "open sans";
  font-weight: 600;
  display: flex;
  align-items: center;
}

.calendar-toolbar svg{cursor: pointer;}

.calendar-wrapper{
 background:#fff;
 height:100%;
 padding:20px;
 padding-bottom: 0;
 height: calc(100% - 104px);
}

.lunchIcon{
    position: absolute;
    color: #7E009E;
    top: -30px;
    right: 12px;
    pointer-events: auto;
    outline: none;
}

/* Big Calendar Css Overwrite */
.rbc-calendar{
    height: 100%;
    /* padding-bottom: 10px; */
}
.rbc-month-header {
  height: 40px;
  background: #e0f3ff;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.rbc-header {
  border-bottom: 0px;
}

.rbc-row-segment{
    position: relative;
    outline: none;
    /* pointer-events: none; */
    border:0px;
}

.rbc-event, .rbc-event-content{outline: none; border:0px;  pointer-events: none;}

.rbc-event, .rbc-day-slot .rbc-background-event, .rbc-event.rbc-selected{
    pointer-events: none;
    background: transparent;
    outline: none;
    border:0px;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus{outline: none;}
.rbc-off-range {
  color: #bbbbbb;
}

.rbc-off-range-bg,
.rbc-day-bg {
  background: #fff;
}

.rbc-date-cell {
  padding-left: 15px;
  padding-top: 10px;
  text-align: left;
  font-size: 14px;
  pointer-events: none;
  outline: none;
}

.rbc-current.rbc-now .rbc-button-link{border-radius: 100px; width:25px; height: 25px; background:#BCE5FF; margin-bottom:3px;}
