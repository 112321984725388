.changePasswordBg {
  position: absolute;
  width: 100%;
}

.changePassword-wraper {
  display: flex;
  height: calc(100vh - 50px);
  width: 100%;
}

.changePassword-wraper .change-pass-asside {
  background: url("../images/changPasswordBg.jpg");
  background-position: 50%;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.changePassword-wraper .changePassword-form {
  background: #fff;
  height: 100%;
  position: relative;
  width: 100%;
}

.changePassword {
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  justify-content: center;
  left: 50%;
  margin: auto;
  padding: 20px 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60%;
}

.changePassword-logo {
  text-align: center;
}

.changePassword-logo img {
  width: 75%;
}

.changePassword-heading {
  align-self: center;
  color: #26abe2;
  font-size: 30px;
  line-height: 40px;
  margin: 25px 0 75px;
  text-align: center;
}

.changePassword-label {
  color: #2b2b2b;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
}

.change-password-input {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.change-password-input .changePassword-input>div input {
  padding: 10px 15px !important;
}

.changePassword-input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.8em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 0.75rem 0.375rem 3rem;
  width: 100%;
}

.change-password-input fieldset {
  border-radius: 5px 0 0 5px;
  border-right: 0;
}

.input-field-icon {
  background: #eee;
  border: 1px solid #b2c0d3;
  border-radius: 0 5px 5px 0;
  padding: 8px 15px;
  text-align: center;
  width: 50px;
}

.input-field-icon svg {
  color: #666;
}

.input-field-icon {
  background: #eee;
  border: 1px solid #b2c0d3;
  border-radius: 0 5px 5px 0;
  padding: 8px 15px;
  text-align: center;
  width: 50px;
}

.input-field-icon svg {
  color: #666;
}

.confirm-new-password {
  margin-bottom: 50px;
}

.changePassword-button {
  align-self: center;
  background: #26abe2;
  background-size: 200% 200%;
  border: none;
  border-radius: 10px;
  color: #f0f8ff;
  cursor: pointer;
  font-size: larger;
  font-weight: 700;
  margin: auto;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
}

/* .mainDiv {
  border-radius: 10px;
  height: 200px;
  width: 80%;
  margin: auto;
  border: none;
  justify-content: center;
  display: flex;
  flex-direction: column;
} */
.mainDiv1 {
  border-radius: 10px;
  height: 200px;
  width: 80%;
  margin: auto;
  border: none;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 50px;
}

.sendMailButton {
  padding: 10px 30px;
  font-size: 16px;
  background: #87cbff;
  border-radius: 10px;
  border: none;
}

.sendMailButton:hover {
  background-color: #43adff;
}

.changePasswordDialog {
  display: flex;
  justify-content: center;
}

.changePasswordDialog .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  padding: 0;
  background: #fff;
}

.changePasswordMainHeading {
  position: sticky;
  top: 0;
  /* background: rgba(0, 0, 0, 0); */
  z-index: 1;
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 25px;
}

.forgotPassContent {
  font-size: 14px;
  margin: 20px 0 30px;
  color: #000;
  line-height: 26px;
}

.forgotPassContent .companyEmail {
  margin: 20px 0;
}

.forgotPassContent .companyEmail label.companyEmailLabel {
  font-weight: 500;
  margin-bottom: 5px;
}

.changePasswordDialog .MuiDialog-paperWidthSm {
  width: 620px;
  height: fit-content;
  padding: 0px 20px 10px 20px;
  max-height: 80%;
}

.changePasswordDialog_div_button {
  /* background: rgba(0, 0, 0, 0); */
  padding: 15px 20px !important;
}

.lockIcon {
  font-size: 25px;
  margin-right: 10px;
}

.newPassField .newPassLabel {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.newPassField {
  display: flex;
  column-gap: 15px;
  row-gap: 20px;
  text-align: left;
  margin: 20px auto;
}

.loginOtpContent .otpResendTxt {
  margin-top: 20px;
}

/* .divInside {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
} */
.divInside .labelCss {
  margin-bottom: 15px;
}

.divInside .inputCss {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  opacity: 0.7;
  color: #fff;
  font-weight: 100;
}

.divInside .inputCss:focus {
  border: none;
  outline: none;
  border-bottom: 2px solid #fff;
  opacity: 1;
}

.crossIcon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 20px;
}

.otpTextField {
  display: flex;
}

.otpTextField>div {
  margin: 0px 20px;
}

@media only screen and (max-width: 1024px) {
  .changePassword-wraper .change-pass-asside {
    display: none;
  }

  .changePassword-logo img {
    width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .changePassword-logo img {
    width: 50%;
  }

  .changePassword {
    width: 80%;
  }

  .changePasswordDialog .MuiDialog-paperWidthSm {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .changePassword {
    width: 100%;
  }
}