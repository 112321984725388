.loadingContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: rgb(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999999;
    top: 0;
    left: 0;
}
.loading{
    position: relative;
    width: 75px;
    height: 75px;
    font-size: 65px;
    --duration:1s; 
    --num-dot:10;
    --color-dot: #7E009E;
}
.loading > *{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  transform:rotate(calc((360deg / var(--num-dot) /* Number of child */) * var(--index)));
}
.loading > *::before{
  position:absolute;
  content:'';
  top:.15em;
  left:.45em;
  width:.1em;
  height:.1em;
  transform:scale(0); /* First state is scale from 0 */
  border-radius:50%;
  background-color:var(--color-dot);
  animation:scaleTo1 var(--duration) ease-in-out calc(var(--duration) / var(--num-dot) * var(--index)) infinite reverse;
}
.loadingText {
    font-family: sans-serif;
    color: #333;
    margin-top: 20px;
}
@keyframes scaleTo1 {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}