.jobProfileStatus {color: #009B00; font-weight: 600;}
.employmentDetailsStatus { display: flex; align-items: center; column-gap: 5px; }
.employmentDetailsStatus .activeEmploymentStatus { width: 12px; height: 12px; border-radius: 50%; background: #009B00; }
.employmentDetailsStatus .leftEmploymentStatus { width: 12px; height: 12px; border-radius: 50%; background: #d80f0f; }
.tsTimelineSwiter .tsTimelineSlides { 
    /* display: flex; align-items: center; column-gap: 20px; white-space: nowrap;  */
    width: auto !important;}
.sSlideList { display: flex; align-items: center; column-gap: 20px; cursor: pointer;}
.tsTimelineSwiter .tsTimelineSlides .tsTimelineBox { border: 1px solid #ccc; padding: 30px 15px; border-radius: 10px; display: flex;  column-gap: 20px; }
.tsTimelineSwiter .tsTimelineSlides .tsTimelineBox svg { font-size: 30px; color: #7E009E; }
.tsTimelineSwiter .tsTimelineSlides .tsTimelineBox .tsTimelineBoxContent .tsTimelineBoxHeading { font-weight: 600; color: #000; }
.tsTimelineSwiter .tsTimelineSlides .tsTimelineBox .tsTimelineBoxContent .tsTimelineBoxDate { font-size: 12px; color: #333333; }
.tsTimelineSwiter .tsTimelineSlides .tsTimelineCircle { width: 50px; height: 50px; line-height: 50px; text-align: center; background: #fff; border-radius: 50%; border: 1px solid #333; color: #000; padding: 7px 14px; display: flex; align-items: center; justify-content: center; }
.employee_form_dialog.empReportsHistoryDialog .MuiDialog-paperWidthSm {
    min-width: 750px;
}