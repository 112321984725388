.EmpProjectInfo {
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  position: relative;
}
.EmpProjectInfo:hover {
  border: 1px solid #666666;
}
.EmpProjectInfo .projectLogo .projectLogoTitle {
  font-weight: 600;
  color: #000000;
}
.EmpProjectInfo .projectLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.EmpProjectInfo .projectLogo img {
  width: 110px;
}
.EmpProjectInfo .projectSubtitle {
  font-size: 14px;
  color: #333333;
  padding: 0px;
}
.EmpProjectInfo .projectRole .projectRoleTxt {
  font-size: 14px;
  color: #333333;
}
.EmpProjectInfo .projectRole .projectRoleContent {
  font-weight: 600;
  color: #333333;
}
.EmpProjectInfo .projectTeam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}
.EmpProjectInfo .projectTeam .projectEmployees {
  display: flex;
  align-items: flex-end;
}
.EmpProjectInfo .projectTeam .employeeImg .projectTeamTitle {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}
.EmpProjectInfo .projectTeam .employeeImg .projectEmpImages img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -5px;
  outline: 3px solid #fff;
}
.EmpProjectInfo .projectTeam .employeeImg .projectEmpImages img:first-child {
  margin-left: 0;
}
.EmpProjectInfo .projectTeam .projectEmployees .moreEmployeesCount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin-left: -10px;
  outline: 3px solid #fff;
  background: #dddddd;
  border-radius: 50%;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
}
.EmpProjectInfo .projectTeam .projectManagerImg {
  text-align: center;
}
.EmpProjectInfo .projectTeam .projectManagerImg img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}
.projectManagerTitle {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}
/* Project Info Team Box Css */
.projectInfoTeam {
  z-index: 1;
}
.projectInfoTeam .projectInfoTeamList.teamApprover {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 60%;
  overflow-y: auto;
  height: 100%;
  top: 0;
  left: 40%;
  z-index: 1;
}
.projectInfoTeam .projectInfoTeamList {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 60%;
  overflow-y: auto;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.projectInfoTeam .projectInfoTeamList::-webkit-scrollbar{
  display: none;
}
.projectInfoTeam .projectInfoTeamList.teamApprover::-webkit-scrollbar{
  display: none;
}
.projectInfoTeam .projectInfoTeamList li {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  padding: 5px 10px;
}
.projectInfoTeam .projectInfoTeamList li .projectListProfile img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}
.projectInfoTeam .projectInfoTeamList li .projectListContent .projectEmpName {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.projectInfoTeam
  .projectInfoTeamList
  li
  .projectListContent
  .projectEmpDesignation {
  font-size: 12px;
  color: #333;
}

.EmpProjectInfo .projectInfoBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}
.EmpProjectInfo .projectInfoBtns .inProgressBtn {
  font-size: 12px;
  font-weight: 600;
  background: #fffbee;
  color: #ae5700;
  border: 1px solid #b78a20;
  border-radius: 35px;
  padding: 2px 8px;
}
.EmpProjectInfo .projectInfoBtns .primaryProjectBtn {
  font-size: 12px;
  font-weight: 600;
  background: #eeffff;
  color: #006363;
  border: 1px solid #009494;
  border-radius: 35px;
  padding: 2px 8px;
}
.EmpProjectInfo .startEndDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.EmpProjectInfo .startEndDate .startDateContent .startDateTxt,
.EmpProjectInfo .startEndDate .endDate .endDateTxt {
  font-size: 14px;
  color: #333333;
}
.EmpProjectInfo .startEndDate .startDateContent .startDate,
.EmpProjectInfo .startEndDate .endDate .endDate {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}
.EmpProjectInfo .startEndDate .endDate {
  text-align: right;
}
#disableCard {
  background-color: #f0f0f0;
}
.projectInfoTeamList.LastElement li:last-child {
  position: sticky;
  bottom: 0px;
  background: #fff;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
}
@media only screen and (max-width: 1680px) {
  .EmpProjectInfo .projectTeam .employeeImg .projectEmpImages img,
  .EmpProjectInfo .projectTeam .projectEmployees .moreEmployeesCount,
  .EmpProjectInfo .projectTeam .projectManagerImg img {
    width: 30px;
    height: 30px;
  }
  .EmpProjectInfo .projectInfoBtns .inProgressBtn,
  .EmpProjectInfo .projectInfoBtns .primaryProjectBtn {
    font-size: 12px;
    padding: 3px 10px;
  }
}
