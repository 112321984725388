.empDocumentsMain .empDocumentsTable table, .empDocumentsMain .empDocumentsTable tr th, .empDocumentsMain .empDocumentsTable tr td { border: none !important; }
.empDocumentsMain .empDocumentsTable { border: 1px solid #0000001A; border-radius: 10px; margin-top: 20px; overflow-x: auto; width: 100%; white-space: nowrap;}
.empDocumentsMain .empDocumentsTable .pdfIconWithTxt { display: flex; align-items: center; column-gap: 10px; }
.empDocumentsMain .empDocumentsTable .pdfIconWithTxt svg { font-size: 20px; color: #CE0022; }
.empDocumentsMain .empDocumentsTable .pdfIconWithTxt .pdfIconWithTxtContent { font-size: 14px; color: #000; }
.empDocumentsMain .empDocumentsTable .documentsTableAction { font-size: 14px; margin: auto; text-align: center; color: #333; }
.empDocumentsMain .empDocumentsTable .documentsTableAction svg { padding: 0 15px; cursor: pointer;}
.empDocumentsMain .empDocumentsTable .documentsTableAction .trashIcon { color: #8A0000; }
.empDocumentsMain .empDocumentsTable tbody tr td { padding: 15px !important; }
.tableHeading { padding: 10px 20px; background: #FCFCFC; box-shadow: 0px 1px 1px #0000001A; vertical-align: baseline;}
.tableHeading .tableHeadingTxt { font-size: 14px; font-weight: 600; color: #000; }
.browseHereBtn { padding: 7px 30px; text-align: center; text-decoration: none; display: inline-block; cursor: pointer; font-size: 14px; color: #FFFFFF; background: #1678C8; border: 1px solid #1678C8; border-radius: 35px; }
.empTitle.horizontalLine { display: inline-flex; position: relative; width: 100%; font-size: 16px; font-weight: 600;}
.empTitle.horizontalLine span { background: #fff; z-index: 1; padding-right: 15px; }
.empTitle.horizontalLine:after { content: ""; border: 1px solid #DDDDDD; width: 100%; position: absolute; top: 50%; }
.empDocumentsMain {
    height: calc(100vh - 210px);
    margin-top: 20px;
}

.documentCard {
    margin-bottom: 30px;
}
.documentCard .documentsTitle {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}
.documentCard .documentCardContent {
    background: #FAFAFA;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 10px;
}
.documentCard .documentCardContent ul.documentsList {
    padding: 15px 0 20px;
}
.documentCard .documentCardContent ul.documentsList li {
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.documentCard .documentCardContent ul.documentsList li .pdfName {
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
    color: #333333;
}
.documentCard .documentCardContent ul.documentsList li .pdfIcon {
    font-size: 18px;
    color: #DA3848;
}
.documentCard .documentCardContent ul.documentsList li .documentPdfClose {
    font-size: 14px;
    color: #DA3848;
    cursor: pointer;
}
.documentCard .documentCardContent .drapAndDrop {
    min-width: 100%;
    background: #fff;
}
.documentCard .documentCardContent .drapAndDrop .browseContent .browseBtn {
    padding: 2px 15px;
    font-size: 13px;
}
.documentCard .documentCardContent .drapAndDrop .browseContent .browseText {
    font-size: 11px;
    color: #666666;
}
.documentCard .documentCardContent .drapAndDrop .uploadContent .uploadText {
    font-size: 12px;
    color: #333333;
}