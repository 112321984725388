.downloadReportLoader {
  width: 15px !important;
  height: 15px !important;
  margin-left: 10px;
  color: #7e009e;
}
.payrollTableResponsive
  .MuiPaper-elevation
  .tss-hwdp7s-MUIDataTable-liveAnnounce {
  height: auto;
}
.payrollTableResponsive {
  height: calc(100vh - 200px);
  overflow: auto;
}
.payrollTableResponsive.empSeparationApprovalContainer {
  height: calc(100vh - 130px);
  overflow: auto;
}
.payrollTableResponsive .payrollTableConteiner {
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.payrollTableResponsive table,
.payrollTableResponsive table thead tr th.MuiTableCell-head,
.payrollTableResponsive table tbody tr td.MuiTableCell-body {
  border: none !important;
  padding-left: 15px;
}
.payrollTableResponsive .MuiPaper-elevation {
  box-shadow: none;
  border-radius: 5px;
}
.payrollTableResponsive table thead tr th.MuiTableCell-head,
.payrollTableResponsive table tbody tr td.MuiTableCell-body {
  border-top: 1px solid #00000029;
}
.payrollTableResponsive table thead tr th {
  background: #f1f4fb !important;
  position: sticky;
  top: 0px;
  left: 0;
  padding: 15px !important;
}
.innerTable table thead tr th:first-child {
  width: 55px;
}
.innerTable table thead tr {
  border-bottom: 1px solid #00000029;
}
.payrollTableConteiner.workmodeTable table tr td .workmodeActionBtn {
  border: none;
  background: transparent;
  color: #790098;
  font-size: 16px;
}
.payrollTableResponsive table thead tr th button.MuiButton-sizeMedium {
  font-size: 16px;
  font-weight: 600;
}
.payrollTableResponsive .payrollTableConteiner.workmodeTable thead tr th {
  color: #555555;
  font-size: 14px !important;
  white-space: nowrap;
}
.payrollTableResponsive .payrollTableConteiner.workmodeTable tbody tr td {
  white-space: nowrap;
}
.payrollTableResponsive .payrollTableConteiner table tfoot {
  border-top: 1px solid #00000029;
}
.payrollTableResponsive table tbody tr.MuiTableRow-hover:hover {
  background: #f1f4fb;
}
.payrollTableResponsive table tbody tr.MuiTableRow-hover:active {
  background: #f1f4fb;
}
.payrollTableResponsive table tfoot tr td.MuiTableCell-footer {
  padding: 0 !important;
  background: #fff;
  border: none !important;
}
.payrollTableResponsive table tbody tr th {
  border-top: 1px solid #00000029;
  color: #555555;
  padding: 20px;
}
/* modals css */
.postModalsContainer .postModalsContent .postModalTitle {
  font-size: 18px;
  font-weight: 500;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1;
  padding-bottom: 15px;
}
.postModalsContainer .postModalsContent .postModalTitle .postModalTitleHeading {
  padding: 20px;
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.postModalsContainer .postModalsContent .postModalSubHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  background: #f0f9ff;
  color: #001460;
}
.payrollModalCancleBtn {
  width: 122px;
  height: 36px;
  border: 1px solid #999999;
  border-radius: 30px;
  color: #333333;
  background: none;
}
.payrollModalCancleBtn:hover {
  color: black;
  border: 1px solid #333333;
}
.payrollModalSubmitBtn {
  width: 122px;
  height: 36px;
  border: 1px solid #7e009e;
  border-radius: 30px;
  background: #7e009e;
  color: #ffff;
}
.payrollModalSubmitBtn:hover {
  background: #660080;
}
.payrollModalFooter {
  padding: 20px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: sticky;
  bottom: 0;
  left: 0;
  background: #fff;
}
.postModalsContent .payrollModalInnerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 20px;
}
.postModalsContent .payrollModalInnerContent label {
  margin-right: 0;
}
.postModalsContent .payrollModalInnerContent .payrollModalInnerContentDate {
  color: #000;
  font-weight: 600;
  white-space: nowrap;
}
.postModalsContent .payrollModalInnerContent .payrollModalHalf_And_FullDay {
  margin-right: 30px;
}
.postModalsContent
  .payrollModalInnerContent
  .payrollModalHalf_And_FullDay
  ul.attanReguBtn
  li {
  width: auto;
}
.postModalsContent
  .payrollModalInnerContent
  .payrollModalHalf_And_FullDay
  ul
  li:first-child
  label {
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 35px 0 0 35px;
  white-space: nowrap;
}
.postModalsContent
  .payrollModalInnerContent
  .payrollModalHalf_And_FullDay
  ul
  li:last-child
  label {
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 0 35px 35px 0;
  white-space: nowrap;
}
.postModalsContent
  .payrollModalInnerContent
  .payrollModalFirst_And_SecondHalf
  label:first-child {
  margin-right: 30px;
  color: #000;
}
/* end of modals css */
.empTabs.payrollTabs {
  border-top: 1px solid #00000029;
  border-bottom: 1px solid #00000029;
  position: sticky;
  top: 50px;
  z-index: 1;
}
.tab-content.payrollTab {
  height: calc(100vh - 107px);
  overflow: auto;
  background: #f1f4fb;
}
.payrollSummaryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
}
.payrollSummaryDate {
  position: relative;
  cursor: pointer;
  width: 300px;
}
.DashboardpayrollSummaryData {
  position: relative;
  cursor: pointer;
  width: 300px;
  display: flex;
}
.DashboardpayrollSummaryData label {
  width: 220px;
  font-size: 16px;
  font-weight: 600;
  line-height: 33px;
  color: #444;
}
.yearlyPayrollTitleSummary .graphCurrentYear .payrollSummaryDate > div {
  background: transparent !important;
  padding: 0;
}
.yearlyPayrollTitleSummary .graphCurrentYear .payrollSummaryDate > div input {
  background: transparent !important;
  -webkit-text-fill-color: #333333;
}
.payrollSummaryDate fieldset {
  border: none;
}
.yearlyPayrollTitleSummary .graphCurrentYear {
  width: 100%;
  min-width: 200px;
  margin: 0;
  padding: 0;
  /* height: 20px; */
}
.payrollSummaryDate input {
  font-size: 16px;
  font-weight: 600;
  color: #666;
}
.payrollSummaryGraph {
  height: 100%;
  max-height: 445px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.payrollSummaryDate .summaryDate {
  font-weight: 600;
  pointer-events: none;
}
.payrollSummaryDate span.dateFromTo {
  font-size: 14px;
  margin: 0 20px 0 10px;
  color: #000;
  pointer-events: none;
}
.payrollSummaryActionBtn {
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.payrollSummary {
  margin-top: 20px;
}
.payrollSummary .payrollSummaryContent {
  background: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  height: 100%;
}
.payrollSummary .payrollSummaryContent .payrollSummaryText {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.payrollSummary .payrollSummaryContent .payrollSummaryValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payrollSummary
  .payrollSummaryContent
  .payrollSummaryValue
  .payrollSummaryCount {
  font-size: 24px;
}
.payrollSummary
  .payrollSummaryContent
  .payrollSummaryValue
  .payrollSummaryCount
  span {
  font-size: 16px;
  color: #666666;
}
.payrollSummary
  .payrollSummaryContent
  .payrollSummaryValue
  .payrollSummaryCount.payrollDate {
  font-size: 16px;
  font-weight: 600;
}
.payrollSummary
  .payrollSummaryContent
  .payrollSummaryValue
  .payrollSummaryIcon {
  font-size: 18px;
  color: #009b00;
}
.payrollSummaryActionBtn {
  margin-top: 20px;
}

.payrollSummaryDate .payrollSummaryDateHistory {
  list-style: none;
  position: absolute;
  top: 40px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #00000029;
  z-index: 1;
}
.payrollSummaryDate .payrollSummaryDateHistory li:last-child {
  border-radius: 0 0 10px 10px;
}
.payrollSummaryDate .payrollSummaryDateHistory li:first-child {
  border-radius: 10px 10px 0 0;
}
.payrollSummaryDate .payrollSummaryDateHistory li {
  padding: 10px 20px;
}
.payrollSummaryDate .payrollSummaryDateHistory li span.dateFromTo {
  margin: 0;
}
.payrollSummaryDate .payrollSummaryDateHistory li.active {
  background: #eaf6ff;
}

.payrollTitle {
  font-size: 16px;
  font-weight: 600;
  color: #555555;
  /* margin-top: 30px; */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.payrollTitle .payrollCompTitle {
  color: #e86b17;
  font-size: 14px;
  margin-left: 10px;
}
.monthlySummary .monthlySummaryBox {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.monthlySummary .monthlySummaryBox .monthlySummaryText {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryText
  span.netParollCostInfoIcon {
  color: #999999;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryText
  span.netParollCostInfoIcon
  .netPayCostHover {
  position: absolute;
  top: -35px;
  border: 1px solid #0077c6;
  color: #0077c6;
  padding: 5px 15px;
  border-radius: 6px;
  white-space: nowrap;
  background: #bce5ff;
  display: none;
}
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryText
  span.netParollCostInfoIcon:hover
  .netPayCostHover,
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryText
  .netPayError:hover
  .netPayErrorHover {
  display: block;
}

.monthlySummary .monthlySummaryBox .monthlySummaryText .netPayError {
  position: relative;
  cursor: pointer;
}
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryText
  .netPayError
  .netPayErrorHover {
  position: absolute;
  top: -35px;
  border: 1px solid #0077c6;
  color: #0077c6;
  padding: 5px 15px;
  border-radius: 6px;
  white-space: nowrap;
  background: #bce5ff;
  display: none;
}
.monthlySummary .monthlySummaryBox .monthlySummaryValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryValue
  .monthlySummaryAmount
  svg.rupeeIcon {
  font-size: 16px;
}
.monthlySummary .monthlySummaryBox .monthlySummaryValue .monthlySummaryAmount {
  font-size: 18px;
  color: #001d6d;
}
.monthlySummary .monthlySummaryBox .monthlySummaryValue .monthlySummaryIcon {
  font-size: 18px;
  color: #009b00;
}
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryValue
  .monthlySummaryAmount.netPayrollCost {
  font-size: 14px;
  font-weight: 600;
  color: #e86b17;
}
.monthlySummary .monthlySummaryBox .monthlySummaryText .warning-icon {
  color: #e86b17;
}
/* .payrollCompBox {
  padding: 0;
}
.payrollCompBox:last-child {
  padding-right: 10px;
}
.payrollCompBox:first-child {
  padding-left: 10px;
} */
.payrollCompBox .monthlySummary .monthlySummaryBox {
  border-right: 2px solid #f1f4fb;
  border-radius: 0;
  white-space: nowrap;
}
.payrollCompBox:first-child .monthlySummary .monthlySummaryBox {
  border-radius: 10px 0 0 10px;
}

.payrollCompBox:last-child .monthlySummary .monthlySummaryBox {
  border-radius: 0 10px 10px 0;
}
.payrolCompIcon {
  font-size: 14px;
  margin-left: 5px;
}
.payrolCompIcon.warning {
  color: #9e5b00;
}
.payrolCompIcon.success {
  color: #2eae4b;
}
.payrollCompBox .monthlySummary .monthlySummaryBox .monthlySummaryText {
  margin-bottom: 20px;
}
.payrollCompBox .monthlySummary .monthlySummaryBox .cardDetails {
  font-size: 14px;
  margin-top: 5px;
  color: #999999;
  text-decoration: none;
}
.yearlyPayrollTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.yearlyPayrollTitle .yearlyPayrollTitleSummary {
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  position: relative;
  cursor: pointer;
}
.currentYearToggle {
  list-style: none;
  position: absolute;
  top: 25px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #00000029;
  min-width: 150px;
}
ul.currentYearToggle li {
  padding: 10px 20px;
}
ul.currentYearToggle li.active {
  background: #eaf6ff;
}
ul.currentYearToggle li:first-child {
  border-radius: 10px 10px 0 0;
}
ul.currentYearToggle li:last-child {
  border-radius: 0 0 10px 10px;
}
.payrollCompleaveAttendance,
.payrolcomponentIcon,
.bonusRevisions,
.reimbursementDeductions,
.onHoldArrears {
  position: relative;
  cursor: pointer;
}
.monthlySummary
  .monthlySummaryBox
  .monthlySummaryValue
  .monthlySummaryAmount
  .payrolCompIcon {
  font-size: 18px;
  margin-left: 10px;
}
.payrolCompHover {
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #4ba4dd;
  color: #0077c6;
  border-radius: 6px;
  background: #fff;
  min-width: 250px;
  padding: 5px 15px;
  display: none;
}
.payrollCompleaveAttendance:hover .payrolCompHover {
  display: block;
}
.payrolcomponentIcon:hover .payrolCompHover {
  display: block;
}
.bonusRevisions:hover .payrolCompHover {
  display: block;
}
.reimbursementDeductions:hover .payrolCompHover {
  display: block;
}
.onHoldArrears:hover .payrolCompHover {
  display: block;
}
.payrolCompHover .payrolCompHoverContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}
.payrolCompHover .payrolCompHoverContent .payrolCompHoverCount {
  font-weight: 600;
}

.Project-date-input input {
  height: 1em;
}

/* leave_Att_TopHeader */
.leave_Att_TopHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #00000029;
  padding: 0 15px;
}
.leave_Att_TopHeader.bonus_revision_header {
  padding: 0 15px 0 0;
}
ul.leave_Att_TopHeader_Menu {
  display: flex;
  align-items: center;
  list-style: none;
}
ul.leave_Att_TopHeader_Menu .leaveAttTab {
  background: transparent;
  color: #555555;
  font-weight: 600;
  border-bottom: 1px solid transparent;
  position: relative;
  padding: 15px 30px;
  cursor: pointer;
}
ul.leave_Att_TopHeader_Menu .leaveAttTab.active {
  border-bottom: 1px solid #555555;
}
ul.leave_Att_TopHeader_Menu .leaveAttTab.active:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #555;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.leave_Att_TopHeader .payrollSummaryDate .empInput {
  margin: 0 !important;
}
.compAttTablePagination {
  position: sticky;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 1;
}
.completeAttendanceData {
  position: relative;
  background: #fff;
  height: calc(100vh - 240px);
  width: 100%;
  overflow-y: auto;
}
.completeAttendanceData.myTeamCalendar {
  height: calc(100vh - 12px);
}
.completeAttendanceData table {
  border: none !important;
  border-collapse: separate;
  border-spacing: 0;
  padding: 0 !important;
  margin-bottom: 0;
}
.completeAttendanceData table td .mnthAttWrapper {
  width: 300px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  background: #fff;
  padding: 9px;
}
.completeAttendanceData table th {
  background: #fff;
}
.completeAttendanceData table thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.completeAttendanceData table th:first-child {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.completeAttendanceData table td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}

.completeAttendanceData table .compAttEmpList {
  text-align: left;
}
.completeAttendanceData table .compAttEmpList .compAttEmpName {
  font-size: 14px;
  font-weight: 600;
}
.completeAttendanceData table .compAttEmpList .designation {
  font-size: 12px;
  color: #222222;
}
.completeAttendanceData table .mnthAttEmpIcon img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.completeAttendanceData table .mnthAttEmpIcon {
  width: 36px;
  height: 36px;
  background: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
}
.completeAttendanceData table td {
  background: #fff;
  padding: 2px !important;
  text-align: center;
  position: relative;
}
.completeAttendanceData table td,
.completeAttendanceData table th {
  margin: 0;
  border: none !important;
  white-space: nowrap;
}
.completeAttendanceData table .headcol {
  position: absolute;
  width: 2em;
  left: 0;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
}
.completeAttendanceData table .headcol:before {
  content: "Row ";
}
.completeAttendanceData table .long {
  letter-spacing: 1em;
}
.completeAttendanceData table td .weekOff {
  background: #f8f8f8;
  color: #f8f8f8;
  padding: 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 28px;
  line-height: 28px;
}
.completeAttendanceData table td .present {
  background: #ecffec;
  color: #006c00;
  padding: 2px 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
}

.completeAttendanceData table td .off_day {
  background: #f8f8f8;
  color: #f8f8f8;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
}

.completeAttendanceData table td .holiday {
  background: #ccedf6;
  color: #26356f;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
}

.completeAttendanceData table td .OD {
  background: #f8f8f8;
  color: #f8f8f8;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
}
.compAttTableDate {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #000;
  line-height: 20px;
}
.completeAttendanceData table td .absent {
  background: #ffe0e0;
  color: #8a0000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
}

.completeAttendanceData table td .leave_without_pay {
  background: #ffe0e0;
  color: #8a0000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
}
.completeAttendanceData table td .open_leave {
  background: #ffebeb;
  color: #801b90;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
  font-weight: 600;
  word-break: break-word;
  white-space: normal;
}

.completeAttendanceData table td .halfDay {
  background: #f8f8f8;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
  font-weight: 600;
  word-break: break-word;
  white-space: normal;
}

.completeAttendanceData table td .approved_leave {
  background: #ffebeb;
  color: #8a0000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  line-height: 28px;
}

.completeAttendanceData table td .maternity {
  background: #ecffec;
  color: #006c00;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.completeAttendanceData table td .attHistoryListContent {
  display: none;
}
.completeAttendanceData table td:hover .attHistoryListContent {
  display: block;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 9;
}
/* .attHistoryListContent:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #888888;
  z-index: 1;
} */

/* Monthly Attendence hover tool tip css*/
.attHistoryListContent .attHistoryStatus .attHistoryStatusText {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.attHistoryListContent .attHistoryStatus .attHistoryStatusText .absentInfoIcon {
  font-size: 13px;
  margin-left: 5px;
  cursor: pointer;
}
.attHistoryListContent
  .attHistoryStatus
  .attHistoryStatusText
  .infoIconHoverState {
  position: relative;
}
.attHistoryListContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 20px 0 0;
  margin-bottom: 15px;
  width: 560px;
}
.attHistoryListContent:last-child {
  margin-bottom: 0;
}
.attHistoryListContent .attHistoryDays {
  padding: 0 20px;
  text-align: center;
}
.attHistoryListContent .attHistoryDays .attHistoryDaysCount {
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
}
.attHistoryListContent .attHistoryDays .attHistoryDayText {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
/* .hrCalanderRight .attendanceHistory ul.attHistoryList .attHistoryListContent .workFromHomeContent { line-height: 30px; }  */
.attHistoryListContent .workFromHomeContent .workFromHomeText {
  font-weight: 500;
}
.attHistoryListContent .workFromHomeContent .workFromHomeText {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.attHistoryListContent .workFromHomeContent .calendarIconWithDate .checkInTxt {
  font-size: 14px;
  font-weight: 500;
}
.attHistoryListContent
  .workFromHomeContent
  .calendarIconWithDate
  .checkInTxt
  span {
  font-weight: 400;
}
.attHistoryListContent .calendarIconWithDate .workFromHomeBadge {
  display: inline-flex;
  font-size: 12px;
  border-radius: 2px;
  background: #eeeeee;
  padding: 2px 15px;
}
.attHistoryListContent .workFromHomeContent .workFromHomeBadge {
  background: #eeeeee;
  padding: 3px 15px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 2px;
  display: inline-block;
  margin-bottom: 5px;
}
.attHistoryListContent .workFromHome {
  display: flex;
  align-items: center;
  column-gap: 50px;
  width: 100%;
}
.attHistoryListContent .attHistoryStatus {
  margin-left: auto;
  text-align: center;
  color: #0f673e;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500; /* cursor: pointer; */
  width: 110px;
  white-space: normal;
  text-align: center;
}
.attHistoryListContent .attHistoryStatus svg {
  font-size: 22px;
}
.attHistoryListContent .attHistoryListCols {
  display: flex;
  align-items: center;
  width: 100%;
}
.attHistoryListContent .attHistoryListCols .workFromHomeContent {
  text-align: left;
}
.attHistoryListContent .attHistoryAddText {
  width: 100%;
  font-size: 13px;
  font-weight: 600;
}
.attHistoryListContent .attHistoryLoggedHours {
  width: 100%;
  font-size: 13px;
}
.attHistoryListContent .attHistoryLoggedHours span {
  font-weight: 500;
}
.attHistoryListContent .listAddress {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 0 0 5px 5px;
  padding: 5px 20px;
  margin-top: 20px;
  text-align: left;
}
/* LoggedOut Attendance List Css */
li.attHistoryListContent.loggedOut .workFromHomeText,
.attHistoryListContent.loggedOut .attHistoryStatus,
.attHistoryListContent.loggedOut .attHistoryLoggedHours {
  color: #8a0000;
  cursor: pointer;
}
.hrCalanderRight
  .attendanceHistory
  .attHistoryList
  .attHistoryListContent.loggedOut {
  border-color: #8a0000;
}
.attHistoryListContent.loggedOut .workFromHomeContent .workFromHomeBadge {
  background: #ffe7e7;
  color: #8a0000;
}
li.attHistoryListContent.pendingStatus .workFromHomeText,
.attHistoryListContent.pendingStatus .attHistoryStatus,
.attHistoryListContent.pendingStatus .attHistoryLoggedHours {
  color: #9e5b00;
}
.hrCalanderRight
  .attendanceHistory
  .attHistoryList
  .attHistoryListContent.pendingStatus {
  border-color: #9e5b00;
}
.attHistoryListContent.pendingStatus .workFromHomeContent .workFromHomeBadge {
  background: #ffeed6;
  color: #9e5b00;
}
/* End of Monthly Attendence hover tool tip css*/

.attendanceArrow {
  cursor: pointer;
  padding: 0px 20px 0px 5px;
  font-size: 20px;
}

.attendanceMonth {
  padding: 0px 20px 0px 5px;
}
.projectWResources {
  display: flex;
  align-items: center;
  height: 175px;
}
ul.resourcesList {
  list-style: none;
  min-width: 230px;
}
ul.resourcesList li {
  font-size: 14px;
  padding: 5px 15px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;
}
ul.resourcesList .resourcesListCount {
  display: flex;
  align-items: center;
  column-gap: 5px;
  pointer-events: none;
}
.resourcesList .resourcesListItem {
  display: flex;
  align-items: center;
  column-gap: 10px;
  pointer-events: none;
}
ul.resourcesList li.active {
  background: #f8f8f8;
}
ul.resourcesList li .resourcesListCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
ul.resourcesList li .resourcesListCircle.circleOne {
  background: #b41fb4;
}
ul.resourcesList li .resourcesListCircle.circleTwo {
  background: #3b67ce;
}
ul.resourcesList li .resourcesListCircle.circleThree {
  background: #069a00;
}
ul.resourcesList li .resourcesListCircle.circleFour {
  background: #45b4e4;
}
.markAttList .markAttLeaveType {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.markAttList .markAttLeaveType label {
  margin-right: 15px;
  white-space: nowrap;
  font-weight: 600;
}
.markAttLeaveType .markAttLeaveTypeInput {
  width: 100%;
}

@media screen and (max-width: 1680px) {
  .myTeamWFH .wfh_employee .myTeamEmpImg {
    width: 65px;
    height: 65px;
  }
}
@media screen and (max-width: 992px) {
  .myTeamLeftCols {
    padding-right: 0;
  }
  .monthlyAttendance {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .myTeamTodayStatus .myTeamTodayStatusContent {
    height: 165px;
    width: 100%;
  }
  .myTeamResources .departmentWiseResoursesTitle {
    font-size: 14px;
    font-weight: 500;
  }
  .myTeamResources .departmentWiseResoursesTitle .selectResources {
    padding: 2px 15px;
    font-size: 14px;
  }
  .myTeamLeftSide {
    height: auto;
  }
  .completeAttendanceData {
    height: calc(100vh - 68px);
  }
}
@media screen and (max-width: 768px) {
  .monthlyAttendance .myTeamResources {
    flex-direction: column;
    column-gap: normal;
    height: 500px;
    row-gap: 85px;
    overflow: initial;
  }
  .monthlyAttendance .myTeamResources .departmentWiseResourses,
  .monthlyAttendance .myTeamResources .projectWiseResourses {
    height: 165px;
    width: 100%;
  }
  .myTeamTodayStatus {
    min-width: 100%;
    height: auto;
  }
}
/* @media screen and (max-width: 600px) {
  .monthlyAttendance .monthAttHeader .monthAttHeaderMode {
    margin-top: 15px;
    line-height: 30px;
  }
} */

.designation {
  font: var(--unnamed-font-style-normal) normal normal 12px/17px
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
  text-align: left;
  font: normal normal normal 10px/15px Open Sans;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}
.payrollActionsDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  padding-right: 15px;
  column-gap: 20px;
  /* border-bottom: 1px solid #00000029; */
}
.payrollActionsDivResignation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  /* border-bottom: 1px solid #00000029; */
}
.compAttSearchBtn .searchBox .searchBoxInput {
  padding: 3px 15px;
}
.actionButtonReject {
  border: 1px solid #8a0000;
  color: #8a0000;
}

.actionButtons {
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  margin-left: 10px;
}

.actionButtonApprove {
  border: 1px solid #7e009e;
  color: #7e009e;
}

.actionTabs {
  padding: 5px;
  border-radius: 10px;
}

.actionTabs:hover {
  background-color: rgb(249, 214, 214);
  cursor: grab;
}

.activeTab {
  background-color: rgb(250, 195, 195);
}

.noAttendanceDateColumn {
  display: flex;
  column-gap: 5px;
  width: 360px;
  max-height: 65px;
  flex-wrap: wrap;
  overflow: auto;
}

.noAttendanceDateDiv {
  padding: 3px 10px;
  background-color: #f0f4ff;
  text-align: left;
  color: #333333;
  box-shadow: 0px 1px 0px #7a91cb;
  border-radius: 5px;
  margin-bottom: 5px;
}

.actionIcon {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  color: #790098;
  column-gap: 15px;
}
.payrollTableConteiner table tr td .actionIcon .OnHold {
  background: #fffbf1;
  border: 1px solid #ddd4bb;
  color: #9f7700;
  padding: 0 15px;
  border-radius: 35px;
}
.payrollTableConteiner table tr td .actionIcon .Release {
  background: #f1fff8;
  border: 1px solid #a6c9b9;
  color: #0f673e;
  padding: 0 15px;
  border-radius: 35px;
}

.payrollTableConteiner table tr td .actionIcon .Pending {
  background: #ad3acb05;
  border: 1px solid #790098;
  color: #790098;
  padding: 0 15px;
  border-radius: 35px;
}

/* .actionIcon:hover {
  background-color: rgb(247, 246, 246);
  cursor: grab;
} */

.ShareThoughtsIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px 24px;
  font-size: 14px;
}

.approveBtn {
  border: 1px solid #7e009e;
  background: #790098;
  color: #fff;
  border-radius: 30px;
  padding: 5px 20px;
}

.rejectedBtn {
  border: 1px solid #8a0000;
  background: #8a0000;
  color: #fff;
  border-radius: 30px;
  padding: 5px 20px;
  margin-left: 20px;
}

.notifyMe {
  border: 1px solid #009b00;
  background: #009b00;
  color: #fff;
  border-radius: 35px;
  padding: 2px 15px;
  font-size: 14px;
}

.reasonInput {
  width: 92%;
  padding: 15px;
  height: 50px;
  border: none;
  background: #fff;
  border: 1px solid #00000029;
  margin: 10px 0px;
}

.errorMessage {
  color: #cf0101;
  font-size: 15px;
}

.selectDataOption .MuiPickersCalendarHeader-root {
  display: none;
}

.create_form_input_div {
  display: flex;
  flex-direction: column;
}

.create_form_input_div.autoSelect
  .MuiInputBase-fullWidth.MuiInputBase-formControl {
  padding-top: 0;
  padding-bottom: 0;
}

.create_form_input_div.autoSelect .empInput {
  margin-top: 0 !important;
}

.pendingApprovalHeading {
  font-size: 18px;
  color: #444444;
  font-weight: 600;
}

.pendingApprovelList {
  margin-top: 10px;
  padding-left: 20px;
}

.infoIconFroGenerateSalary {
  font-size: 20px;
  color: #cd4343;
}
