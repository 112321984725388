.employee_form_dialog {
  display: flex;
  justify-content: center;
}

.employee_form_dialog
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  padding: 0;
}


.add_employee_main_heading {
  color: #7e009e;
  /* position: sticky;
    top: 0; */
  background: #fff;
  /* z-index: 1; */
}
.employee_form_hr_div_checkbox input {
  appearance: auto;
}
.employee_form_hr_div_checkbox label.create_form_labels {
  padding-top: 0;
}
.add_employee_main_heading .commentsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add_employee_main_heading .commentsHeader .closeIcon{cursor: pointer;}

.employee_form_dialog .MuiDialog-paperWidthSm {
  width: 620px;
  border: 1px solid #bebebe;
  border-radius: 10px;
  height: fit-content;
  padding: 0px 20px 10px 20px;
  max-height: 81%;
}

.create_form_input_div .empInput .MuiChip-filled {
  background: #dcf2ff;
}

.card-content-employee-form {
  overflow-x: hidden;
  /* padding: 0 20px !important; */
  max-height: calc(100vh - 318px);
  overflow: auto;
}

button.save_button {
  width: 122px;
  height: 36px;
  border: 1px solid #7e009e;
  border-radius: 30px;
  background: #7e009e;
  color: #ffff;
}

button.save_button:hover {
  background: #660080;
}

.cancel_button {
  width: 122px;
  height: 36px;
  border: 1px solid #999999;
  border-radius: 30px;
  color: #333333;
  background: none;
}

.cancel_button:hover {
  color: black;
  border: 1px solid #333333;
}

.create_form_labels {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
  color: #000000;
}

.create_form_asterisk {
  font-size: 14px;
  color: red;
}

.card-content-employee-form .skillSetRating {
  color: #7e009e;
}

.employee_form_button_div {
  background: #fff;
  padding: 15px 20px !important;
  /* position: sticky;
    bottom: 0; */
}

.employee_form_dialog input,
.employee_form_dialog select {
  padding: 6px 15px !important;
  font-size: 14px;
  appearance: auto;
}

.create_form_input_div {
  display: flex;
  flex-direction: column;
}

.employee_form_inner_heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}

#current_address_checkbox_label {
  float: right;
  margin-right: 15px;
}

.employee_form_hr_div {
  display: flex;
  align-items: center;
}

.employee_form_hr_div_text {
  width: 100%;
  max-width: fit-content;
}

.employee_form_hr_div_line {
  width: 100%;
  margin-left: 5px;
}

.employee_form_hr {
  border: 1px solid gray;
  margin-bottom: 5px;
}

#employee_form_label_id {
  margin-top: 5px;
}

.employee_form_hr_div_checkbox {
  display: flex;
  width: 100%;
  max-width: fit-content;
}

.employee_form_address_div {
  display: flex;
  flex-direction: column;
}

.employee_form_address_inner_div {
  display: flex;
  flex-direction: row;
}

.employee_form_radio {
  margin-right: 10px;
}

.create_form_input_div
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 6px 15px !important;
}

.employee_form_delete_icon {
  color: #9e0000;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  flex: end;
  align-items: center;
}

.employee_form_add_row_button {
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  color: #001d6d;
  display: flex;
}

.create_form_input_div_time {
  margin-bottom: 20px;
}

.weekends {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MuiMenu-paper.MuiPaper-elevation.MuiPopover-paper {
  max-height: 300px;
}

.MuiMenu-paper.MuiPaper-elevation.MuiPopover-paper ul.MuiMenu-list {
  padding: 0;
}
.workingDaysList {
  display: flex;
  align-items: baseline;
  column-gap: 20px;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;
}
.workingDaysList label.create_form_labels {
  padding-top: 0;
  font-weight: 600;
  font-size: 14px;
  min-width: 75px;
}
.workingDaysList ul {
  padding: 0;
}
.workingDaysList ul li label {
  font-weight: 400;
  font-size: 14px;
}
.workmodeDates {
  display: flex;
  align-items: center;
  column-gap: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.workmodeDates .workmodeDatesLabel {
  border: 1px solid #00000029;
  border-radius: 5px;
  margin: 15px auto 0;
  width: 65px;
}
.workmodeDates .workmodeDatesLabel label {
  margin: 0;
}
.errorMessagePopUp {
  font-size: 12px;
  color: #f00;
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
}
.add_employee_main_heading.workmodeModalHeader .workmodeModalHeaderText {
  padding: 0 20px 15px;
}
.add_employee_main_heading.workmodeModalHeader {
  padding: 15px 0;
}
.add_employee_main_heading.workmodeModalHeader .currentWorkmode {
  background: #F0F9FF;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #001460;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .employee_form_dialog .MuiDialog-paperWidthSm {
    width: 100%;
    max-width: 80%;
    max-height: 80%;
  }
}

@media screen and (max-width: 600px) {
  .employee_form_dialog .MuiDialog-paperWidthSm {
    max-width: 100%;
  }
}
