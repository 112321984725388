.empDetailsMain {
  background: #f1f4fb;
}

.empDetailsMain .empDetailTitle {
  border-bottom: 1px solid #00000029;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.empDetailTitle .empDetailsToggleIcon {
  padding: 13px 30px;
  color: #555555;
}

.empDetailsMain .empDetailTitle .empDetailTitleText {
  position: relative;
  width: 106px;
  font-weight: 600;
  text-align: center;
  padding: 13px 0;
  border-bottom: 1px solid #555555;
}

.empDetailsMain .empDetailTitle .empDetailTitleText.hideAfter {
  border-bottom: none;
}

.empDetailTitleText.hideAfter:after {
  display: none;
}

.empDetailsMain .empDetailTitle .empDetailTitleText:after {
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 5px solid #555;
  z-index: 1;
}

.empDetailsLeft {
  height: 100%;
  /* height: calc(100vh - 124px); */
  position: relative;
  background: #fff;
}

.empDetailsAboutMain {
  height: calc(100vh - 124px);
  overflow: auto;
  padding-bottom: 160px;
}
.empDetailsAboutMain::-webkit-scrollbar {
  display: none;
}

.empDetailsAboutMain
  .empDetailsProfile
  .empDetailProfileEditIcon
  .employeeIdProfilePage {
  font-size: 12px;
  border: 1px solid;
  border-radius: 15px;
  padding: 1px 10px;
  font-weight: 600;
  margin-right: 10px;
}
.empDetailsMain .empDetailsLeft .empDetailsProfile {
  position: relative;
  background: #dcf2ff;
  padding: 30px;
  padding-right: 0;
  display: flex;
  column-gap: 20px;
}

.aboutProfileImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empDetailsProfileImg .empDetailsImg {
  width: 100px;
  border-radius: 50%;
  height: 100px;
  object-fit: cover;
}

.empDetailsProfileImg .changePhoto {
  font-size: 12px;
  font-weight: 600;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 35px;
  text-align: center;
  padding: 2px 10px;
  margin-top: 5px;
  cursor: pointer;
  display: inline-block;
}

.profileActiveStatus {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  background: green;
  border-radius: 50%;
  outline: 3px solid #fff;
  z-index: 1;
}

.profileLeftStatus {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
  outline: 3px solid #fff;
  z-index: 1;
}

.empDetailsProfileContent {
  color: #000;
  line-height: 28px;
}

.empDetailsProfileContent .empName {
  font-size: 18px;
}

.empDetailsProfileContent .empDesignation {
  font-weight: 600;
}

.empDetailsProfileContent .empEmail,
.empDetailsProfileContent .empReportsTo {
  font-size: 14px;
}

.empDetailsProfileContent .empReportsTo .empReportsToName {
  color: #002c65;
  font-weight: 600;
}

.empDetailsLeft .empStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #f0f9ff;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 15px;
}

.empDetailsMain .empDetailsLeft .empStatus .empDepartment {
  color: #001460;
  padding: 15px 0 0 15px;
}

.empDetailsMain .empDetailsLeft .empStatus .empWorkMode {
  color: #560098;
  padding: 15px 15px 0 15px;
}

.empDetailsMain .empBioDesc .empBioTitle,
.empDetailsMain .empBio .empBioTitle {
  font-weight: 600;
  color: #001460;
  padding-bottom: 10px;
}
.empDetailsMain .empBioDesc .empBioTitle svg {
  cursor: pointer;
}

.empDetailProfileEditIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #001460;
  font-size: 18px;
  cursor: pointer;
}

.empDetailsMain .empDetailsLeft .empBioDesc {
  padding: 20px 20px 0px 20px;
}

.empDetailsMain .empDetailsLeft .empBio {
  padding: 20px 20px 0 20px;
}

.empDetailsMain .empBio .empBioContent {
  font-size: 12px;
  line-height: 28px;
}

.empDetailsLeft .empBio .empBioDetails .empBioEmailsContent {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 40px;
  flex-wrap: wrap;
}

.empDetailsLeft .empBio .empBioDetails .empBioEmailsContent .empBioInfoName {
  min-width: 100px;
  font-weight: 500;
  color: #1678c8;
}

.empDetailsLeft .empBio .empBioDetails .empBioEmailsContent .empBioInfoText {
  font-weight: 400;
  color: #000;
  line-height: 22px;
}

.empDetailsLeft
  .empBio
  .empBioDetails
  .empBioEmailsContent
  .empBioInfoText
  .copyIcon {
  color: #666;
  padding-left: 5px;
  cursor: pointer;
}
#phoneIcon {
  cursor: pointer;
}

/* EmpProfile Progress bar */
.empDetailsMain .empDetailsLeft .empProfileStatus {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  padding: 20px;
  box-shadow: 0px -1px 1px #00000029;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
}

.empProgress {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: none;
  box-shadow: none;
  position: relative;
  margin: auto;
  color: #b41fb4;
}

.empProfileProgrStatus {
  position: relative;
}

.empProgress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid #eeeeee;
  position: absolute;
  top: 0;
  left: 0;
}

.empProgress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.empProgress .empProgress-left {
  left: 0;
}

.empProgress .empProgress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 8px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.empProgress .empProgress-left .empProgress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.empProgress .empProgress-right {
  right: 0;
}

.empProgress .empProgress-right .empProgress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.empProgress .empProgress-value {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 18px;
  color: #b41fb4;
  line-height: 80px;
  font-weight: 600;
  text-align: center;
}

.empProgress.blue .empProgress-bar {
  border-color: #b41fb4;
}

.empProgress.blue .empProgress-left .empProgress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.empDetailsLeft .empProfileStatus .empProfileProgrStatus .empProgressText,
.empDetailsLeft .empProfileStatus .empProfileResume .pdfText {
  font-size: 14px;
  font-weight: 600;
  color: #001460;
  margin-top: 5px;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.empProfileResume {
  text-align: center;
}

.empProfileResume .pdf {
  font-size: 40px;
  color: #ce0022;
  border: 1px solid #dddddd;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
}

/* Emp Right section css */
/* .empDetailsRight {
    position: relative;
    background: #fff;
    height: calc(100vh - 124px);
} */

.empDetailsRight .empDetailsRightContent {
  height: calc(100vh - 168px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.empDetailsRight .empDetailTabs {
  /* box-shadow: 0px 1px 1px #00000029; */
  /* margin-bottom: 15px; */
  list-style: none;
  display: flex;
}

.empDetailsRight .empDetailTabs li button {
  width: 100%;
  color: #555;
  font-weight: 600;
  padding: 13px 20px;
  border-radius: 0;
  border: none;
  position: relative;
  background: #fff;
}

.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab .empDetaisTab {
  text-align: left;
  padding: 10px 15px;
}

.empDetailsRight .empDetailTabs li button.active {
  color: #555;
  background-color: transparent;
  border-bottom: 1px solid #555555;
}

.empDetailsRight .empDetailTabs li button.active:after {
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 5px solid #555;
  z-index: 1;
}

.table.immediateFamilyDetails tr td,
.table.immediateFamilyDetails tr th {
  border: none !important;
  line-height: 28px;
  color: #333;
}

.table.immediateFamilyDetails tr th {
  font-weight: 600;
}

/* Employee Right Section */
.empDetailsRight {
  position: relative;
  background: #fff;
  height: 100%;
}

.employeeAboutMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-left: 1px solid #dddddd;
  cursor: pointer;
}

/* ul.employeeAboutMenuContent {
    width: 200px;
    position: absolute;
    top: 55px;
    right: 5px;
    border: 1px solid #ccc;
    z-index: 1;
    border-radius: 5px;
    list-style: none;
    text-align: left;
    background: #fff;
    line-height: 30px;
}

ul.employeeAboutMenuContent li {
    padding: 5px 15px;
} */

.fileUpload .guZdik {
  height: 120px;
}

.empDetailsRight .empDetailTabs.empDetailMobTabs {
  display: none;
  width: 250px;
  position: absolute;
  top: 55px;
  right: 10px;
  border: 1px solid #ccc;
  z-index: 1;
  border-radius: 5px;
  list-style: none;
  text-align: left;
  background: #fff;
  line-height: 30px;
}

.empDetailsRight .empDetailTabs.empDetailMobTabs li {
  width: 100%;
}

.empDetailsRight .nav-pills.empDetailTabs.empDetailMobTabs .nav-link {
  width: 100%;
  padding: 5px 15px;
  text-align: left;
  font-size: 14px;
}

.empDetailsRight .nav-pills.empDetailTabs.empDetailMobTabs .nav-link.active {
  border: none;
}

.empDetailsRight
  .nav-pills.empDetailTabs.empDetailMobTabs
  .nav-link.active:after {
  display: none;
}

.empDetailsRight .empDetailTabs.empDetailMobTabs li .empDetaisTab.active {
  border: none;
  background: #7e009e;
  color: #fff;
}

.empDetailsRight
  .empDetailTabs.empDetailMobTabs
  li
  .empDetaisTab.active::after {
  display: none;
}

.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab1,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab2,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab3,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab4,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab5,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab6,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab7,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab8,
.employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab9 {
  display: none;
}

.empDetailTitle .empDetailsToggleIcon {
  display: none;
}

.tableAddButton {
  background: transparent;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 30px;
  font-size: 14px;
  padding: 3px 20px;
  margin-left: 10px;
}

.AddButtonCss {
  background: transparent;
  color: #7e009e;
  border: 1px solid #7e009e;
  border-radius: 50%;
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.hoverIconTextCss {
  display: none;
}

.AddButtonCss:hover .hoverIconTextCss {
  display: block;
}

.tableDeleteIcon {
  color: #da3848;
  cursor: pointer;
}

.tableAttachIcon {
  color: #000000;
  cursor: pointer;
}

.tableEditIcon {
  color: #000000;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
}

.document_upload_css {
  margin-top: 30px;
}
.fileCss {
  font-size: 14px;
  color: #000000;
}
.fileIconCss {
  color: #7a91a4;
  margin-right: 5px;
}
.document_upload_css .file {
  color: #0d487f;
  font-weight: 600;
  width: 500px;
}
.fileDivCss {
  display: flex;
  align-items: center;
}

#crossIconCss {
  margin-left: 10px;
}
.resetIcon {
  cursor: pointer;
}

.document_upload_css .file::file-selector-button {
  background-color: #a0d1ff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  color: rgb(49, 49, 49);
  cursor: pointer;
}
.document_upload_css .file::file-selector-button:hover {
  background-color: #8ec9ff;
}
.IconDivWorkExperience {
  border-radius: 0px 10px 10px 0px;
  background: #f9f9f9;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  height: 100%;
  row-gap: 20px;
  border-right: 1px solid #ccc;
}
.previousWorkExperience .IconDivWorkExperience .workExpIcon {
  font-size: 14px;
}

/* Hover Tooltip css */
.hoverTooltip {
  position: relative;
}
.hoverTooltip .hoverTooltipContent {
  display: none;
  margin: 10px 0;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #0077c6;
  font-size: 14px;
  font-weight: 600;
  background: #f0f9ff;
  width: max-content;
  max-width: 300px;
  line-height: 24px;
  z-index: 2;
}
.hoverTooltip:hover .hoverTooltipContent {
  display: block;
}
.hoverTooltip .hoverTooltipContent.hoverTooltipRight {
  position: absolute;
  right: 0;
  transform: initial;
  left: auto;
}
/* End of Hover Tooltip css */

@media screen and (max-width: 1920px) {

  .empDetailTabs li.empDescTab.empDescTab9 {
    display: none;
  }

  .empDetailTabs.empDetailMobTabs.active {
    display: block;
  }

  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab9 {
    display: block;
  }
}
@media screen and (max-width: 1860px) {
  .empDetailTabs li.empDescTab.empDescTab8 {
    display: none;
  }

  .empDetailTabs li.empDescTab.empDescTab9 {
    display: none;
  }

  .empDetailTabs.empDetailMobTabs.active {
    display: block;
  }

  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab8 {
    display: block;
  }

  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab9 {
    display: block;
  }
}

@media screen and (max-width: 1680px) {
  .empDetailsAboutMain {
    padding-bottom: 135px;
  }

  .empDetailsMain .empDetailsLeft .empBioDesc .empBioContent {
    font-size: 14px;
  }

  /* profile status circle css */
  .empDetailsMain .empDetailsLeft .empProfileStatus {
    padding: 10px 20px;
  }

  .empProgress {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .empProgress .empProgress-left .empProgress-bar,
  .empProgress .empProgress-right .empProgress-bar {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
  }

  .empProgress .empProgress-value {
    width: 60px;
    height: 60px;
    font-size: 14px;
    line-height: 60px;
  }

  .empProgress .empProgress-bar {
    border-width: 5px;
  }

  .empProgress:after {
    content: "";
    border: 5px solid #eeeeee;
  }

  /* .empProfileResume .pdf {
        font-size: 28px;
        padding: 15px;
    }

    .empDetailsLeft .empProfileStatus .empProfileProgrStatus .empProgressText,
    .empDetailsLeft .empProfileStatus .empProfileResume .pdfText {
        font-size: 12px;
    } */

  /* profile status circle css */
}

@media screen and (max-width: 1660px) {
  .empDetailTabs li.empDescTab.empDescTab7 {
    display: none;
  }

  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab7 {
    display: block;
  }
}

@media screen and (max-width: 1440px) {
  .empDetailTabs li.empDescTab.empDescTab6,
  .empDetailTabs li.empDescTab.empDescTab5 {
    display: none;
  }

  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab6,
  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab5 {
    display: block;
  }
}

@media screen and (max-width: 1280px) {
  .empDetailTabs li.empDescTab.empDescTab4 {
    display: none;
  }

  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab4 {
    display: block;
  }
}

@media screen and (max-width: 1720px) {
  /* .empDetailsMain .empDetailsLeft .empDetailsProfile { padding-right: 30px; text-align: center; } */
  /* .empDetailsProfileImg { display: flex; flex-direction: column; align-items: center; margin-bottom: 15px; } */
  .empDetailsProfileImg {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 991px) {
  .empDetailsRight .empDetailsRightContent {
    height: calc(100vh - 136px);
  }
  .empDetailsLeft {
    height: auto;
  }

  .empDetailsAboutMain .empBio .empBioDetails {
    display: flex;
    flex-wrap: wrap;
  }

  .empDetailsLeft .empBio .empBioDetails .empBioEmailsContent {
    width: 50%;
  }

  .empDetailsAboutMain .emp_bio_profile {
    display: flex;
  }

  .empDetailsMain .empDetailsLeft .empBioDesc {
    padding: 14px 20px 0px 20px;
    background: #fafafa;
    width: 100%;
  }

  .empDetailsMain .empBioDesc .empBioTitle,
  .empDetailsMain .empBio .empBioTitle {
    font-size: 18px;
  }

  /* profile status circle css */
  .empDetailsMain .empDetailsLeft .empProfileStatus {
    position: initial;
    box-shadow: none;
    padding: 5px 20px 20px;
    height: 100px;
  }
  .empProfileResume .pdf {
    font-size: 30px;
    padding: 15px;
  }
  .empDetailsAboutMain {
    height: auto;
    padding-bottom: 15px;
  }

  .empProgress {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }

  .empProgress .empProgress-left .empProgress-bar,
  .empProgress .empProgress-right .empProgress-bar {
    border-top-left-radius: 44px;
    border-bottom-left-radius: 44px;
  }

  .empProgress .empProgress-value {
    width: 44px;
    height: 44px;
    font-size: 10px;
    line-height: 44px;
  }

  .empProgress .empProgress-bar {
    border-width: 5px;
  }

  .empProgress:after {
    content: "";
    border: 5px solid #eeeeee;
  }

  .empProfileProgrStatus {
    display: flex;
    align-items: center;
    /* column-gap: 15px; */
  }

  /* .empProfileResume .pdf {
        font-size: 25px;
        padding: 10px;
    } */

  .empProfileResume {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  /* profile status circle css */
  .empDetailsRight {
    border-top: 1px solid #0000004d;
  }
}

@media screen and (max-width: 768px) {
  .empDetailsRight .empDetailsRightContent {
    height: calc(100vh - 133px);
  }
  .empDetailsMain .empDetailsLeft .empDetailsProfile {
    padding-right: 30px;
    text-align: center;
  }

  .aboutProfileImg {
    margin: auto;
  }

  .empDetailsLeft.active {
    display: none;
  }

  .empDetailTitle .empDetailsToggleIcon {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .empDetailsRight .empDetailsRightContent {
    height: calc(100vh - 66px);
  }
  .empDetailsAboutMain .emp_bio_profile {
    display: flex;
    flex-direction: column;
  }

  .empDetailsMain .empDetailsLeft .empBioDesc {
    padding: 15px 20px;
    background: #fff;
  }

  .empDetailsProfileContent {
    text-align: left;
  }

  .empDetailsLeft .empBio .empBioDetails .empBioEmailsContent {
    width: 100%;
  }

  .empDetailsMain .empDetailsLeft .empDetailsProfile {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 500px) {
  .empDetailTabs li.empDescTab.empDescTab3 {
    display: none;
  }

  .employeeAboutMenu .empDetailTabs.empDetailMobTabs .empDescTab.empMobTab3 {
    display: block;
  }
  .empDetailsMain .empDetailsLeft .empProfileStatus {
    height: 128px;
  }
  .empProfileProgrStatus,
  .empProfileResume {
    flex-direction: column;
  }
  .empProfileResume .pdf {
    font-size: 40px;
    padding: 15px;
    margin-top: 10px;
  }
}
